import React, { Fragment, useEffect, useState } from 'react'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'
import { prettyPrice } from '../../utils/pretty-prices'

import Table, { NestedTable } from '../../components/table'

const RenderList = ({billingReports, products}) => {
  const { t } = useTranslation()

  let grandTotalCost, grandTotalOneTimeCredits, grandTotalPercentageDiscounts, grandTotalDiscounts, grandTotal

  if (billingReports.length > 0 ) {
    grandTotalCost = billingReports.map(g => g.cost).reduce((a, b) => a + b)
    grandTotalOneTimeCredits = billingReports.map(g => g.oneTimeCredits).reduce((a, b) => a + b)
    grandTotalPercentageDiscounts = billingReports.map(g => g.percentageDiscounts).reduce((a, b) => a + b)
    grandTotalDiscounts = billingReports.map(g => g.totalDiscounts).reduce((a, b) => a + b)
    grandTotal = billingReports.map(g => g.subTotal).reduce((a, b) => a + b)
  }

  const groupedByWorkspace = _.groupBy(billingReports, br => {
    if (br.service.workspace) {
      return br.service.workspace.id
    } else if (br.service.billingAccoutId) {
      return br.service.billingAccountId
    }
  })
  // Lets populate the rows object with each workspace, then each billingReports data row!
  let rows = []
  Object.keys(groupedByWorkspace).forEach(key => {
    const billingReports = groupedByWorkspace[key]

    const workspace = billingReports[0].service.workspace || {}
    const billingAccount = billingReports[0].service.billingAccount || {}
    console.log('billingAccount', billingAccount)

    // first, lets push the "heading" row into the rows object
    rows.push([{value: workspace.name || t('Account services'), bold: true, isHeading: true, colspan: 9}])
    rows.push([
        { value: t('Service'), bold: true },
        { value: t('Product'), bold: true },
        { value: t('Usage'), bold: true },
        { value: t('Unit key'), bold: true },
        { value: t('Cost'), textAlign: 'right', bold: true },
        { value: t('One time credits'), textAlign: 'right', bold: true },
        { value: t('Percentage discounts'), textAlign: 'right', bold: true },
        { value: t('Total discount'), textAlign: 'right', bold: true },
        { value: t('Sub total'), textAlign: 'right', bold: true }
      ])

    // Then push all the billing reports as rows

    let totalCost, totalOneTimeCredits, totalPercentageDiscounts, totalDiscounts, total

    if (billingReports.length > 0 ) {
      totalCost = billingReports.map(g => g.cost).reduce((a, b) => a + b)
      totalOneTimeCredits = billingReports.map(g => g.oneTimeCredits).reduce((a, b) => a + b)
      totalPercentageDiscounts = billingReports.map(g => g.percentageDiscounts).reduce((a, b) => a + b)
      totalDiscounts = billingReports.map(g => g.totalDiscounts).reduce((a, b) => a + b)
      total = billingReports.map(g => g.subTotal).reduce((a, b) => a + b)
    }

    const billingReportRows = []
    const groupedByServiceId = _.groupBy(billingReports, br => br.serviceId)
    for (let x = 0; Object.keys(groupedByServiceId).length > x; x++) {
      const currentServiceId = Object.keys(groupedByServiceId)[x]
      const serviceBillingReports = groupedByServiceId[currentServiceId]

      // then group by key
      const groupedByKey = _.groupBy(serviceBillingReports, sbr => sbr.unitKey)
      for (let y = 0; Object.keys(groupedByKey).length > y; y++) {
        const currentUnitKey = Object.keys(groupedByKey)[y]
        const currentUnitKeyLogs = groupedByKey[currentUnitKey]
        // now, lets create a row by this

        const usage = currentUnitKeyLogs.map(g => g.usage).reduce((a, b) => a + b)
        const cost = currentUnitKeyLogs.map(g => g.cost).reduce((a, b) => a + b)
        const oneTimeCredits = currentUnitKeyLogs.map(g => g.oneTimeCredits).reduce((a, b) => a + b)
        const percentageDiscounts = currentUnitKeyLogs.map(g => g.percentageDiscounts).reduce((a, b) => a + b)
        const totalDiscounts = currentUnitKeyLogs.map(g => g.totalDiscounts).reduce((a, b) => a + b)
        const subTotal = currentUnitKeyLogs.map(g => g.subTotal).reduce((a, b) => a + b)

        let product = currentUnitKeyLogs[0].product.name

        rows.push([{
          value: currentUnitKeyLogs[0].service.name,
          inlineLabel: t('Service'),
        }, {
          value: product,
          inlineLabel: t('Product'),
        }, {
          value: usage,
          inlineLabel: t('Usage'),
        }, {
          value: currentUnitKeyLogs[0].unitKey,
          inlineLabel: t('Unit key'),
        }, {
          value: prettyPrice(cost, 'NOK'),
          textAlign: 'right',
          inlineLabel: t('Cost'),
        }, {
          value: prettyPrice(oneTimeCredits, 'NOK'),
          textAlign: 'right',
          inlineLabel: t('One time credits'),
        }, {
          value: prettyPrice(percentageDiscounts, 'NOK'),
          textAlign: 'right',
          inlineLabel: t('Percentage discount'),
        }, {
          value: prettyPrice(totalDiscounts, 'NOK'),
          textAlign: 'right',
          inlineLabel: t('Total discount'),
        }, {
          value: prettyPrice(subTotal, 'NOK'),
          textAlign: 'right',
          inlineLabel: t('Sub total'),
        }])
      }
    }

    // Next, push the subtotal for the entire workspace
    rows.push([{
      value: '',
    }, {
      value: '',
    }, {
      value: '',
    }, {
      value: '',
    }, {
      value: prettyPrice(totalCost, 'NOK'),
      textAlign: 'right',
      bold: true,
      inlineLabel: t('Cost'),
    }, {
      value: prettyPrice(totalOneTimeCredits, 'NOK'),
      textAlign: 'right',
      bold: true,
      inlineLabel: t('One time credits'),
    }, {
      value: prettyPrice(totalPercentageDiscounts, 'NOK'),
      textAlign: 'right',
      bold: true,
      inlineLabel: t('Percentage discount'),
    }, {
      value: prettyPrice(totalDiscounts, 'NOK'),
      textAlign: 'right',
      bold: true,
      inlineLabel: t('Total discount'),
    }, {
      value: prettyPrice(total, 'NOK'),
      textAlign: 'right',
      bold: true,
      inlineLabel: t('Sub total'),
    }])
  })

  // finally, lets push in the LAST row which is the grand total

  // heading row
  rows.push([{
    value: t('Total'),
    bold: true,
    isHeading: true,
    colspan: 9
  }])

  rows.push([
    { value: '' },
    { value: '' },
    { value: '' },
    { value: '' },
    { value: t('Cost'), textAlign: 'right', bold: true },
    { value: t('One time credits'), textAlign: 'right', bold: true },
    { value: t('Percentage discounts'), textAlign: 'right', bold: true },
    { value: t('Total discount'), textAlign: 'right', bold: true },
    { value: t('Total'), textAlign: 'right', bold: true }
  ])

  // grand total
  rows.push([{
    value: '',
  }, {
    value: '',
  }, {
    value: '',
  }, {
    value: '',
  }, {
    value: prettyPrice(grandTotalCost, 'NOK'),
    textAlign: 'right',
    bold: true,
    inlineLabel: t('Cost'),
  }, {
    value: prettyPrice(grandTotalOneTimeCredits, 'NOK'),
    textAlign: 'right',
    bold: true,
    inlineLabel: t('One time credits'),
  }, {
    value: prettyPrice(grandTotalPercentageDiscounts, 'NOK'),
    textAlign: 'right',
    bold: true,
    inlineLabel: t('Percentage discount'),
  }, {
    value: prettyPrice(grandTotalDiscounts, 'NOK'),
    textAlign: 'right',
    bold: true,
    inlineLabel: t('Total discount'),
  }, {
    value: prettyPrice(grandTotal, 'NOK'),
    textAlign: 'right',
    bold: true,
    inlineLabel: t('Total'),
  }])


  return (
    <NestedTable
      rows={rows} />
  )
}

export default RenderList
