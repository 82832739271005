import React from "react"
import ReactDOM from "react-dom"
import './i18n'

import App from "./containers/app"
import * as serviceWorker from "./serviceWorker"
import { Auth0Provider } from "./react-auth0-spa"
import auth0Config from "./auth_config.json"
import history from "./utils/history"

import config from './config'

// Levelup SDK
import { LevelupSDKProvider } from './levelup-sdk'

// Notifications provider
import { NotificationsProvider } from './notifications'

// Modal provider
import { ModalProvider } from './modal'

// Stripe
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import './index.scss'

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const stripePromise = loadStripe(config.stripePublishableKey)

ReactDOM.render(
  <Auth0Provider
    domain={auth0Config.domain}
    client_id={auth0Config.clientId}
    audience={auth0Config.audience}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <Elements stripe={stripePromise}>
      <NotificationsProvider>
        <LevelupSDKProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </LevelupSDKProvider>
      </NotificationsProvider>
    </Elements>
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
