import React, { Fragment, useState } from "react"

const Table = ({keyPrefix, headArray, rows, tableFooterRows, collapsibleLabel, rowsRightAlignArray, }) => {
  const [ open, setOpen ] = useState(false)

  let always_open = ``
  if (!collapsibleLabel) {
    always_open = true
  }

  return (
    <Fragment>
      {collapsibleLabel &&
        <h3 className={`lvl-temp-accordion-anchor ${open && 'is-open'}`} onClick={ e => setOpen(!open) }>{collapsibleLabel}</h3>
      }
      <div className={`lvl-table-container ${open && 'is-open'} ${always_open && 'is-open'}`}>
        <table className="lvl-table">
          <thead>
            {headArray && headArray.length > 0 &&
              <tr>
                {headArray.map((ha, i) => <th key={`${keyPrefix}-head-${i}`} className={rowsRightAlignArray && rowsRightAlignArray[i] && 'h-text-align-right'}>
                  {ha}
                  </th>
                )}
              </tr>
            }
          </thead>
          <tbody>

            {rows && rows.map((r, i) => (
              <tr key={`${keyPrefix}${i}`}>
                {r.map((c, innerIndex) =>
                  <td key={`${keyPrefix}-td-${i}-${innerIndex}`} className={rowsRightAlignArray && rowsRightAlignArray[innerIndex] && 'h-text-align-right'}>
                    <span className="lvl-table__inline-label">{headArray[innerIndex]}:</span>
                    <span>{c}</span>
                  </td>
                  )}
              </tr>
            ))}

            {tableFooterRows && tableFooterRows.length > 0 && tableFooterRows.map((r, i) => (
              <tr key={`table-footer-${keyPrefix}${i}`}>
                {r.map((c, innerIndex) =>
                  <td key={`${keyPrefix}-td-${i}-${innerIndex}`} className={rowsRightAlignArray && rowsRightAlignArray[innerIndex] && 'h-text-align-right'}>
                    <span className="lvl-table__inline-label"><b>{headArray[innerIndex]}:</b></span>
                    <span><b>{c}</b></span>
                  </td>
                  )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

const NestedTable = ({keyPrefix, headArray, rows}) => {
  return (
    <div className={`lvl-table-container is-open`}>
      <table className="lvl-table">
        {headArray && headArray.length > 0 &&
          <thead>
            <tr>
              {headArray.map((column, i) => (
                <th key={`${keyPrefix}-head-${i}`}
                    className={column.textAlign && `h-text-align-${column.textAlign}`}>
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
        }

        <tbody>
          {rows && rows.map((row, i) => (
            <tr key={`${keyPrefix}${i}`} className={row[0].isHeading && 'heading-row'}>
              {row.map((column, innerIndex) =>
                <td key={`${keyPrefix}-td-${i}-${innerIndex}`}
                    className={column.textAlign && `h-text-align-${column.textAlign}`}
                    colSpan={column.colspan || 1}
                    >
                  <span className="lvl-table__inline-label">{column.inlineLabel}</span>
                  {column.isHeading ? <h2>{column.value}</h2> : <span className={column.bold && `h-text-bold`}>{column.value}</span>}
                </td>
                )}
            </tr>
          ))}

          {/*tableFooterRows && tableFooterRows.length > 0 && tableFooterRows.map((r, i) => (
            <tr key={`table-footer-${keyPrefix}${i}`}>
              {r.map((c, innerIndex) =>
                <td key={`${keyPrefix}-td-${i}-${innerIndex}`} className={rowsRightAlignArray && rowsRightAlignArray[innerIndex] && 'h-text-align-right'}>
                  <span className="lvl-table__inline-label"><b>{headArray[innerIndex]}:</b></span>
                  <span><b>{c}</b></span>
                </td>
                )}
            </tr>
          ))*/}
        </tbody>
      </table>
    </div>
  )
}

NestedTable.defaultProps = {
  keyPrefix: 'default-prefix-',
  headArray: null,
  rows: [ // layer one includes each row
    [{ // layer two includes each column
      value: 'Column value', // The value that are going to be represented
      textAlign: 'right', // How do you want to align the content of the column
      colspan: 1, // how many columns should the col span over. (default is 1)
      textSize: 'normal', // The size of the text inside the column
      isHeading: true, // The size of the text inside the column
      bold: false // Should the column text be bold?
    }]
  ]
}

export {
  NestedTable
}

export default Table
