import React from 'react'

const SelectorList = ({items}) => {
  /*
  items - array of the menu items
  */

  return (
    <div className="lvl-selector-list">
      {items.map((item, key) =>
        <a key={item.id} href={item.link}>{item.label}</a>
      )}
    </div>
  )
}

export {
  SelectorList
}



