import React, { Fragment, useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"
import iso3311a2 from 'iso-3166-1-alpha-2'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useNotifications } from '../../notifications'

import './index.css'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PaymentMethodsAdd = () => {
  const stripe = useStripe()
  const elements = useElements()

  const [ clientSecret, setClientSecret ] = useState(false)
  const [ freeze, setFreeze ] = useState(false)
  const { getBillingAccountSetupPaymentMethodIntents, postBillingAccountPaymentMethodIntentSuccess } = useLevelupSDK()
  const { setNotification } = useNotifications()

  const { billingAccountId } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  // TEST CARD
  // 4000005780000007

  useEffect(() => {
    getBillingAccountSetupPaymentMethodIntents(billingAccountId)
      .then(data => {
        setClientSecret(data.client_secret)
      })
      .catch(e => {
        setNotification({text: t('Failed, please try again later'), status: 'error'})
        history.push(`/billing-accounts/${billingAccountId}/payment-methods`)
      })
  }, [])

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Add Payment Method')}</h1>
        </div>

        <div className="lvl-page__body">
          <Formik
            initialValues={{
              name: '',
              city: '',
              country: 'NO',
              line1: '',
              line2: '',
              postal_code: '',
              state: '',
              email: '',
              phone: '',
            }}
            validationSchema={yup.object({
              name: yup.string().min(5, t('Write full name, and should be at least 5 characters')).required(t('Required')),
              email: yup.string().email('Email must be valid').required(t('Required')),
              phone: yup.string().min(2, t('Missing')).required(t('Required')),
              country: yup.string().required(t('Required')),
              line1: yup.string().min(2, t('Missing')).required(t('Required')),
              postal_code: yup.string().min(2, t('Missing')).required(t('Required')),
              city: yup.string().min(2, t('Missing')).required(t('Required')),
              state: yup.string().min(2, t('Missing')).required(t('Required'))
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)

              if (!clientSecret || !elements || !stripe) {
                setNotification({text: t('Failed'), status: 'error'})
                setSubmitting(false)
                return
              }

              stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                  card: elements.getElement(CardElement),
                  billing_details: {
                    name: values.name,
                    address: {
                      city: values.city,
                      country: values.country,
                      line1: values.line1,
                      line2: values.line2,
                      postal_code: values.postal_code,
                      state: values.state
                     },
                     email: values.email,
                     phone: values.phone,
                  }
                }
              }).then(result => {
                // The setup has succeeded. Display a success message and send
                // result.setupIntent.payment_method to your server to save the
                // card to a Customer
                postBillingAccountPaymentMethodIntentSuccess(billingAccountId, {payment_method: result.setupIntent.payment_method, status: result.setupIntent.status})
                  .then(res => {
                    setNotification({text: t('Updated 🎉'), status: 'success'})
                    setSubmitting(false)
                    history.goBack()
                  })
                  .catch(e => {
                    setNotification({text: t('Failed'), status: 'error'})
                    setSubmitting(false)
                  })
              })
              .catch(error => {
                // Display result.error.message in your UI.
                setNotification({text: t('Failed'), status: 'error'})
                setSubmitting(false)
              })
            }}
            >
            {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
              <Form>

                <div className="lvl-input">
                  <label htmlFor="name">{t('Name')}</label>
                  <Field type="text" id="name" name="name" />
                  <ErrorMessage name="name" component="div" className="validation-message" />
                </div>

                <div className="lvl-input">
                  <label htmlFor="email">{t('Email')}</label>
                  <Field type="email" id="email" name="email" />
                  <ErrorMessage name="email" component="div" className="validation-message" />
                </div>

                <div className="lvl-input">
                  <label htmlFor="phone">{t('Phone')}</label>
                  <PhoneInput
                    country={'no'}
                    containerClass="lvl-input"
                    inputClass="lvl-input"
                    value={values.phone}
                    onChange={phone => setFieldValue('phone', phone)}
                  />
                </div>

                <div className="lvl-input lvl-input--select">
                  <label htmlFor="country">{t('Country')}</label>
                  <Field as="select" id="country" name="country">
                    {iso3311a2.getCodes().map(c => <option key={c} value={c}>{iso3311a2.getCountry(c)}</option>)}
                  </Field>
                </div>

                <div className="lvl-input">
                  <label htmlFor="line1">{t('Address, line 1')}</label>
                  <Field type="text" id="line1" name="line1" />
                  <ErrorMessage name="line1" component="div" className="validation-message" />
                </div>

                <div className="lvl-input">
                  <label htmlFor="line2">{t('Address, line 2')}</label>
                  <Field type="text" id="line2" name="line2" />
                  <ErrorMessage name="line2" component="div" className="validation-message" />
                </div>

                <div className="lvl-input">
                  <label htmlFor="postal_code">{t('Postal code')}</label>
                  <Field type="text" id="postal_code" name="postal_code" />
                  <ErrorMessage name="postal_code" component="div" className="validation-message" />
                </div>

                <div className="lvl-input">
                  <label htmlFor="city">{t('City')}</label>
                  <Field type="text" id="city" name="city" />
                  <ErrorMessage name="city" component="div" className="validation-message" />
                </div>

                <div className="lvl-input">
                  <label htmlFor="state">{t('State')}</label>
                  <Field type="text" id="state" name="state" />
                  <ErrorMessage name="state" component="div" className="validation-message" />
                </div>

                <CardElement options={CARD_ELEMENT_OPTIONS} />
                <ErrorMessage name="stripe" component="div" className="validation-message" />

                <div className="lvl-button-group">
                  <button type="button" onClick={e => history.goBack()}>{t('Cancel')}</button>
                  <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Submit')}</button>
                </div>
              </Form>
            )}
          </Formik>

        </div>
      </div>
    </Fragment>
  )
}

export default PaymentMethodsAdd
