import React, { useEffect, useState } from "react"
import { NavLink, useParams} from "react-router-dom"
import validateUUID from 'uuid-validate'
import { useTranslation } from 'react-i18next'

import { useLevelupSDK } from '../../levelup-sdk'

const WorkspaceSidebar = () => {
  const [ products, setProducts ] = useState([])
  const [ hasGoogleMaps, setHasGoogleMaps ] = useState(false)

  const { t } = useTranslation()

  const { workspaceId, billingAccountId } = useParams()
  const { getProductsGroupsProducts, getBillingAccountWorkspaceServices } =  useLevelupSDK()


  // HARD CODED STRING!
  const GOOGLE_MAPS_PRODUCTS_GROUPS_ID = 'fc440e51-455b-4b49-b8dd-e303cf2cb917'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(GOOGLE_MAPS_PRODUCTS_GROUPS_ID)
      .then(products => {
        setProducts(products)
        Promise.all(products.map(product => getBillingAccountWorkspaceServices(billingAccountId, workspaceId, `?productId=${product.id}`)))
          .then(res => {
            const combinedServices = Array.prototype.concat.apply([], res)
            setHasGoogleMaps(combinedServices.length > 0)
          }).catch(e => console.error(e))
      })
      .catch(error => console.error(error))
  }, [billingAccountId, workspaceId, getProductsGroupsProducts, getBillingAccountWorkspaceServices])

  return (
    <nav className="lvl-sidebar">
      <ul>
        <li><div className="lvl-label">{t('Hosting')}</div></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting`} activeClassName="--active">{t('Hosting')}</NavLink></li>
        {/*<li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/vpsr-hosting`} activeClassName="--active">{t('VPSR Hosting')}</NavLink>
          <ul>
            <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/vpsr-hosting/kubernetes-containers`} activeClassName="--active">{t('Kubernetes Containers')}</NavLink></li>
          </ul>
        </li>*/}
        <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/domains`} activeClassName="--active">{t('Domains')}</NavLink></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/zone-files`} activeClassName="--active">{t('Zone Files')}</NavLink></li>

        <li><div className="lvl-label">{t('Add-Ons')}</div></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring`} activeClassName="--active">{t('Monitoring')}</NavLink></li>
        {hasGoogleMaps && <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/google-maps`} activeClassName="--active">{t('Google Maps')}</NavLink></li>}
        <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms`} activeClassName="--active">{t('System SMS')}</NavLink></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-email`} activeClassName="--active">{t('System Email')}</NavLink></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/ssl-certificates`} activeClassName="--active">{t('SSL Certificates')}</NavLink></li>

        <li><div className="lvl-label">{t('Administration')}</div></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/edit`} activeClassName="--active">{t('Workspace Settings')}</NavLink></li>
      </ul>
    </nav>
  )
}

const BillingAccountSidebar = () => {
  const [ billingAccount, setBillingAccount ] = useState(null)
  const [ oneTimeCredits, setOneTimeCredits ] = useState([])
  const [ percentages, setPercentages ] = useState([])

  const { t } = useTranslation()
  const { billingAccountId } = useParams()
  const {
    getBillingAccount,
    getBillingAccountDiscountsOneTimeCredits,
    getBillingAccountDiscountsPercentages
  } =  useLevelupSDK()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return

    getBillingAccount(billingAccountId).then(res => setBillingAccount(res))

    getBillingAccountDiscountsOneTimeCredits(billingAccountId)
      .then(res => {
        setOneTimeCredits(res)
      })
      .catch(e => console.error(e))

    getBillingAccountDiscountsPercentages(billingAccountId)
      .then(res => {
        setPercentages(res)
      })
      .catch(e => console.error(e))

  }, [billingAccountId])

  return (
    <nav className="lvl-nav lvl-nav--horizontal">
      <ul>
        <li><div className="lvl-label">{t('Workspaces')}</div></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/workspaces`} activeClassName="--active">{t('Workspaces')}</NavLink></li>

        <li><div className="lvl-label">{t('Billing')}</div></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/billing-reports`} activeClassName="--active">{t('Usage & Costs')}</NavLink></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/invoices`} activeClassName="--active">{t('Invoices')}</NavLink></li>
        {oneTimeCredits.length !== 0 || percentages.length !== 0 &&
          <li><NavLink to={`/billing-accounts/${billingAccountId}/discounts`} activeClassName="--active">{t('Discounts')}</NavLink></li>
        }
        <li><NavLink to={`/billing-accounts/${billingAccountId}/payment-methods`} activeClassName="--active">{t('Payment methods')}</NavLink></li>
        {billingAccount && billingAccount.isReseller &&
          <li><NavLink to={`/billing-accounts/${billingAccountId}/reseller/details`} activeClassName="--active">{t('Reseller')}</NavLink></li>
        }

        <li><div className="lvl-label">{t('Team')}</div></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/team`} activeClassName="--active">{t('Team')}</NavLink></li>

        <li><div className="lvl-label">{t('Support')}</div></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/priority-support`} activeClassName="--active">{t('Priority Support')}</NavLink></li>


        <li><div className="lvl-label">{t('Administration')}</div></li>
        <li><NavLink to={`/billing-accounts/${billingAccountId}/edit`} activeClassName="--active">{t('Account Settings')}</NavLink></li>
      </ul>
    </nav>
  )
}

export { WorkspaceSidebar, BillingAccountSidebar }
