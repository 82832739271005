import React from 'react'
import { useTranslation } from 'react-i18next'

const LoadingPage = () => {
  const { t } = useTranslation()

  return (
    <div className="lvl-page"><div className="lvl-page__body"><div>{t('Loading...')}</div></div></div>
  )
}

const ProgressIndicator = () => {
  return (
    <div role="progressbar" className="mdc-linear-progress mdc-linear-progress--indeterminate"><div className="mdc-linear-progress__buffering-dots"></div><div className="mdc-linear-progress__buffer"></div><div className="mdc-linear-progress__bar mdc-linear-progress__primary-bar"><span className="mdc-linear-progress__bar-inner"></span></div><div className="mdc-linear-progress__bar mdc-linear-progress__secondary-bar"><span className="mdc-linear-progress__bar-inner"></span></div></div>
  )
}

export {
  LoadingPage,
  ProgressIndicator
}
