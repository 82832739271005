import React, { Fragment, useState, useEffect } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

const BillingAccountsWorkspacesMonitoringEdit = () => {
  const [ initialValues, setInitialValues ] = useState(null)

  const { t } = useTranslation()
  const history = useHistory()
  const { setNotification } = useNotifications()
  const { billingAccountId, workspaceId, serviceId } = useParams()
  const { patchBillingAccountWorkspaceService, getBillingAccountWorkspaceService } =  useLevelupSDK()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return
    if (!serviceId || !validateUUID(serviceId)) return

    getBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
    .then(service => {
      const returnObj = {}

      returnObj.name = service.name

      if (service.details) {
        if (service.details.url) {
          returnObj.url = service.details.url
        }
        if (service.details.domain) {
          returnObj.domain = service.details.domain
        }
        if (service.details.interval) {
          returnObj.interval = service.details.interval
        }
      }

      setInitialValues(returnObj)
    })
    .catch(e => console.error(e))

  }, [billingAccountId, workspaceId, serviceId])


  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring/${serviceId}`}>&larr; {t('Back')}</Link>
          <span style={{margin: '0 5px 0 5px'}}></span>
          <b>{t('Monitoring')}</b>
        </div>

        <div className="lvl-page__body">
          {initialValues &&
            <Formik
              initialValues={initialValues}
              validationSchema={yup.object({
                name: yup.string().min(2, t('Minimum 2 characters')).max(20, t('Maximum 20 characters')).required(t('Required')),
                url: yup.string().url(),
                domain: yup.string().matches(/[a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/, t('Please provide a valid domain name')).min(3, t('Minimum 3 characters')),
                interval: yup.mixed().oneOf(['15MIN', '30MIN', '1H', '1D', '1W', '1M'])
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)

                const patch = {}
                patch.name = values.name
                patch.details = {}

                if (values.url) 
                  patch.details.url = values.url

                if (values.domain) 
                  patch.details.domain = values.domain

                if (values.interval) 
                  patch.details.interval = values.interval

                patchBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId, patch)
                  .then(res => {
                    history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring/${serviceId}`)
                    setNotification({text: t('Updated 🎉'), status: 'success'})
                    setSubmitting(false)
                  })
                  .catch(e => {
                    setNotification({text: t('Failed to create'), status: 'error'})
                    setSubmitting(false)
                  })
              }}
              >
              {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
                <Form>
                  <div className="lvl-input">
                    <label htmlFor="name">{t('Name')}</label>
                    <Field type="text" id="name" name="name" />
                    <ErrorMessage name="name" component="div" className="validation-message" />
                  </div>

                  {values.url &&
                    <div className="lvl-input">
                      <label htmlFor="url">{t('URL')}</label>
                      <Field type="text" id="url" name="url" />
                      <ErrorMessage name="url" component="div" className="validation-message" />
                    </div>
                  }

                  {values.domain &&
                    <div className="lvl-input">
                      <label htmlFor="domain">{t('Domain')}</label>
                      <Field type="text" id="domain" name="domain" />
                      <ErrorMessage name="domain" component="div" className="validation-message" />
                    </div>
                  }

                  {values.interval &&
                    <div className="lvl-input">
                      <label htmlFor="interval">Interval</label>
                      <div className="lvl-input--select">
                        <Field as="select" name="interval" id="interval">
                          <option value="15MIN">{t('Once every 15 minutes')}</option>
                          <option value="30MIN">{t('Once every 30 minutes')}</option>
                          <option value="1H">{t('Once every hour')}</option>
                          <option value="1D">{t('One a day')}</option>
                          <option value="1W">{t('Once a week')}</option>
                          <option value="1M">{t('Once a month')}</option>
                        </Field>
                      </div>
                    </div>
                  }

                  <div className="lvl-button-group">
                    <button type="button" onClick={e => history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring/${serviceId}`)}>{t('Cancel')}</button>
                    <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Update')}</button>
                  </div>
                </Form>
              )}
            </Formik>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesMonitoringEdit
