import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'
import Table from '../../components/table'

import { prettyPrice } from '../../utils/pretty-prices'

const ResellerDetails = () => {
  const [ loading, setLoading ] = useState(true)
  const [ billingAccount, setBillingAccount ] = useState(null)
  const [ resellerAccounts, setResellerAccounts ] = useState([])
  const [ transactions, setTransactions ] = useState([])

  const { t } = useTranslation()
  const { billingAccountId } = useParams()

  const {
    getBillingAccount,
    getBillingAccountResellerAccounts,
    getBillingAccountTransactions
  } = useLevelupSDK()

  useEffect(() => {
    if (!billingAccountId) return

    getBillingAccount(billingAccountId).then(res => {
      setBillingAccount(res)

      getBillingAccountResellerAccounts(billingAccountId)
        .then(res => {
          setResellerAccounts(res)
          setLoading(false)
        })
        .catch(e => console.error(e))
    })

    getBillingAccountTransactions(billingAccountId).then(res => setTransactions(res))

  }, [])

  let rows = []
  let footerRows = []
  if (billingAccount && resellerAccounts.length > 0 ) {
    rows = resellerAccounts.map(ra => {
      const kickbackSoFar = (ra.stats.subTotalSoFarThisMonth * billingAccount.kickbackPercentage) / 100
      const date = moment().date()
      const dailyKickback = kickbackSoFar / moment().date()
      const estimatedKickback = dailyKickback * moment().daysInMonth()
      return [
        ra.name,
        kickbackSoFar,
        estimatedKickback
      ]
    })

    footerRows.push([
      'total',
      prettyPrice(rows.map(r => r[1]).reduce((a, b) => a + b), 'NOK'),
      prettyPrice(rows.map(r => r[2]).reduce((a, b) => a + b), 'NOK'),
    ])

    // format prices in rows
    rows = rows.map(row => [row[0], prettyPrice(row[1], 'NOK'), prettyPrice(row[2],'NOK')])
  }

  return (
    <div className="lvl-page">
      <div className="lvl-page__header">
        <h1>{t('Reseller Details')}</h1>
      </div>

      { loading && <ProgressIndicator />}

      <div className="lvl-page__body">
        {billingAccount && resellerAccounts &&
          <div>
            <h2>{t('Overview current month')}</h2>
            <Table
              keyPrefix="reseller-rows-"
              headArray={[t('Account'), t('Kickback so far this month'), t('Estimated kickback this month')]}
              rows={rows}
              tableFooterRows={footerRows}
              />
          </div>
        }
        {billingAccount && transactions && 
          <div>
            <h2>{t('History')}</h2>
            <Table
              keyPrefix="transactions-rows-"
              headArray={[t('Date'), t('Due date'), t('Amount paid'), t('Amount pending'), t('Currency')]}
              rows={transactions.map(transaction => [
                moment(transaction.invoiceDate).format('DD.MM.YYYY'),
                moment(transaction.dueDate).format('DD.MM.YYYY'),
                prettyPrice(transaction.amountPayed, 'NOK'),
                prettyPrice(transaction.amountPending, 'NOK'),
                transaction.currency,
              ])}
              />
          </div>
        }
      </div>
    </div>
  )
}

export default ResellerDetails
