import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'
import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'

const PrioritySupportEdit = () => {
  const [ products, setProducts ] = useState([])
  const [ service, setService ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ selectedProduct, setSelectedProduct ] = useState()

  const history = useHistory()
  const { t } = useTranslation()
  const { setNotification } = useNotifications()


  const {
    getProductsGroupsProducts,
    getBillingAccountService,
    patchBillingAccountService,
    deleteBillingAccountService
  } =  useLevelupSDK()
  const { billingAccountId, serviceId } = useParams()

  const PRIORITY_SUPPORT_PRODUCTS_GROUPS_ID = 'd861ea2d-9fed-4426-b43e-2d6f201e9d28'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!serviceId || !validateUUID(billingAccountId)) return

    getProductsGroupsProducts(PRIORITY_SUPPORT_PRODUCTS_GROUPS_ID)
      .then(products => {
        setProducts(products)
      })
      .catch(e => console.error(e))


    getBillingAccountService(billingAccountId, serviceId)
      .then(service => {
        setLoading(false)
        setSelectedProduct(service.productId)
      })
      .catch(error => console.error(error))
  }, [billingAccountId, serviceId])

  const submit = (e) => {
    e.preventDefault()
    patchBillingAccountService(billingAccountId, serviceId, {productId: selectedProduct})
      .then(res => {
        setNotification({text: t('Updated 🎉'), status: 'success'})
        history.goBack()
      })
      .catch(e => {
        setNotification({text: t('Failed to update'), status: 'error'})
      })
  }

  const deleteService = () => {
    deleteBillingAccountService(billingAccountId, serviceId)
      .then(res => {
        setNotification({text: t('Deleted ✌️'), status: 'success'})
        history.goBack()
      })
      .catch(e => {
        setNotification({text: t('Failed to delete service'), status: 'error'})
      })
  }

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
          header={t('Edit Priority Support')}
          backLink={`/billing-accounts/${billingAccountId}/priority-support`}
          options={[
            service && <button className="lvl-button lvl-button--dangerous" onClick={e => deleteService()}><i className="fa fa-trash-o"></i>{t('Delete')}</button>
            ]}
          />
        </div>

        <div className="lvl-page__header">
          <h1>{t('Edit Priority Support')}</h1>
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">
          {!loading && service &&
            <Fragment>
              <div>
                <form onSubmit={e => submit(e)} noValidate>
                  <div className="lvl-input">
                    <label htmlFor="interval">{t('Plan')}</label>
                    <div className="lvl-input--select">
                      <select value={selectedProduct} onChange={e => setSelectedProduct(e.target.value)}>
                        {products.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                      </select>
                    </div>
                  </div>

                  <div className="lvl-button-group">
                    <button type="submit" className="lvl-button lvl-button--proceed">{t('Save')}</button>
                    <button type="button" onClick={e => history.goBack()}>{t('Cancel')}</button>
                  </div>
                </form>
              </div>
            </Fragment>
          }

        </div>
      </div>
    </Fragment>
  )
}

export default PrioritySupportEdit
