import React, { Fragment, useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import ChangeCredentialsPassword from './change-credentials-password'

const SMTPCredentials = () => {
  const [ loading, setLoading ] = useState(true)
  const [ systemEmailCredentials, setSystemEmailCredentials ] = useState()
  const [ newCredentials, setNewCredentials ] = useState({login: '', password: ''})

  const { t } = useTranslation()
  const { billingAccountId, workspaceId, serviceId } = useParams()
  const { setNotification } = useNotifications()

  const {
    getBillingAccountWorkspaceSystemEmailStatsTotal,
    getBillingAccountWorkspaceSystemEmailCredentials,
    postBillingAccountWorkspaceSystemEmailCredentials,
    deleteBillingAccountWorkspaceSystemEmailCredentialsLogin,
  } =  useLevelupSDK()

  const deleteCredential = (login) => {
    if (window.confirm('Are you sure you wish to delete this item? ' + login)) {
      deleteBillingAccountWorkspaceSystemEmailCredentialsLogin(billingAccountId, workspaceId, serviceId, login)
        .then(res => {
          loadCredentials()
          setNotification({text: t('Deleted 🎉'), status: 'success'})
        })
        .catch(e => {
          setNotification({text: t('Failed to delete'), status: 'error'})
        })
    }
  }

  const loadCredentials = () => {
    if (!billingAccountId || !workspaceId || !serviceId) return

    setLoading(true)

    getBillingAccountWorkspaceSystemEmailCredentials(billingAccountId, workspaceId, serviceId)
      .then(res => {
        setSystemEmailCredentials(res)
        setLoading(false)
      })
      .catch(e => setLoading(false))
  }


  useEffect(() => {
    loadCredentials()
  }, [])

  return (
    <Fragment>
      <h2>{t('SMTP Credentials')}</h2>

      {loading && 
        <div>Laster...</div>
      }

      {systemEmailCredentials && systemEmailCredentials.items &&
        <div>
          <table className="lvl-table-definition-list">
            <thead>
              <tr>
                <th>Login</th>
                <th>Mailbox</th>
                <th>Created at</th>
                <th>Byte size</th>
                <th>Delete</th>
                <th>Update password</th>
              </tr>
            </thead>
            <tbody>
              {systemEmailCredentials.items.map(credential => (
                <tr key={credential.login}>
                  <td>{credential.login}</td>
                  <td>{credential.mailbox}</td>
                  <td>{credential.created_at}</td>
                  <td>{credential.size_bytes}</td>
                  <td><button onClick={e => deleteCredential(credential.login)}>Delete</button></td>
                  <td>
                    <ChangeCredentialsPassword login={credential.login} billingAccountId={billingAccountId} workspaceId={workspaceId} serviceId={serviceId} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }

      <div>
        <h3>{t('SMTP Settings')}</h3>
        <p>smtp.mailgun.org</p>
        <p>Our servers listen on ports 25, 587, and 465 (SSL/TLS)</p>
        <p>Use full email address like: brad@mailgun.levelup.no as the username.</p>
      </div>

      <div>
        <h3>{t('Add SMTP credentials')}</h3>

        <Formik
          initialValues={{
            login: '',
            password: ''
          }}
          validationSchema={yup.object({
            login: yup.string().min(2, t('Minimum 2 characters')).max(20, t('Maximum 20 characters')).required(t('Required')),
            password: yup.string().min(5, t('Minimum 5 characters')).max(32, t('Maximum 32 characters')).required(t('Required')),
          })}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setSubmitting(true)

            postBillingAccountWorkspaceSystemEmailCredentials(billingAccountId, workspaceId, serviceId, values)
              .then(res => {
                setSubmitting(false)
                setNotification({text: t('Updated 🎉'), status: 'success'})
                loadCredentials()
              })
              .catch(e => {
                setNotification({text: t(`Failed - ${e.response.data.message}`), status: 'error'})
                setErrors({mailgun: e.response.data.message})
                setSubmitting(false)
              })
          }}
          >
          {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
            <Form>

              <div className="lvl-input">
                <label htmlFor="login">{t('Login')}</label>
                <Field type="text" id="login" name="login" />
                <ErrorMessage name="login" component="div" className="validation-message" />
              </div>

              <div className="lvl-input">
                <label htmlFor="password">{t('Password')}</label>
                <Field type="password" id="password" name="password" />
                <ErrorMessage name="password" component="div" className="validation-message" />
              </div>

              <ErrorMessage name="mailgun" component="div" className="validation-message" />

              <div className="lvl-button-group">
                <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Create')}</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  )
}

export default SMTPCredentials
