import React, { useRef, useEffect} from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useAuth0 } from "../../../react-auth0-spa"

const ProfileDropdown = ({navOpen, setNavOpen}) => {
  const { logout } = useAuth0()
  const { t } = useTranslation()

  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
          setNavOpen(false);
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  });

  return navOpen ? (
    <div className="dropdown-menu" ref={ref}>
      <ul>
        <li><Link onClick={e => setNavOpen(false)} to="/profile">{t('Profile')}</Link></li>
        <li>
          <a href="" onClick={(e) => {
            e.preventDefault()
            logout({returnTo: window.location.origin})
          }}>{t('Log out')}</a>
        </li>
      </ul>
    </div>
  ): <div />
}

export default ProfileDropdown
