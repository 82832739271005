import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import Table from '../../components/table'

import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'


import { prettyPrice } from '../../utils/pretty-prices'

const BillingAccountsWorkspacesSystemSMSEdit = () => {
  const [ loading, setLoading ] = useState(false)
  const [ service, setService ] = useState()

  const { setNotification } = useNotifications()
  const history = useHistory()
  const { t } = useTranslation()
  const {
    getBillingAccountWorkspaceService,
    patchBillingAccountWorkspaceService
  } =  useLevelupSDK()

  const { billingAccountId, workspaceId, serviceId } = useParams()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return
    if (!serviceId || !validateUUID(serviceId)) return

    getBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
      .then(res => setService(res))
      .catch(error => {
         setNotification({text: t('Failed'), status: 'error'})
         history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms/${serviceId}`)
      })
  }, [billingAccountId, workspaceId])


  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
            header={t('Edit System SMS')}
            backLink={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms/${serviceId}`}
            options={[
              <Link className="lvl-button" to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms/${serviceId}/edit`}><i className="fa fa-pencil"></i>{t('Edit')}</Link>,
              // <button className="lvl-button lvl-button--dangerous" onClick={e => deleteService()}><i className="fa fa-trash-o"></i>{t('Delete')}</button>
              ]}
          />
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__header">
          <h1>{t('Edit System SMS')}</h1>
        </div>

        <div className="lvl-page__body">
          {service && 
            <Formik
              initialValues={{
                name: service.name,
              }}
              validationSchema={yup.object({
                name: yup.string().min(2, t('Minimum 2 characters')).max(20, t('Maximum 20 characters')).required(t('Required')),
              })}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(true)
                patchBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId, values)
                  .then(data => {
                    if (data && data.id) {
                      history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms/${data.id}`)
                      setNotification({text: t('Updated 🎉'), status: 'success'})
                      setSubmitting(false)
                    } else {
                      setNotification({text: t('Failed to create'), status: 'error'})
                      setSubmitting(false)
                    }
                  }).catch(error => {
                    setNotification({text: t('Failed to create'), status: 'error'})
                    setSubmitting(false)
                  })
              }}
              >
              {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
                <Form>

                  <div className="lvl-input">
                    <label htmlFor="name">{t('Name')}</label>
                    <Field type="text" id="name" name="name" />
                    <ErrorMessage name="name" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-button-group">
                    <button type="button" onClick={e => history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms/${serviceId}`)}>{t('Cancel')}</button>
                    <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Save')}</button>
                  </div>
                </Form>
              )}
            </Formik>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesSystemSMSEdit
