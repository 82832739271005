import React, { Fragment, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import iso3311a2 from 'iso-3166-1-alpha-2'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

const BillingAccountsAdd = () => {
  const [ isSubmitting, setIsSubmitting ] = useState()

  const history = useHistory()
  const { t } = useTranslation()
  const { postBillingAccount } =  useLevelupSDK()
  const { setNotification } = useNotifications()

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Add Account')}</h1>
        </div>

        <div className="lvl-page__body">

          <Formik
            initialValues={{
              name: '',
              isCompany: false,
              vatNumber: '',
              personName: '',
              country: 'NO',
              adressLine1: '',
              adressLine2: '',
              postalCode: '',
              city: '',
              billingPhoneNumber: '',
              billingEmail: '',
              preferredLanguage: 'en',
              technicalContactName: '',
              technicalContactEmail:'',
              technicalContactPhone: '',
            }}
            validationSchema={yup.object({
              name: yup.string().min(2, t('Minimum length of 2 characters')).required(t('Required')),
              isCompany: yup.boolean().required(t('Required')),
              personName: yup.string().min(2, t('Minimum length of 2 characters')).required(t('Required')),
              country: yup.string().min(1).required(t('Required')),
              adressLine1: yup.string().min(2, t('Minimum length of 2 characters')).required(t('Required')),
              postalCode: yup.string().min(2, t('Minimum length of 2 characters')).required(t('Required')),
              city: yup.string().min(2, t('Minimum length of 2 characters')).required(t('Required')),
              billingEmail: yup.string().email(t('Email must be valid')).required(t('Required')),
              technicalContactEmail: yup.string().email(t('Email must be valid')).required(t('Required')),
              preferredLanguage: yup.string().min(1).required(t('Required')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              postBillingAccount(values)
                .then(res => {
                  history.push(`/billing-accounts/${res.id}/workspaces`)
                  setSubmitting(false)
                })
                .catch(e => {
                  setNotification({text: t('Failed to create'), status: 'error'})
                  setSubmitting(false)
                })
            }}
            >
            {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => {
              // console.log('values', values)
              return (
                <Form>

                  <div className="lvl-input">
                    <label htmlFor="name">{t('Name')}</label>
                    <Field type="text" id="name" name="name" data-hj-allow />
                    <ErrorMessage name="name" component="div" className="validation-message" />
                  </div>

                  <label className="lvl-input-checkbox">
                    {t('Company')}
                    <input type="checkbox" 
                           checked={values.isCompany} 
                           id="isCompany" 
                           onChange={handleChange}
                           onBlur={handleBlur} />
                    <span className="checkmark"></span>
                  </label>

                  {values.isCompany &&
                    <div className="lvl-input">
                      <label htmlFor="vatNumber">{t('Organization number')}</label>
                      <Field type="text" id="vatNumber" name="vatNumber" data-hj-allow />
                      <ErrorMessage name="vatNumber" component="div" className="validation-message" />
                    </div>
                  }

                  <fieldset>
                    <h3>{t('Contact person')}</h3>

                    <div className="lvl-input">
                      <label htmlFor="personName">{t('Name')}</label>
                      <Field type="text" id="personName" name="personName" data-hj-allow />
                      <ErrorMessage name="personName" component="div" className="validation-message" />
                    </div>

                    <div className="lvl-input">
                      <label htmlFor="billingPhoneNumber">{t('Phone')}</label>
                      <PhoneInput
                        country={'no'}
                        containerClass="lvl-input"
                        inputClass="lvl-input"
                        value={values.billingPhoneNumber}
                        onChange={phone => setFieldValue('billingPhoneNumber', phone)}
                        data-hj-allow
                      />
                    </div>

                    <div className="lvl-input">
                      <label htmlFor="billingEmail">{t('Email')}</label>
                      <Field type="email" id="billingEmail" name="billingEmail" data-hj-allow />
                      <ErrorMessage name="billingEmail" component="div" className="validation-message" />
                    </div>
                  </fieldset>

                  <fieldset>
                    <h3>{t('Technical contact')}</h3>
                    <p><small><i className="fas fa-info-circle"></i>&nbsp;<em>{t('This is the person that is going to get all technical updates.')}</em></small></p>

                    <div className="lvl-input">
                      <label htmlFor="technicalContactName">{t('Name')}</label>
                      <Field type="text" id="technicalContactName" name="technicalContactName" data-hj-allow />
                      <ErrorMessage name="technicalContactName" component="div" className="validation-message" />
                    </div>

                    <div className="lvl-input">
                      <label htmlFor="technicalContactPhone">{t('Phone')}</label>
                      <PhoneInput
                        country={'no'}
                        containerClass="lvl-input"
                        inputClass="lvl-input"
                        value={values.technicalContactPhone}
                        onChange={phone => setFieldValue('technicalContactPhone', phone)}
                        data-hj-allow
                      />
                    </div>

                    <div className="lvl-input">
                      <label htmlFor="technicalContactEmail">{t('Email')}</label>
                      <Field type="email" id="technicalContactEmail" name="technicalContactEmail" data-hj-allow />
                      <ErrorMessage name="technicalContactEmail" component="div" className="validation-message" />
                    </div>
                  </fieldset>

                  <fieldset>
                    <h3>{t('Settings')}</h3>

                    <div className="lvl-input lvl-input--select">
                      <label htmlFor="preferredLanguage">{t('Preferred contact language')}</label>
                      <Field as="select" id="preferredLanguage" name="preferredLanguage">
                        <option value="en">{t('English')}</option>
                        <option value="nb">{t('Norwegian')}</option>
                      </Field>
                    </div>
                  </fieldset>

                  <fieldset>
                    <h3>{t('Address')}</h3>
                    <div className="lvl-input lvl-input--select">
                      <label htmlFor="country">{t('Country')}</label>
                      <Field as="select" id="country" name="country">
                        {iso3311a2.getCodes().map(c => <option key={c} value={c}>{iso3311a2.getCountry(c)}</option>)}
                      </Field>
                    </div>

                    <div className="lvl-input">
                      <label htmlFor="adressLine1">{t('Address, line 1')}</label>
                      <Field type="text" id="adressLine1" name="adressLine1" data-hj-allow />
                      <ErrorMessage name="adressLine1" component="div" className="validation-message" />
                    </div>

                    <div className="lvl-input">
                      <label htmlFor="adressLine2">{t('Address, line 2')}</label>
                      <Field type="text" id="adressLine2" name="adressLine2" data-hj-allow />
                      <ErrorMessage name="adressLine2" component="div" className="validation-message" />
                    </div>

                    <div className="lvl-input">
                      <label htmlFor="postalCode">{t('Postal code')}</label>
                      <Field type="text" id="postalCode" name="postalCode" data-hj-allow />
                      <ErrorMessage name="postalCode" component="div" className="validation-message" />
                    </div>


                    <div className="lvl-input">
                      <label htmlFor="city">{t('City')}</label>
                      <Field type="text" id="city" name="city" data-hj-allow />
                      <ErrorMessage name="city" component="div" className="validation-message" />
                    </div>

                  </fieldset>

                  <div className="lvl-button-group">
                    <button type="button" onClick={e => history.goBack()}>{t('Cancel')}</button>
                    <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Submit')}</button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsAdd
