import React, { useEffect, useCallback, useState, Fragment } from "react"
import { Router, Switch, Link } from "react-router-dom"
import { useTranslation, Trans } from 'react-i18next'

import './app.scss'

import history from "../utils/history"
import { useAuth0 } from "../react-auth0-spa"
import { useLevelupSDK } from '../levelup-sdk'
import { useNotifications } from '../notifications'
import { useModal } from '../modal'

import PrivateRoute from "../components/private-route"

import { UserMessage } from '../components/misc-ui-components/user-notification'
import { UserNotification } from '../components/misc-ui-components/user-notification'
import { Modal } from '../components/misc-ui-components/modal'


// User related
import Profile from "../pages/profile"


// billing account related
import BillingAccountsList from '../pages/ba-list'
import BillingAccountsAdd from '../pages/ba-add'
import BillingAccountBillingReports from '../pages/ba-billing-reports'
import BillingAccountInvoices from '../pages/ba-invoices'
import BillingAccountInvoicesDetails from '../pages/ba-invoices-details'
import BillingAccountDiscounts from '../pages/ba-discounts'

import BillingAccountResellerDetails from '../pages/ba-reseller-details'

import BillingAccountsWorkspacesList from '../pages/ba-workspaces-list'
import BillingAccountsWorkspacesAdd from '../pages/ba-workspaces-add'


import BillingAccountsEdit from '../pages/ba-edit'
import BillingAccountsPaymentMethods from '../pages/ba-payment-methods'
import BillingAccountsPaymentMethodsAdd from '../pages/ba-payment-methods-add'
import BillingAccountsPaymentMethodsEdit from '../pages/ba-payment-methods-edit'

import BillingAccountTeam from "../pages/ba-team"
import BillingAccountTeamAdd from '../pages/ba-team-add'

import BillingAccountsNewTicket from '../pages/ba-new-ticket'

import BillingAccountsPrioritySupport from '../pages/ba-priority-support'
import BillingAccountsPrioritySupportEdit from '../pages/ba-priority-support-edit'
import BillingAccountsPrioritySupportAdd from '../pages/ba-priority-support-add'

// workspace related
import BillingAccountWorkspacesDetail from '../pages/ba-workspaces-detail'
import BillingAccountsWorkspacesEdit from '../pages/ba-workspaces-edit'

import BillingAccountsWorkspaecsAddContact from '../pages/ba-workspaces-add-contact'


import BillingAccountsWorkspaecsSharedHosting from '../pages/ba-workspaces-shared-hosting'
import BillingAccountsWorkspaecsSharedHostingDetail from '../pages/ba-workspaces-shared-hosting-detail'
import BillingAccountsWorkspaecsSharedHostingAdd from '../pages/ba-workspaces-shared-hosting-add'

import BillingAccountsWorkspacesVpsrHosting from '../pages/ba-workspaces-vpsr-hosting'
import BillingAccountsWorkspacesKubernetesContainers from '../pages/ba-workspaces-kubernetes-containers'
import BillingAccountsWorkspacesKubernetesContainersDetails from '../pages/ba-workspaces-kubernetes-containers-details'

import BillingAccountsWorkspacesDomains from '../pages/ba-workspaces-domains'
import BillingAccountsWorkspacesDomainsDetails from '../pages/ba-workspaces-domains-details'

import BillingAccountsWorkspacesZoneFiles from '../pages/ba-workspaces-zone-files'
import BillingAccountsWorkspacesZoneFilesDetails from '../pages/ba-workspaces-zone-files-details'


import BillingAccountsWorkspacesMonitoring from '../pages/ba-workspaces-monitoring'
import BillingAccountsWorkspacesMonitoringDetail from '../pages/ba-workspaces-monitoring-details'
import BillingAccountsWorkspacesMonitoringAdd from '../pages/ba-workspaces-monitoring-add'
import BillingAccountsWorksapcesMonitoringEdit from '../pages/ba-workspaces-monitoring-edit'


import BillingAccountsWorkspacesGoogleMaps from '../pages/ba-workspaces-google-maps'
import BillingAccountsWorkspacesGoogleMapsDetails from '../pages/ba-workspaces-google-maps-details'

import BillingAccountWorkspacesSystemSMS from '../pages/ba-workspaces-system-sms'
import BillingAccountWorkspacesSystemSMSDetails from '../pages/ba-workspaces-system-sms-details'
import BillingAccountWorkspacesSystemSMSAdd from '../pages/ba-workspaces-system-sms-add'
import BillingAccountWorkspacesSystemSMSEdit from '../pages/ba-workspaces-system-sms-edit'

import BillingAccountsWorkspacesSystemEmail from '../pages/ba-workspaces-system-email'
import BillingAccountsWorkspacesSystemEmailDetails from '../pages/ba-workspaces-system-email-details'

import BillingAccountsWorkspacesSSLCertificates from '../pages/ba-workspaces-ssl-certificates'


import ComponentsOverview from '../pages/components-overview'

const App = () => {
  const [ userMessage, setUserMessage ] = useState(null)
  const [ userDetails, setUserDetails ] = useState(null)

  const {
    loading,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0()

  const { t, i18n } = useTranslation()
  const { getMe, getStatus, getBillingAccounts, getBillingAccountTransactions } =  useLevelupSDK()
  const { notification } = useNotifications()
  const { modal, setModal } = useModal()

  // redirect to sign in screen
  useEffect(() => {
    if (!loading && !isAuthenticated) {
      loginWithRedirect({})
    }
  }, [loading, isAuthenticated])

  useEffect(() => {
    // only run this if the user is authenticated
    if (!isAuthenticated) return

    getMe().then(user => setUserDetails(user)).catch(e => console.error(e))
  }, [isAuthenticated]) // only run again if isAuthenticated changes

  useEffect(() => {
    if (!userDetails) return
    if (!userDetails['user_metadata']) return
    if (!userDetails['user_metadata'].language) return
    // Set language if you can!
    i18n.changeLanguage(userDetails['user_metadata'].language)
  }, [userDetails, i18n])

  // check if there is un-paid invoices
  useEffect(() => {
    // reset first
    setUserMessage(null)

    if (!isAuthenticated) return

    getStatus()
      .then(res => {
        if (res.unPaidInvoices && res.unPaidInvoices.length > 0) {
          setUserMessage({
            text: <span>You haven't paid the due, please pay to avoid interuptions of services. ({res.unPaidInvoices[0].billingAccount.name}) <Link to={`/billing-accounts/${res.unPaidInvoices[0].billingAccount.id}/invoices/${res.unPaidInvoices[0].id}`}>See invoice</Link></span>,
            status: 'error'
          })
        }
      })
      .catch(e => console.error(e))
  }, [isAuthenticated])

  return (loading || !isAuthenticated) ? <div /> : (
    <div className="app lvl-section--ba">
      <div className="lvl-cta-help-container h-no-print">
        <h3>
          <Trans i18nKey="weAreImprovingTheConsole">
            We are doing improvements to make the Console better for you. Please dont hesitate to <a onClick={e => setModal({
              content: (
                <Fragment>
                  <h1 className="demo">{t("We'd love to help you")}</h1>
                </Fragment>
              )
            }) } >talk to us</a> 🤗
          </Trans>
        </h3>
      </div>

      <Fragment>
        {notification.active && <UserNotification text={notification.text} status={notification.status} />}
        {modal.active && <Modal/>}

        <Router history={history}>
          {userMessage && <UserMessage text={userMessage.text} status={userMessage.status} /> }
          <Switch>
            <PrivateRoute path="/components-overview" exact component={ComponentsOverview} />

            {/* Workspace related */}
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/add" exact component={BillingAccountsWorkspacesAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId" exact component={BillingAccountWorkspacesDetail} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/edit" exact component={BillingAccountsWorkspacesEdit} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/add-contact" exact component={BillingAccountsWorkspaecsAddContact} />

            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/shared-hosting" exact component={BillingAccountsWorkspaecsSharedHosting} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/shared-hosting/add" exact component={BillingAccountsWorkspaecsSharedHostingAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/shared-hosting/:serviceId" exact component={BillingAccountsWorkspaecsSharedHostingDetail} />

            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/vpsr-hosting" exact component={BillingAccountsWorkspacesVpsrHosting} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/vpsr-hosting/kubernetes-containers" exact component={BillingAccountsWorkspacesKubernetesContainers} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/vpsr-hosting/kubernetes-containers/:serviceId" exact component={BillingAccountsWorkspacesKubernetesContainersDetails} />

            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/domains" exact component={BillingAccountsWorkspacesDomains} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/domains/:serviceId" exact component={BillingAccountsWorkspacesDomainsDetails} />

            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/zone-files" exact component={BillingAccountsWorkspacesZoneFiles} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/zone-files/:serviceId" exact component={BillingAccountsWorkspacesZoneFilesDetails} />

            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/monitoring" exact component={BillingAccountsWorkspacesMonitoring} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/monitoring/add" exact component={BillingAccountsWorkspacesMonitoringAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/monitoring/:serviceId" exact component={BillingAccountsWorkspacesMonitoringDetail} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/monitoring/:serviceId/edit" exact component={BillingAccountsWorksapcesMonitoringEdit} />


            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/google-maps" exact component={BillingAccountsWorkspacesGoogleMaps} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/google-maps/:serviceId" exact component={BillingAccountsWorkspacesGoogleMapsDetails} />

            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/system-sms" exact component={BillingAccountWorkspacesSystemSMS} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/system-sms/add" exact component={BillingAccountWorkspacesSystemSMSAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/system-sms/:serviceId" exact component={BillingAccountWorkspacesSystemSMSDetails} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/system-sms/:serviceId/edit" exact component={BillingAccountWorkspacesSystemSMSEdit} />

            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/system-email" exact component={BillingAccountsWorkspacesSystemEmail} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/system-email/:serviceId" exact component={BillingAccountsWorkspacesSystemEmailDetails} />

            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/:workspaceId/ssl-certificates" exact component={BillingAccountsWorkspacesSSLCertificates} />

            {/* Billing account related */}
            <PrivateRoute path="/billing-accounts/add" exact component={BillingAccountsAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/billing-reports" exact component={BillingAccountBillingReports} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/invoices" exact component={BillingAccountInvoices} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/discounts" exact component={BillingAccountDiscounts} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/reseller/details" exact component={BillingAccountResellerDetails} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces" exact component={BillingAccountsWorkspacesList} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/edit" exact component={BillingAccountsEdit} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/payment-methods" exact component={BillingAccountsPaymentMethods} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/payment-methods/add" exact component={BillingAccountsPaymentMethodsAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/team" exact component={BillingAccountTeam} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/team/add" exact component={BillingAccountTeamAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/new-ticket" exact component={BillingAccountsNewTicket} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/priority-support" exact component={BillingAccountsPrioritySupport} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/priority-support/add" exact component={BillingAccountsPrioritySupportAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/priority-support/:serviceId/edit" exact component={BillingAccountsPrioritySupportEdit} />


            {/* Billing account related */}
            <PrivateRoute path="/billing-accounts/add" exact component={BillingAccountsAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/billing-reports" exact component={BillingAccountBillingReports} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/invoices" exact component={BillingAccountInvoices} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/invoices/:transactionId" exact component={BillingAccountInvoicesDetails} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/discounts" exact component={BillingAccountDiscounts} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces" exact component={BillingAccountsWorkspacesList} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/workspaces/add" exact component={BillingAccountsWorkspacesAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/edit" exact component={BillingAccountsEdit} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/payment-methods" exact component={BillingAccountsPaymentMethods} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/payment-methods/add" exact component={BillingAccountsPaymentMethodsAdd} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/payment-methods/:paymentMethodId/edit" exact component={BillingAccountsPaymentMethodsEdit} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/team" exact component={BillingAccountTeam} />
            <PrivateRoute path="/billing-accounts/:billingAccountId/team/add" exact component={BillingAccountTeamAdd} />

            {/* User related */}
            <PrivateRoute path="/" exact component={BillingAccountsList} />
            <PrivateRoute path="/profile" exact component={Profile} />
          </Switch>

        </Router>
      </Fragment>
    </div>
  );
}

export default App
