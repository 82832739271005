import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

import { useLevelupSDK } from '../../../levelup-sdk'

const AccountPicker = ({billingAccount}) => {
  const [ billingAccountDropdownOpen, setBillingAccountDropdownOpen ] = useState(false)
  const [ billingAccounts, setBillingAccounts] = useState([])
  const [ filter, setFilter ] = useState('')

  const history = useHistory()

  const {
    getBillingAccounts,
  } =  useLevelupSDK()

  const { t } = useTranslation()

  useEffect(() => {
    getBillingAccounts().then(res => setBillingAccounts(res))
  }, [])

  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
          setBillingAccountDropdownOpen(false)
      }
  }

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true)
      return () => {
          document.removeEventListener('click', handleClickOutside, true)
      }
  })

  const goToAndClose = (e, path) => {
    e.preventDefault()
    setBillingAccountDropdownOpen(false)
    history.push(path)
  }


  return (
    <div className="client-picker" ref={ref}>
      <div className="client-picker__field" onClick={e => setBillingAccountDropdownOpen(!billingAccountDropdownOpen)}>
        <span className="lvl-label">{t('Account')}:<br /></span>
        {billingAccount ?
            <span>{billingAccount.name} {billingAccount.submittedForDeletion && <span className="h-text-warning">({t('Deletion requested')})</span>}</span>
          :
            <span>{t('Select account')}</span>
        }
      </div>

      {billingAccountDropdownOpen &&
        <div class="dropdown-menu dropdown-menu--left-aligned">
          <ul>
            {billingAccount && <li><a onClick={e => goToAndClose(e, `/billing-accounts/${billingAccount.id}/workspaces`)}>{t('Account home')}</a><hr /></li>}
            <span className="dropdown-menu__label lvl-label">
              {billingAccount ? t('Switch account') : t('Select account') }
            </span>
            <li>
              <div className="dropdown-menu__search">
                <input autoFocus
                       type="text"
                       placeholder={t('Search accounts')}
                       style={{width: '100%', flex: 1, color: 'black'}}
                       value={filter}
                       className="lvl-input-inline"
                       onChange={e => setFilter(e.target.value)} />
              </div>
            </li>
            {_.orderBy(billingAccounts, [billingAccount => billingAccount.name.toLowerCase()], ['asc'])
              .filter(b => filter.length > 0 ? b.name.toLowerCase().includes(filter.toLowerCase()) : true)
              .slice(0, 3)
              .map(ba => <li><a onClick={e => goToAndClose(e, `/billing-accounts/${ba.id}/workspaces`)}>{ba.name}</a></li>)}
            <li className="downdown-menu__item"><hr /><a onClick={e => goToAndClose(e, `/`)}>{t('View all accounts')}</a></li>
          </ul>
        </div>
      }
    </div>
  )
}

export default AccountPicker
