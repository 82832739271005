import React from 'react'

const logoSVG = '<svg version="1.1" class="lvl-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 656.6 82.1" style="enable-background:new 0 0 656.6 82.1;" xml:space="preserve"><style type="text/css">.st0{fill:none;}.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#333E43;}.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#E15E4A;}</style><g><rect class="st0" width="656.6" height="82.1"/><path class="st1" d="M630.8,40.1h-20.2V10.8h20.2c9.1,0,15.3,6,15.3,14.6S639.9,40.1,630.8,40.1z M610.6,79.2V48.7H632c15.2,0,24.1-10.6,24.1-23.2S647.4,2.3,632,2.3H601v76.9H610.6z M537.3,80.6c20.9,0,31.4-12.2,31.4-31.4V2.3h-9.6v46.8c0,14.1-7.6,23-21.8,23c-14.2,0-21.8-8.9-21.8-23V2.3h-9.6v47.1C505.9,68.3,516.4,80.6,537.3,80.6z M481.1,79.2v-8.5h-35.8V2.3h-9.6v76.9H481.1z M406.1,79.2v-8.5h-40.8V44.2h40v-8.5h-40V10.8h40.8V2.3h-50.4v76.9H406.1z M300.2,79.2l31-76.9h-11l-26,66.4l-26-66.4h-11l30.9,76.9H300.2z M235.7,79.2v-8.5h-40.8V44.2h40v-8.5h-40V10.8h40.8V2.3h-50.4v76.9H235.7z M157.4,79.2v-8.5h-35.8V2.3h-9.6v76.9H157.4z"/><rect class="st0" width="656.6" height="82.1"/><path class="st2" d="M41.9,81.6V0h-8.3v81.6H41.9z M8.3,81.6V0H0v81.6H8.3z"/><rect class="st0" width="656.6" height="82.1"/></g></svg>'

const LevelupLogo = () => {

  const LevelupLogoSVG = logoSVG

  return (
    <span dangerouslySetInnerHTML={{__html: LevelupLogoSVG}}></span>
  )
}

const LevelupBadge = () => {

  const LevelupBadgeHtml = logoSVG

  return (
    <span className="levelup-badge" dangerouslySetInnerHTML={{__html: LevelupBadgeHtml}}></span>
  )
}


export {
  LevelupLogo,
  LevelupBadge
}



