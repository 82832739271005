import React, { Fragment } from "react"

import { useModal } from '../../../modal'

import { useTranslation } from 'react-i18next'

const ContactUs = ({preText}) => {

  const { modal, setModal } = useModal()
  const { t } = useTranslation()

  return (
    <Fragment>
      <p><i>{preText} <a onClick={e => setModal({
        content: (
          <h1 className="demo">{preText}</h1>
        )
      }) }>{t('Contact us')}</a></i></p>
    </Fragment>
  )
}

ContactUs.defaultProps = {
  preText: ''
}

export default ContactUs
