import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import _ from 'lodash'
import validateUUID from 'uuid-validate'

import mailgunLogo from '../../assets/mailgun-logo.png'


import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import NoServices from '../../components/misc-ui-components/no-services'
import ContactUs from '../../components/misc-ui-components/contact-us'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'
import ProviderExcerpt from '../../components/misc-ui-components/provider-excerpt'

const BillingAccountsWorkspacesSystemEmail = () => {
  const [ products, setProducts ] = useState([])
  const [ services, setServices ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const history = useHistory()
  const { t } = useTranslation()
  const { billingAccountId, workspaceId } = useParams()
  const { getProductsGroupsProducts, getBillingAccountWorkspaceServices } =  useLevelupSDK()

  // HARD CODED STRING!
  const SYSTEM_EMAIL_PRODUCTS_GROUPS_ID = '3c1b6570-b113-443d-8f35-d545d078837c'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(SYSTEM_EMAIL_PRODUCTS_GROUPS_ID)
      .then(products => {
        setProducts(products)
        Promise.all(products.map(product => getBillingAccountWorkspaceServices(billingAccountId, workspaceId, `?productId=${product.id}`)))
          .then(res => {
            const combinedServices = Array.prototype.concat.apply([], res)
            setServices(combinedServices)
            setLoading(false)
          }).catch(e => console.error(e))
      })
      .catch(error => console.error(error))
  }, [billingAccountId, workspaceId, getProductsGroupsProducts, getBillingAccountWorkspaceServices])

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('System Email')}</h1>
          <ProviderExcerpt logoHtml={<img src={mailgunLogo} />} />
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">
          {!loading && services.length > 0 &&
            <Fragment>
              <Table
                headArray={[
                  t('Name'),
                  t('Product'),
                  // t('Actions')
                ]}
                rows={_.orderBy(services, [service => service.name.toLowerCase()], ['asc']).map(service => {
                  const product = products.find(p => p.id === service.productId)

                  return [
                    <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-email/${service.id}`}>{service.name}</Link>,
                    product.name || 'Laster...',
                    // t('Edit')
                  ]
                })} />
              <ContactUs preText={t('Add more System email?')} />
            </Fragment>
          }

          {!loading && services.length === 0 &&
            <NoServices
              productName={t('system email')}
              />
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesSystemEmail
