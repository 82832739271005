import React, { useContext, useState } from "react"

export const ModalContext = React.createContext()
export const useModal = () => useContext(ModalContext)
export const ModalProvider = ({
  children,
  ...initOptions
}) => {
  const defaultValues = {
    active: false,
    content: <div />
  }
  const [ modal, setModal ] = useState(defaultValues)

  return (
    <ModalContext.Provider
      value={{
        modal: modal,
        setModal: ({content}) => {
          console.log('trigger modal', content)
          setModal({
            active: true,
            content: content
          })
        },
        destroyModal: () => {
          setModal(defaultValues)
        }
      }}>
      {children}
    </ModalContext.Provider>
  )
}
