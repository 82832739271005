import React from 'react'

const StatusLabel = ({type, text}) => {
  /*
    type can be: 'warning', 'error', 'success'
    text: just a string
  */

  let labelClass = ``
  if (type) {
    labelClass = `lvl-status-label--${type}`
  }
  return (
    <div className={`lvl-status-label ${labelClass}`}>{text}</div>
  )
}

export {
  StatusLabel
}



