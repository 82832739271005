import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from "react-router-dom"
import _ from 'lodash'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import NoServices from '../../components/misc-ui-components/no-services'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'
import { LevelupBadge } from '../../components/misc-ui-components/levelup-logo'
import ProviderExcerpt from '../../components/misc-ui-components/provider-excerpt'

import { Mixpanel } from '../../mixpanel';

const BillingAccountsWorkspacesMonitoring = () => {
  const [ products, setProducts ] = useState([])
  const [ services, setServices ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const { t } = useTranslation()
  const history = useHistory()
  const { billingAccountId, workspaceId } = useParams()
  const {
    getProductsGroupsProducts,
    getBillingAccountWorkspaceServices
  } =  useLevelupSDK()


  // HARD CODED STRING!
  const MONITORING_PRODUCTS_GROUPS_ID = 'bbc0e9a2-7338-470a-b368-36c4b71f1e38'

  const refresh = () => {
    getProductsGroupsProducts(MONITORING_PRODUCTS_GROUPS_ID)
      .then(products => {
        setProducts(products)
        Promise.all(products.map(product => getBillingAccountWorkspaceServices(billingAccountId, workspaceId, `?productId=${product.id}`)))
          .then(res => {
            const combinedServices = Array.prototype.concat.apply([], res)
            setServices(combinedServices)
            setLoading(false)
          }).catch(e => console.error(e))
      })
      .catch(error => console.error(error))
  }

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    refresh()

  }, [billingAccountId, workspaceId])

  return (
    <Fragment>
      <div className="lvl-page">
        {loading && <ProgressIndicator />}

        <div className="lvl-page__header">
          <div className="h-flex-wrap h-flex-align-center">
            <h1>{t('Monitoring')}</h1>
            <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring/add`} onClick={e => Mixpanel.track("Clicked add new monitoring", {"var1": "hip-hop", "var2": 42})} className="lvl-button h-margin-left-one-unit">{t('Create new monitor')}</Link>
          </div>
          <ProviderExcerpt logoHtml={<LevelupBadge />} />
        </div>

        <div className="lvl-page__body">

          {!loading && services.length > 0 &&
            <Fragment>
              <Table
                headArray={[
                  t('Name'),
                  t('Product'),
                  t('Status'),
                  // t('Actions')
                ]}
                rows={_.orderBy(services, [service => service.name ? service.name.toLowerCase() : ''], ['asc']).map(service => {
                  const product = products.find(p => p.id === service.productId) || {}
                  let status = ''
                  if (service.details && service.details.status) {
                    status = service.details.status
                  }
                  return [
                    <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring/${service.id}`}>{service.name}</Link>,
                    product.name || 'Laster...',
                    status === 'OK' ? <span className="lvl-status-label lvl-status-label--success">OK</span> : <span className={`lvl-status-label lvl-status-label--${status.toLowerCase()}`}>{status}</span>,
                    // <Fragment>
                    //   <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring/${service.id}`}>{t('View')}</Link>, <Link to={`/monitoring/${service.id}/edit`}>{t('Edit')}</Link>
                    // </Fragment>
                  ]
                })} />

                <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring/add`} onClick={e => Mixpanel.track("Clicked add new monitoring", {"var1": "hip-hop", "var2": 42})} className="lvl-button lvl-button--large">{t('Create new monitor')}</Link>
              </Fragment>
          }

          {!loading && services.length === 0 &&
            <NoServices
              productName={t('monitoring')}
              ctaOnClick={e => history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring/add`)}
              />
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesMonitoring
