import React, { useState } from 'react'

const DropdownNav = ({items}) => {
  /*
  items - array of the menu items
  */
  const [ open, setOpen ] = useState(false)
  const [ selected, setSelected ] = useState(false)

  return (
    <div className="lvl-dropdown-container">
      {items.map((item, key) =>
          item.default &&
          <div key={item.id} className='is-displayed lvl-dropdown-container__label' onClick={ e => setOpen(!open) }>{item.item}</div>
      )
      }
      <ul className={`lvl-dropdown ${open && 'is-open'}`}>
        {items.map((item, key) =>
            <li key={item.id} className={item.default && 'is-selected'} onClick={ e => setSelected(!selected) }>{item.item}</li>
        )
        }
      </ul>
    </div>

  )
}

export {
  DropdownNav
}



