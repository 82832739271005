import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import validateUUID from 'uuid-validate'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import { prettyPrice } from '../../utils/pretty-prices'

import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

const BillingAccountsDiscounts = () => {
  const [ loading, setLoading ] = useState(true)
  const [ oneTimeCredits, setOneTimeCredits ] = useState([])
  const [ percentages, setPercentages ] = useState([])
  const { t } = useTranslation()

  const { billingAccountId } = useParams()

  const { getBillingAccountDiscountsOneTimeCredits, getBillingAccountDiscountsPercentages } =  useLevelupSDK()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    setLoading(true)

    getBillingAccountDiscountsOneTimeCredits(billingAccountId)
      .then(res => {
        setOneTimeCredits(res)
        setLoading(false)
      })
      .catch(e => console.error(e))

    getBillingAccountDiscountsPercentages(billingAccountId)
      .then(res => {
        setPercentages(res)
        setLoading(false)
      })
      .catch(e => console.error(e))

  }, [billingAccountId])

  console.log('one time credits')

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Discounts')}</h1>
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">
          {oneTimeCredits.length > 0 &&
            <div>
              <h2>{t('One Time Credits')}</h2>
              <Table
                keyPrefix="pending-"
                headArray={[t('Valid from'), t('Valid to'), t('Credits'), t('Credit spent'), t('currency'), t('Limited to product'), t('Limited to service')]}
                rows={oneTimeCredits.map(c => [
                  moment(c.validFrom).format('DD.MM.YYYY'),
                  moment(c.validTo).format('DD.MM.YYYY'),
                  prettyPrice(c.oneTimeCredits, 'NOK'),
                  prettyPrice(c.creditsSpent, 'NOK'),
                  c.currency,
                  c.productId || t('None'),
                  c.serviceId || t('None')
                ])}
                />
            </div>
          }

          {percentages.length > 0 &&
            <div>
              <h2>{t('Percentage Discounts')}</h2>
              <Table
                keyPrefix="pending-"
                headArray={[t('Valid from'), t('Valid to'), t('Discount'), t('Limited to product'), t('Limited to service')]}
                rows={percentages.map(c => [
                  moment(c.validFrom).format('DD.MM.YYYY'),
                  moment(c.validTo).format('DD.MM.YYYY'),
                  c.discountPercentage + '%',
                  c.productId || t('No product restriction'),
                  c.serviceId || t('No service restriction')
                ])}
                />
            </div>
          }

          {oneTimeCredits.length === 0 && percentages.length === 0 && t('No discounts')}
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsDiscounts
