import React, { Fragment, useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

const BillingAccountWorkspacesDetail = () => {
  const [ workspace, setWorkspace ] = useState()
  const { billingAccountId, workspaceId } = useParams()

  const { t } = useTranslation()
  const { getBillingAccountWorkspace } =  useLevelupSDK()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return

    if (!workspaceId || !validateUUID(workspaceId)) return

    getBillingAccountWorkspace(billingAccountId, workspaceId)
      .then(res => {
        if (res && res.id) {
          setWorkspace(res)
        }
      })
      .catch(e => console.error(e))

  }, [billingAccountId, workspaceId])



  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{workspace && workspace.name}' {t('Dashboard')}</h1>
        </div>

        <div className="lvl-page__body">
          <div>
            <h2>{t('Service overview')}</h2>

            <div style={{background: 'whitesmoke', height: 400}}>
              Think more about this screen...
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountWorkspacesDetail
