import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import _ from 'lodash'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useModal } from '../../modal'

import Table from '../../components/table'
import NoServices from '../../components/misc-ui-components/no-services'

import cfLogo from '../../assets/cf-web-badges-f-1.webp'
import forwardMxLogo from '../../assets/forwardmx-logo.png'
import { LevelupBadge } from '../../components/misc-ui-components/levelup-logo'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

const Domains = () => {
  const [ products, setProducts ] = useState([])
  const [ services, setServices ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const history = useHistory()
  const { t } = useTranslation()
  const { billingAccountId, workspaceId } = useParams()
  const { getProductsGroupsProducts, getBillingAccountWorkspaceServices } =  useLevelupSDK()
  const { modal, setModal } = useModal()


  // HARD CODED STRING!
  const DOMAINS_PRODUCTS_GROUPS_ID = '2c4f9139-642c-4554-a635-0aade7df1233'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(DOMAINS_PRODUCTS_GROUPS_ID)
      .then(products => {
        setProducts(products)
        Promise.all(products.filter(p => p.id !== '97f0be18-456e-4422-ae81-d868a16311d9').map(product => getBillingAccountWorkspaceServices(billingAccountId, workspaceId, `?productId=${product.id}`)))
          .then(res => {
            const combinedServices = Array.prototype.concat.apply([], res)
            setServices(combinedServices)
            setLoading(false)
          }).catch(e => console.error(e))
      })
      .catch(error => console.error(error))

  }, [billingAccountId, workspaceId, getBillingAccountWorkspaceServices, getProductsGroupsProducts])


  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Domains')}</h1>
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">
          {!loading && services.length > 0 &&
            <Table
              headArray={[
                t('Name'),
                t(''),
                t('Product'),
              ]}
              rows={_.orderBy(services, [service => service.name.toLowerCase()], ['asc']).map(service => {
                const product = products.find(p => p.id === service.productId)
                let productName = 'Laster...'
                if (product.name) {
                  productName = product.name
                }
                let productSupplier = ''
                if (product.name && product.name === 'Zone File') {
                  productSupplier = <img style={{display: 'inline'}} src={cfLogo} />
                }
                if (product.name && product.name === 'Domain Administration') {
                  productSupplier = <LevelupBadge />
                }
                if (product.name && product.name === 'MX Manipulation') {
                  productSupplier = <img style={{display: 'inline', width: '80px'}} src={forwardMxLogo} />
                }
                return [
                  <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/domains/${service.id}`}>{service.name}</Link>,
                  productSupplier,
                  t(productName),
                ]
              })} />
          }

          {!loading && services.length === 0 &&
            <NoServices
              productName={t('domains')}
              />
          }
        </div>
      </div>
    </Fragment>
  )
}

export default Domains
