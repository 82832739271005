import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import _ from 'lodash'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import NoServices from '../../components/misc-ui-components/no-services'

const BillingAccountsWorkspacesGoogleMaps = () => {
  const [ products, setProducts ] = useState([])
  const [ services, setServices ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const history = useHistory()
  const { t } = useTranslation()
  const { billingAccountId, workspaceId } = useParams()
  const { getProductsGroupsProducts, getBillingAccountWorkspaceServices } =  useLevelupSDK()

  // HARD CODED STRING!
  const GOOGLE_MAPS_PRODUCTS_GROUPS_ID = 'fc440e51-455b-4b49-b8dd-e303cf2cb917'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(GOOGLE_MAPS_PRODUCTS_GROUPS_ID)
      .then(products => {
        setProducts(products)
        Promise.all(products.map(product => getBillingAccountWorkspaceServices(billingAccountId, workspaceId, `?productId=${product.id}`)))
          .then(res => {
            const combinedServices = Array.prototype.concat.apply([], res)
            setServices(combinedServices)
            setLoading(false)
          }).catch(e => console.error(e))
      })
      .catch(error => console.error(error))
  }, [billingAccountId, workspaceId, getProductsGroupsProducts, getBillingAccountWorkspaceServices])

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Google Maps')}</h1>
        </div>

        <div className="lvl-page__body">
          {loading && t('Loading...')}

          {!loading && services.length > 0 &&
            <Table
              headArray={[
                t('Name'),
                t('Product'),
                // t('Actions')
              ]}
              rows={_.orderBy(services, [service => service.name.toLowerCase()], ['asc']).map(service => {
                const product = products.find(p => p.id === service.productId)
                return [
                  <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/google-maps/${service.id}`}>{service.name}</Link>,
                  product.name || 'Laster...',
                  // t('Edit')
                ]
              })} />
          }

          <div className="lvl-callout lvl-callout--info -emphasized-header">
            <h2>Deprecated product</h2>
            <div>
              <p>Dear customer,</p>
              <p>Google Maps is a depracated product. It means that we dont have any plans to improve it. We will continue to serve and do basic maintenance for your comfort. </p>
              <p>You will be noticed when the date for discontinuation is set.</p>
            </div>
          </div>

        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesGoogleMaps
