import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import cfLogo from '../../assets/cf-logo-v-rgb.png'

import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'
import ContactUs from '../../components/misc-ui-components/contact-us'
import ProviderExcerpt from '../../components/misc-ui-components/provider-excerpt'

import DNSRecordRow from './dns-record-row'

const RECORD_TYPES = ['A', 'AAAA', 'CNAME', 'TXT', 'SRV', 'LOC', 'MX', 'NS', 'SPF', 'CERT', 'DNSKEY', 'DS', 'NAPTR', 'SMIMEA', 'SSHFP', 'TLSA', 'URI']

const BillingAccountsWorkspacesZoneFileDetails = () => {
  const [ service, setService ] = useState({})
  const [ cloudflareErrors, setCloudflareErrors] = useState([])

  const [ zoneDetails, setZoneDetails ] = useState(null)
  const [ zoneDNSRecords, setZoneDNSRecords] = useState(null)

  const { t } = useTranslation()
  const {
    getBillingAccountWorkspaceService,
    getBillingAccountWorkspaceZoneFile,
    getBillingAccountWorkspaceZoneFileDNSRecords,
    postBillingAccountWorkspaceZoneFileDNSRecord,
    postBillingAccountWorkspaceZoneFilePurgeCache
  } =  useLevelupSDK()


  const { billingAccountId, workspaceId, serviceId } = useParams()

  const { setNotification } = useNotifications()


  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
      .then(service => setService(service))
      .catch(e => console.error(e))

  }, [billingAccountId, workspaceId, serviceId, getBillingAccountWorkspaceService])


  useEffect(() => {
    if (!billingAccountId) return
    if (!workspaceId) return
    if (!serviceId) return

    // get extra information about the zone file
    // get zone details
    getBillingAccountWorkspaceZoneFile(billingAccountId, workspaceId, serviceId)
      .then(res => {
        setZoneDetails(res)
      })
      .catch(e => {
        console.error(e)
      })

    getBillingAccountWorkspaceZoneFileDNSRecords(billingAccountId, workspaceId, serviceId, 'per_page=50')
      .then(res => {
        setZoneDNSRecords(res)
      })
      .catch(e => {
        console.error(e)
      })

  }, [service])

  const reloadDNSRecords = () => {
    getBillingAccountWorkspaceZoneFileDNSRecords(billingAccountId, workspaceId, serviceId, `page=${zoneDNSRecords.result_info.page}&per_page=50`)
      .then(res => {
        setZoneDNSRecords(res)
      })
      .catch(e => {
        console.error(e)
      })
  }

  const nextDNSRecords = () => {
    getBillingAccountWorkspaceZoneFileDNSRecords(billingAccountId, workspaceId, serviceId, `page=${zoneDNSRecords.result_info.page + 1}&per_page=50`)
      .then(res => {
        setZoneDNSRecords(res)
      })
      .catch(e => {
        console.error(e)
      })
  }

  const previousDNSRecords = () => {
    getBillingAccountWorkspaceZoneFileDNSRecords(billingAccountId, workspaceId, serviceId, `page=${zoneDNSRecords.result_info.page - 1}&per_page=50`)
      .then(res => {
        setZoneDNSRecords(res)
      })
      .catch(e => {
        console.error(e)
      })
  }

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
            header={t('Zone File')}
            backLink={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/zone-files`}
          />
        </div>

        <div className="lvl-page__header">
          <h1>{service.name}</h1>
          <ProviderExcerpt logoHtml={<img src={cfLogo} />} />
        </div>

        <div className="lvl-page__body">
          {zoneDetails &&
            <Fragment>
              <div>
                <table className="lvl-table-definition-list">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th>{t('Status')}</th>
                      <td>{zoneDetails.status}</td>
                    </tr>
                    <tr>
                      <th>{t('Paused')}</th>
                      <td>{zoneDetails.paused ? t('Yes') : t('No')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <h3>{t('Cache')}</h3>
                <button onClick={e => {
                  postBillingAccountWorkspaceZoneFilePurgeCache(billingAccountId, workspaceId, serviceId, {purge_everything: true})
                    .then(res => {
                      setNotification({text: t('Cache purged 🎉'), status: 'success'})
                    })
                    .catch(e => {
                      setNotification({text: t('Failed to purge cache'), status: 'error'})
                    })
                }}>{t('Purge cache')}</button>
              </div>

            </Fragment>
          }

          {zoneDetails && zoneDetails['name_servers'] &&
            <div>
              <h3>{t('Name Servers')}</h3>
              <table className="lvl-table-definition-list">
                <thead></thead>
                <tbody>
                  {zoneDetails['name_servers'].map(val => (
                    <tr>
                      <td>{val}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }

          {zoneDetails &&
            <Formik
              initialValues={{
                type: RECORD_TYPES[0],
                name: '',
                content: '',
                ttl: 1,
                priority: 0,
                proxied: true
              }}
              validationSchema={yup.object({
                name: yup.string().min(1, t('Minimum 1 character')).max(255, t('Minimum 255 characters')).required(t('Required')),
                content: yup.string().required(t('Required')),
                ttl: yup.number().required(t('Required')),
                priority: yup.number().min(0, t('Minimum 0')).max(65535, t('Maximum 65535')),
                proxied: yup.boolean()
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)

                postBillingAccountWorkspaceZoneFileDNSRecord(billingAccountId, workspaceId, serviceId, values)
                  .then(result => {
                    if (result && !result.success) {
                      setNotification({text: t('Failed'), status: 'error'})
                      setCloudflareErrors(result.errors)
                      setSubmitting(false)
                    } else {
                      setSubmitting(false)
                      setCloudflareErrors([])
                      reloadDNSRecords()
                      setNotification({text: t('Updated 🎉'), status: 'success'})
                    }
                  })
                  .catch(e => {
                    setNotification({text: t('Failed'), status: 'error'})
                    setCloudflareErrors([])
                    setSubmitting(false)
                  })
              }}
              >
              {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
                <Form>

                  <h3>{t('Add new record')}</h3>

                  <div className="lvl-input lvl-input--select">
                    <label htmlFor="type">{t('Type')}</label>
                    <Field as="select" id="type" name="type">
                      {RECORD_TYPES.map(r => <option value={r} key={r}>{r}</option>)}
                    </Field>
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="name">{t('Name')}</label>
                    <Field type="text" id="name" name="name" />
                    <ErrorMessage name="name" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="content">{t('Content')}</label>
                    <Field type="text" id="content" name="content" />
                    <ErrorMessage name="content" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="ttl">{t('TTL')}</label>
                    <Field type="number" id="ttl" name="ttl" />
                    <ErrorMessage name="ttl" component="div" className="validation-message" />
                  </div>

                  {(values.type === 'MX' || values.type === 'SRV') &&
                    <div className="lvl-input">
                      <label htmlFor="priority">{t('Priority')}</label>
                      <Field type="number" id="priority" name="priority" />
                      <ErrorMessage name="priority" component="div" className="validation-message" />
                    </div>
                  }

                  <label className="lvl-input-checkbox">
                    {t('Cloudflare Proxy')}
                    <input type="checkbox"
                           checked={values.proxied}
                           id="proxied"
                           onChange={handleChange}
                           onBlur={handleBlur} />
                    <span className="checkmark"></span>
                  </label>

                  {cloudflareErrors.length > 0 &&
                    <div style={{background: '#E25F4A', padding: 20}}>
                      {cloudflareErrors.map(cfe => (
                        <div>
                          <div className="validation-message">{cfe.message}</div>
                          {cfe.error_chain && cfe.error_chain.map(chain => <div className="validation-message">- {chain.message}</div>)}
                        </div>
                      ))}
                    </div>
                  }

                  <div className="lvl-button-group">
                    <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Create')}</button>
                  </div>
                </Form>
              )}
            </Formik>
          }

          {zoneDNSRecords && zoneDNSRecords.result &&
            <div>
              <h3>{t('DNS Records')}</h3>
              <table className="lvl-table-definition-list">
                <thead>
                  <tr>
                    <th>{t('Type')}</th>
                    <th>{t('Name')}</th>
                    <th>{t('Content')}</th>
                    <th>{t('TTL')}</th>
                    <th>{t('Proxy')}</th>
                    <th>{t('Actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {zoneDNSRecords.result.map(record =>
                    <DNSRecordRow record={record}
                                  billingAccountId={billingAccountId}
                                  workspaceId={workspaceId}
                                  serviceId={serviceId}
                                  reloadDNSRecords={reloadDNSRecords} />
                  )}
                </tbody>
              </table><i class="fas fa-clouds"></i>

              <div>
                <p>Showing {zoneDNSRecords.result_info.count} of {zoneDNSRecords.result_info.total_count} records</p>
                <p>On page {zoneDNSRecords.result_info.page} of {zoneDNSRecords.result_info.total_pages}</p>
                <button disabled={zoneDNSRecords.result_info.page === 1} onClick={e => previousDNSRecords()}>Previous</button>
                <button disabled={zoneDNSRecords.result_info.page >= zoneDNSRecords.result_info.total_pages} onClick={e => nextDNSRecords()}>Next</button>
              </div>
            </div>
          }

          <div>
            <ContactUs preText={t('Do you want any of the values above changed or deleted?')} />
          </div>
        </div>


      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesZoneFileDetails
