import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { useTranslation } from 'react-i18next'

const BillingReportTimePicker = ({timespan, setTimespan, setQueryString}) => {

  const { t } = useTranslation()

  useEffect(() => {
    let queryString = '?'

    if (timespan === 'current-month') {
      // from the start to the end of this month
      const start = moment().startOf('month').startOf('day').startOf('hour').startOf('minutes').toISOString()
      const end = moment().endOf('month').endOf('day').endOf('hour').endOf('minutes').toISOString()
      queryString += `start=${start}&end=${end}`
    }
    else if (timespan === 'last-month') {
      // go back to last month
      const lastMonth = moment().subtract(1, 'month')

      const start = lastMonth.startOf('month').startOf('day').startOf('hour').startOf('minutes').toISOString()
      const end = lastMonth.endOf('month').endOf('day').endOf('hour').endOf('minutes').toISOString()
      queryString += `start=${start}&end=${end}`
    }
    else if (timespan === 'last-30-days') {
      const start = moment().subtract(31, 'days').toISOString()
      const end = moment().subtract(1, 'days').toISOString()
      queryString += `start=${start}&end=${end}`
    }
    else if (timespan === 'last-90-days') {
      const start = moment().subtract(91, 'days').toISOString()
      const end = moment().subtract(1, 'days').toISOString()
      queryString += `start=${start}&end=${end}`
    }

    setQueryString(queryString)

  }, [timespan])

  return (
    <div>
      <ul className="lvl-selectable-list lvl-block">
        <li onClick={e => setTimespan('current-month')} className={ timespan === 'current-month' ? `-selected` : ''}>{t('Current month')}</li>
        <li onClick={e => setTimespan('last-month')} className={ timespan === 'last-month' ? `-selected` : ''}>{t('Last month')}</li>
        <li onClick={e => setTimespan('last-30-days')} className={ timespan === 'last-30-days' ? `-selected` : ''}>{t('Last 30 days')}</li>
        <li onClick={e => setTimespan('last-90-days')} className={ timespan === 'last-90-days' ? `-selected`: ''}>{t('Last 90 days')}</li>
      </ul>
    </div>
  )
}

export default BillingReportTimePicker
