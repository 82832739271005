import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import validateUUID from 'uuid-validate'

import { useNotifications } from '../../notifications'

import { useLevelupSDK } from '../../levelup-sdk'

const BillingAccountWorkspacesAdd = () => {
  const { postBillingAccountWorkspace } =  useLevelupSDK()
  const { setNotification } = useNotifications()
  const { t } = useTranslation()
  const history = useHistory()

  const { billingAccountId } = useParams()


  return (
    <Fragment>
      <div className="lvl-page">

        <div className="lvl-page__header">
          <h1>{t('Add workspace')}</h1>
        </div>

        <div className="lvl-page__body">
          <Formik
            initialValues={{
              name: '',
            }}
            validationSchema={yup.object({
              name: yup.string().min(2, t('Minimum 2 characters')).max(20, t('Maximum 20 characters')).required(t('Required')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)

              if (!billingAccountId || !validateUUID(billingAccountId)) {
                setNotification({text: t('Failed'), status: 'error'})
                history.push(`/`)
                return
              }

              postBillingAccountWorkspace(billingAccountId, values)
                .then(workspace => {
                  history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspace.id}/shared-hosting`)
                  setNotification({text: t('Created 🎉'), status: 'success'})
                  setSubmitting(false)
                })
                .catch(e => {
                  setNotification({text: t('Failed'), status: 'error'})
                  setSubmitting(false)
                })
            }}
            >
            {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
              <Form>

                <div className="lvl-input">
                  <label htmlFor="name">{t('Name')}</label>
                  <Field type="text" id="name" name="name" data-hj-allow />
                  <ErrorMessage name="name" component="div" className="validation-message" />
                </div>

                <div className="lvl-button-group">
                  <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Save')}</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountWorkspacesAdd
