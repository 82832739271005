import React, { Fragment, useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"
import iso3311a2 from 'iso-3166-1-alpha-2'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import './index.css'


const PaymentMethodsEdit = () => {
  const [ loading, setLoading ] = useState(true)
  const [ paymentMethod, setPaymentMethod ] = useState(null)

  const { getBillingAccountPaymentMethod, patchBillingAccountPaymentMethod } = useLevelupSDK()

  const { setNotification } = useNotifications()
  const { billingAccountId, paymentMethodId } = useParams()
  const history = useHistory()
  const { t } = useTranslation()


  // TEST CARD
  // 4000005780000007

  useEffect(() => {
    setLoading(true)

    getBillingAccountPaymentMethod(billingAccountId, paymentMethodId).then(res => {
      // We should get with the stripe data!
      if (!res.stripe) {
        setNotification({text: t('Failed, please try again later'), status: 'error'})
        history.push(`/billing-accounts/${billingAccountId}/payment-methods`)
      }

      const data = {}

      const billingDetails = res.stripe.billing_details

      if (billingDetails) {
        data.name = billingDetails.name || ''
        data.email = billingDetails.email || ''
        data.phone = billingDetails.phone || ''

        if (billingDetails.address) {
          data.city = billingDetails.address.city || ''
          data.country = billingDetails.address.country || iso3311a2.getCodes()[0]
          data.line1 = billingDetails.address.line1 || ''
          data.line2 = billingDetails.address.line2 || ''
          data.postal_code = billingDetails.address.postal_code || ''
          data.state = billingDetails.address.state || ''
        }
      }

      setLoading(false)
      setPaymentMethod(data)
    })
  }, [])

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Edit Payment Method')}</h1>
        </div>
        {loading && <div className="lvl-page"><div className="lvl-page__body"><div>Loading...</div></div></div>}
        <div className="lvl-page__body">
          {paymentMethod &&
            <Formik
              initialValues={{
                name: paymentMethod.name,
                city: paymentMethod.city,
                country: paymentMethod.country,
                line1: paymentMethod.line1,
                line2: paymentMethod.line2,
                postal_code: paymentMethod.postal_code,
                state: paymentMethod.state,
                email: paymentMethod.email,
                phone: paymentMethod.phone,
              }}
              validationSchema={yup.object({
                name: yup.string().min(5, t('Write full name, and should be at least 5 characters')).required(t('Required')),
                email: yup.string().email('Email must be valid').required(t('Required')),
                phone: yup.string().min(2, t('Missing')).required(t('Required')),
                country: yup.string().required(t('Required')),
                line1: yup.string().min(2, t('Missing')).required(t('Required')),
                postal_code: yup.string().min(2, t('Missing')).required(t('Required')),
                city: yup.string().min(2, t('Missing')).required(t('Required')),
                state: yup.string().min(2, t('Missing')).required(t('Required'))
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                patchBillingAccountPaymentMethod(billingAccountId, paymentMethodId, values)
                  .then(res => {
                    setNotification({text: t('Updated 🎉'), status: 'success'})
                    setSubmitting(true)
                    history.goBack()
                  })
                  .catch(e => {
                    setNotification({text: t('Failed'), status: 'error'})
                    setSubmitting(true)
                  })
              }}
              >
              {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
                <Form>

                  <div className="lvl-input">
                    <label htmlFor="name">{t('Name')}</label>
                    <Field type="text" id="name" name="name" />
                    <ErrorMessage name="name" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="email">{t('Email')}</label>
                    <Field type="email" id="email" name="email" />
                    <ErrorMessage name="email" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="phone">{t('Phone')}</label>
                    <PhoneInput
                      country={'no'}
                      containerClass="lvl-input"
                      inputClass="lvl-input"
                      value={values.phone}
                      onChange={phone => setFieldValue('phone', phone)}
                    />
                  </div>

                  <div className="lvl-input lvl-input--select">
                    <label htmlFor="country">{t('Country')}</label>
                    <Field as="select" id="country" name="country">
                      {iso3311a2.getCodes().map(c => <option key={c} value={c}>{iso3311a2.getCountry(c)}</option>)}
                    </Field>
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="line1">{t('Address, line 1')}</label>
                    <Field type="text" id="line1" name="line1" />
                    <ErrorMessage name="line1" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="line2">{t('Address, line 2')}</label>
                    <Field type="text" id="line2" name="line2" />
                    <ErrorMessage name="line2" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="postal_code">{t('Postal code')}</label>
                    <Field type="text" id="postal_code" name="postal_code" />
                    <ErrorMessage name="postal_code" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="city">{t('City')}</label>
                    <Field type="text" id="city" name="city" />
                    <ErrorMessage name="city" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="state">{t('State')}</label>
                    <Field type="text" id="state" name="state" />
                    <ErrorMessage name="state" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-button-group">
                    <button type="button" onClick={e => history.goBack()}>{t('Cancel')}</button>
                    <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Update')}</button>
                  </div>
                </Form>
              )}
            </Formik>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default PaymentMethodsEdit
