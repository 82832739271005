import React from 'react'
import { useTranslation } from 'react-i18next'


const Details = ({service, currentProduct}) => {
  const { t } = useTranslation()

  return (service.name && currentProduct) ? (
    <div>
      <table className="lvl-table-definition-list">
        <thead></thead>
        <tbody>
          <tr>
            <th>{t('From field')}</th>
            <td>{service.details.from}</td>
          </tr>
          <tr>
            <th>{t('Current plan')}</th>
            <td>{currentProduct.name}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : <div />
}

export default Details
