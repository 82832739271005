module.exports = (function(){
  const local = {
   apiUrl: 'http://localhost:7070/api/v1',
   stripePublishableKey: 'pk_test_AEoDCIUfMsdp6kMIfSzp8GfM00L3282b42',
  }

  const remote = {
    prod: {
      apiUrl: 'https://api.levelup.no/api/v1',
      stripePublishableKey: 'pk_live_OZ0KnAcPfJFo91rJC9dDaPkB005SJwsKLg',
    },
    dev: {
      apiUrl: 'https://api.levelup.no/api/v1',
    }
  }

  const isLocal = () => {
    return /^localhost/.test(window.location.hostname);
  }

  const getProperty = (name) => {
    if (process.env.REACT_APP_BARDIA === 'true') {
      return remote['prod'][name]
    }

    var data = isLocal() ? local : remote;
    if (!isLocal()) {
      if (window.location.hostname.indexOf('dev.') > -1) {
        return data['dev'][name]
      } else {
        return data['prod'][name]
      }
    }
    return data[name];
  };

  return {
    isLocal: isLocal(),
    apiUrl: getProperty('apiUrl'),
    stripePublishableKey: getProperty('stripePublishableKey'),
  }
})()
