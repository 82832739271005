import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useParams } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { useLevelupSDK } from '../../../levelup-sdk'

const WorkspacePicker = ({workspace, billingAccountWorkspaces}) => {
  const [ workspacesDropdownOpen, setWorkspacesDropdownOpen] = useState(false)
  const [ filter, setFilter ] = useState('')

  const history = useHistory()

  const {
    getBillingAccounts,
  } =  useLevelupSDK()

  const { billingAccountId, workspaceId } = useParams()

  const { t } = useTranslation()

  useEffect(() => {

  }, [])

  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
          setWorkspacesDropdownOpen(false)
      }
  }

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true)
      return () => {
          document.removeEventListener('click', handleClickOutside, true)
      }
  })

  const goToAndClose = (e, path) => {
    e.preventDefault()
    setWorkspacesDropdownOpen(false)
    history.push(path)
  }

  return (
    <div className="client-picker" ref={ref}>
      <div className="client-picker__field" onClick={e => setWorkspacesDropdownOpen(!workspacesDropdownOpen)}>
        <span className="lvl-label">{t('Workspace')}:<br /></span>
        {workspace ?
            <span>{workspace.name} {workspace.submittedForDeletion && <span className="h-text-warning">({t('Deletion requested')})</span>}</span>
          :
            <span>{t('Select workspace')}</span>
        }
      </div>

      {workspacesDropdownOpen &&
        <div class="dropdown-menu dropdown-menu--left-aligned">
          <ul>
            {/*workspace && <li style={{borderBottom: '1px solid black'}}><Link to={`/billing-accounts/${billingAccount}`}>Workspace home</a></li>*/}
            <span className="dropdown-menu__label lvl-label">
              {workspace ? t('Switch workspace') : t('Select workspace') }
            </span>
            <li>
              <div className="dropdown-menu__search">
                <input autoFocus
                       type="text"
                       placeholder={t('Search workspaces')}
                       style={{width: '100%', flex: 1, color: 'black'}}
                       value={filter}
                       className="lvl-input-inline"
                       onChange={e => setFilter(e.target.value)} />
              </div>
            </li>
            {_.orderBy(billingAccountWorkspaces, [workspace => workspace.name.toLowerCase()], ['asc'])
              .filter(w => filter.length > 0 ? w.name.toLowerCase().includes(filter.toLowerCase()) : true)
              .slice(0, 3)
              .map(w => <li><a onClick={e => goToAndClose(e, `/billing-accounts/${billingAccountId}/workspaces/${w.id}/shared-hosting`)}>{w.name}</a></li>)
            }
            <li><hr /><Link to={`/billing-accounts/${billingAccountId}/workspaces`}>{t('View all workspaces')}</Link></li>
          </ul>
        </div>
      }
    </div>
  )
}

export default WorkspacePicker
