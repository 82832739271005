import React, { useState } from 'react'
import {Link} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import Table from '../../components/table'

const UnPaidInvoices = ({userStatus}) => {
  const [ showAll, setShowAll ] = useState(false)
  const { t } = useTranslation()

  let invoices = []
  if (showAll) {
    invoices = userStatus.unPaidInvoices
  } else {
    invoices = userStatus.unPaidInvoices.slice(0, 3)
  }

  return (
    <div style={{
      float: 'left',
      width: 300,
      padding: 20,
      marginBottom: 20,
      marginRight: 20,
      borderRadius: 5,
      background: '#f7eefc'
    }}>
      <h3>{t('Unpaid invoices')}</h3>
      <Table
        keyPrefix="un-paid-invoices-"
        headArray={['', '']}
        rows={invoices.map(invoice => [
          invoice.billingAccount.name,
          <Link to={`/billing-accounts/${invoice.billingAccount.id}/invoices/${invoice.id}`}>{t('Show invoice')}</Link>
        ])}
        />
      {userStatus.unPaidInvoices.length > 3 &&
        <button onClick={e => setShowAll(!showAll)}>
          {showAll ? t('Show less') : t('Show all')}
        </button>
      }
    </div>
  )
}

export default UnPaidInvoices
