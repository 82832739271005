import React, { Fragment, useState, useEffect } from "react"
import {Link} from 'react-router-dom'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'
import { useAuth0 } from "../../react-auth0-spa"
import { useLevelupSDK } from '../../levelup-sdk'

import UnPaidInvoices from './un-paid-invoices'
import ErroredServices from './errored-services'
import Table from '../../components/table'

const BillingAccountsList = () => {
  const [ billingAccounts, setBillingAccounts ] = useState([])
  const [ userStatus, setUserStatus ] = useState({})
  const [ searchString, setSearchString ] = useState('')
  const { loading } = useAuth0()

  const {
    getStatus,
    currentBillingAccount,
    currentWorkspace,
    getBillingAccounts,
  } =  useLevelupSDK()

  const { t } = useTranslation()

  useEffect(() => {
    getBillingAccounts().then(billingAccounts => setBillingAccounts(billingAccounts))
    getStatus().then(res => setUserStatus(res))

  }, [currentBillingAccount, currentWorkspace, getBillingAccounts])

  return (
    <div className="lvl-page">
      {((userStatus.erroredServices && userStatus.erroredServices.length > 0) || (userStatus.unPaidInvoices && userStatus.unPaidInvoices.length > 0)) &&
        <Fragment>
          <div className="lvl-page__body">
            <div style={{display: 'block'}}>
              {userStatus.erroredServices && userStatus.erroredServices.length > 0 && <ErroredServices userStatus={userStatus} />}
              {userStatus.unPaidInvoices && userStatus.unPaidInvoices.length > 0 && <UnPaidInvoices userStatus={userStatus} />}
            </div>
          </div>
        </Fragment>
      }


      <div className="lvl-page__header">
        <h1>{t('Select Account')}</h1>
        <div>
          <Link to="/billing-accounts/add" className="lvl-button">{t('Create new Account')}</Link>
        </div>
      </div>

      {loading && <div className="lvl-page"><div className="lvl-page__body"><div>Loading...</div></div></div>}
      {!loading && billingAccounts.length > 0 &&
        <div className="lvl-page__body">
          <div className="lvl-input-inline">
            <label htmlFor="search">{t('Search')}</label>
            <input type="text" id="search" value={searchString} onChange={e => setSearchString(e.target.value)} />
          </div>

          <Table
            keyPrefix="billing-accounts-"
            headArray={[t('Name'),'']}
            rows={_.orderBy(billingAccounts, [billingAccount => billingAccount.name.toLowerCase()], ['asc'])
                    .filter(b => searchString.length > 0 ? b.name.toLowerCase().includes(searchString.toLowerCase()) : true)
                    .map(b => [
              <Link to={`/billing-accounts/${b.id}/workspaces`}>{b.name}</Link>,
              <Link to={`/billing-accounts/${b.id}/edit`}>{t('Account Settings')}</Link>
            ])} />
        </div>
      }
      {!loading && billingAccounts.length === 0 &&
        <div className="lvl-page__body">
          <div style={{background: '#f9f9f9', border: '2px solid #343e44', padding: 30}}>
            <h2>{t('Create your first account')}</h2>
            <p>{t('You have to create an account to hold your billing information before you can start creating services.')}</p>
            <Link to="/billing-accounts/add" className="lvl-button">{t('Create new Account')}</Link>
          </div>
        </div>
      }
    </div>
  );
};

export default BillingAccountsList
