import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import validateUUID from 'uuid-validate'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import RenderList from '../ba-billing-reports/render-list'
import { prettyPrice } from '../../utils/pretty-prices'

import { LevelupLogo } from '../../components/misc-ui-components/levelup-logo'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

const BillingAccountsInvoicesDetails = () => {
  const [ loading, setLoading ] = useState(true)
  const [ transaction, setTransaction ] = useState([])
  const [ paymentMethod, setPaymentMethod ] = useState()
  const [ billingReports, setBillingReports ] = useState([])
  const [ billingAccount, setBillingAccount ] = useState()
  const [ products, setProducts ] = useState([])

  const [ error, setError ] = useState()

  const { t } = useTranslation()

  const { billingAccountId, transactionId } = useParams()

  const { getProducts, getBillingAccount, getBillingAccountTransaction, getBillingAccountTransactionBillingReports, getBillingAccountTransactionPaymentMethod } =  useLevelupSDK()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!transactionId || !validateUUID(transactionId)) return

    setLoading(true)

    Promise.all([
      getProducts().then(res => setProducts(res)).catch(e => console.error(e)),
      getBillingAccount(billingAccountId).then(res => setBillingAccount(res)).catch(e => console.error(e)),
      getBillingAccountTransaction(billingAccountId, transactionId).then(res => setTransaction(res)).catch(e => console.error(e)),
      getBillingAccountTransactionBillingReports(billingAccountId, transactionId).then(r => setBillingReports(r)).catch(e => console.error(e)),
      getBillingAccountTransactionPaymentMethod(billingAccountId, transactionId).then(r => setPaymentMethod(r)).catch(e => console.error(e))
    ])
    .then(r => {
      console.log(r)
      setLoading(false)
    })
    .catch(e => setError('Something went wrong, please try again later'))

  }, [billingAccountId, transactionId])

  let statusClass = ``
  if (transaction.status == 'REQUESTED') {
    statusClass = `lvl-callout--info`
  } else if (transaction.status == 'PAYED') {
    statusClass = `lvl-callout--positive`
  }

  const transactionStatusesLabels = {
    "REQUESTED": "Requested",
    "PAYED": "Payed",
  }

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Invoice')}</h1>
          <button type="button" className="lvl-button lvl-button--large" onClick={e => window.print()}><i className="fas fa-print"></i> {t('Print')}</button>
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">
          {error && error}

          {!error && transaction && billingReports.length > 0 && billingAccount && paymentMethod && products.length > 0 &&
            <Fragment>
              <div className={`h-no-print lvl-callout ${statusClass}`}>
                { transaction.status && <div className="lvl-invoice__status"><b>{t('Status')}: </b>{t(transactionStatusesLabels[transaction.status])}</div> }
                <div><b>{t('Payment method')}: </b>{paymentMethod.type === 'INVOICE' && t('Invoice')}{paymentMethod.type === 'CREDIT-CARD' && t('Credit card')}</div>
              </div>
              <div className="lvl-invoice">

                <div className="lvl-invoice__cant-show-message">
                  <i>Please tilt your phone to view the invoice.</i>
                </div>

                <div className="lvl-invoice__header">

                  <div>
                    <div className="lvl-invoice__client-details">
                      { billingAccount.name && <div><b>{billingAccount.name}</b></div> }
                      { billingAccount.adressLine1 && <div>{billingAccount.adressLine1}</div> }
                      <div>
                        { billingAccount.postalCode && <span>{billingAccount.postalCode}</span> }
                        { billingAccount.city && <span>&nbsp;{billingAccount.city}</span> }
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="lvl-invoice__provider">
                      <LevelupLogo />
                      <div><b>Levelup AS</b></div>
                      <div>PB 45 Borgheim</div>
                      <div>{t('N-3163')} Nøtterøy</div>
                      <div>{t('Norway')}</div>
                      <br/>
                      <div>{t('Organization Number')}: NO 920 223 184 MVA</div>
                      <div>{t('Phone')}: +47 333 22 122</div>
                      <div>{t('Email')}: hei@levelup.no</div>
                    </div>
                  </div>
                </div>

                <div className="lvl-invoice__body">
                  <div className="lvl-invoice__invoice-details lvl-callout lvl-callout--print h-flex-wrap">
                    <div>
                      <div><b>{t('Transaction ID')}:</b></div>
                      <div><b>{t('Billing date')}:</b></div>
                      <div><b>{t('Due date')}:</b></div>

                      <br/>

                      {transaction.vat && <div><b>{t('Amount ex VAT')}:</b></div>}
                      {transaction.vat && <div><b>{t('VAT')}:</b></div>}
                      <div><b>{t('Amount')}:</b></div>
                    </div>

                    <div style={{marginLeft: '20px'}}>
                      <div>{transaction.id}</div>
                      <div>{moment(transaction.invoiceDate).format('DD.MM.YYYY')}</div>
                      <div>{moment(transaction.dueDate).format('DD.MM.YYYY')}</div>

                      <br/>

                      {transaction.vat && <div>{prettyPrice(transaction.amount, 'NOK')}</div>}
                      {transaction.vat && <div>{prettyPrice(transaction.vat, 'NOK')}</div>}
                      <div>{prettyPrice(transaction.amountIncVat, 'NOK')}</div>
                    </div>


                  </div>

                  <RenderList billingReports={billingReports} products={products} />
                </div>

              </div>
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsInvoicesDetails
