import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom"

import { useTranslation } from 'react-i18next'


const BillingAccountsWorkspacesAddContact = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Contact us')}</h1>
        </div>
        <div className="lvl-page__body">
          <div className="lvl-callout lvl-callout--positive">
            <h2>{t('Contact us at ks@levelup.no to set up this service for you')} <i className="far fa-smile"></i></h2>
            <p>{t('We normally respond within a workday, sometimes even faster')}.</p>
            <button type="button" onClick={e => history.goBack()}>{t('Back')}</button> p
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesAddContact
