import React, { Fragment, useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"
import validateUUID from 'uuid-validate'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import InfoCard from '../../components/misc-ui-components/info-card'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

const BillingAccountsPaymentMethods = () => {
  const [ loading, setLoading ] = useState(true)
  const [ paymentMethods, setPaymentMethods] = useState([])

  const history = useHistory()
  const { t } = useTranslation()
  const { billingAccountId } = useParams()

  const { getBillingAccountPaymentMethods, deleteBillingAccountPaymentMethod, patchBillingAccountPaymentMethod } =  useLevelupSDK()


  useEffect(() => {
    if (!billingAccountId || !validateUUID(billingAccountId)) return

    setLoading(true)

    getBillingAccountPaymentMethods(billingAccountId)
      .then(res => {
        setPaymentMethods(res)
        setLoading(false)
      })
      .catch(e => console.error(e))

  }, [billingAccountId])

  const deletePaymentMethod = paymentMethodId => {
    deleteBillingAccountPaymentMethod(billingAccountId, paymentMethodId)
      .then(res => {
        getBillingAccountPaymentMethods(billingAccountId)
          .then(res => setPaymentMethods(res))
          .catch(e => console.error(e))
      })
      .catch(e => console.error(e))
  }

  const setPrimaryPaymentMethod = paymentMethodId => {
    patchBillingAccountPaymentMethod(billingAccountId, paymentMethodId, {status: 'PRIMARY'})
      .then(res => {
        getBillingAccountPaymentMethods(billingAccountId)
          .then(res => setPaymentMethods(res))
          .catch(e => console.error(e))
      })
      .catch(e => console.error(e))
  }

  const paymentMethodsOrder = {
    "PRIMARY": 1,
    "BACKUP": 2,
    "INACTIVE": 3
  }

  const paymentMethodsOrderLabels = {
    "PRIMARY": 'Primary',
    "BACKUP": 'Backup',
    "INACTIVE": 'Inactive'
  }

  const paymentMethodsTypeLabels = {
    "INVOICE": 'Invoice',
    "CREDIT-CARD": 'Credit Card',
  }

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Payment methods')}</h1>
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">

          <div className="h-flex-wrap h-column-layout-inside">
            {_.sortBy(paymentMethods, p => paymentMethodsOrder[p.status]).map(pm => (
              <InfoCard
                body={
                  <div className="lvl-payment-method">
                    <div className="lvl-payment-method__header">
                      <div className="lvl-payment-method__icon">
                        {pm.type === 'CREDIT-CARD' && pm.stripe && pm.stripe.card && pm.stripe.card.brand == 'visa' && <i className="fab fa-cc-visa"></i>}
                        {pm.type === 'CREDIT-CARD' && pm.stripe && pm.stripe.card && pm.stripe.card.brand == 'mastercard' && <i className="fab fa-cc-mastercard"></i>}
                        {pm.type === 'INVOICE' && <i className="fas fa-file-invoice"></i>}
                      </div>
                    </div>
                    <div className="lvl-payment-method__body">
                      {pm.type === 'CREDIT-CARD' && <h2 className="h-uppercase">{pm.stripe && pm.stripe.card && <Fragment>{pm.stripe.card.brand} **** {pm.stripe.card.last4}</Fragment>}</h2>}
                      {pm.type === 'INVOICE' && <h2>{t(paymentMethodsTypeLabels[pm.type])}{pm.customInvoiceString && - pm.customInvoiceString}</h2>}
                      {pm.type === 'CREDIT-CARD' && pm.stripe && pm.stripe.card && <p>{t('Expires')}: {pm.stripe.card.exp_month}/{pm.stripe.card.exp_year}</p>}
                    </div>
                  </div>
                }
                footer={
                  <Fragment>
                    <div className="lvl-text-style-2">{t(paymentMethodsOrderLabels[pm.status])}</div>
                    {pm.status === 'BACKUP' && <button type="button" className="lvl-button lvl-button--hollow" onClick={e => setPrimaryPaymentMethod(pm.id)}><i className="fa fa-question"></i>{t('Set as primary')}</button>}
                    {pm.status !== 'INACTIVE' && pm.type === 'CREDIT-CARD' && <button type="button" className="lvl-button lvl-button--hollow" onClick={e => history.push(`/billing-accounts/${billingAccountId}/payment-methods/${pm.id}/edit`)}><i className="fa fa-pencil"></i>{t('Edit')}</button>}
                    {pm.status !== 'PRIMARY' && <button type="button" className="lvl-button lvl-button--hollow" onClick={e => deletePaymentMethod(pm.id)}><i className="fa fa-remove"></i>{t('Remove')}</button>}
                  </Fragment>
                }
                 />
            ))}
            <InfoCard heading={t('Add new payment method')}
                      body={null}
                      ctaOnClick={e => history.push(`/billing-accounts/${billingAccountId}/payment-methods/add`)}
                      ctaText={t('Add payment method')}
                      addingCard={true} />
          </div>

        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsPaymentMethods
