import React, { Fragment, useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom"
import validateUUID from 'uuid-validate'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import { prettyPrice } from '../../utils/pretty-prices'

import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

const BillingAccountsBilling = () => {
  const [ loading, setLoading ] = useState(true)
  const [ transactions, setTransactions ] = useState([])
  const { t } = useTranslation()

  const { billingAccountId } = useParams()

  const { getBillingAccountTransactions } =  useLevelupSDK()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
      setLoading(true)

    getBillingAccountTransactions(billingAccountId)
      .then(res => {
        setTransactions(res)
        setLoading(false)
      })
      .catch(e => console.error(e))

  }, [billingAccountId])

  let pendingTransactions = [], payedTransactions = []
  if (transactions && transactions.length > 0) {
    pendingTransactions = transactions.filter(t => t.status === 'REQUESTED' && t.type === 'INCOMING') || []
    payedTransactions = transactions.filter(t => t.status === 'PAYED' && t.type === 'INCOMING') || []
  }

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Invoices')}</h1>
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">
          {pendingTransactions.length > 0 &&
            <div>
              <h2>{t('Pending invoices')}</h2>
              <Table
                keyPrefix="pending-"
                headArray={[t('Date'), t('Due date'), t('Amount paid'), t('Amount pending'), t('Currency'), '']}
                rows={pendingTransactions.map(transaction => [
                  moment(transaction.invoiceDate).format('DD.MM.YYYY'),
                  moment(transaction.dueDate).format('DD.MM.YYYY'),
                  prettyPrice(transaction.amountPayed, 'NOK'),
                  prettyPrice(transaction.amountPending, 'NOK'),
                  transaction.currency,
                  <Link to={`/billing-accounts/${billingAccountId}/invoices/${transaction.id}`}>{t('Show invoice')}</Link>
                ])}
                />
            </div>
          }
          {payedTransactions.length > 0 &&
            <div>
              <h2>{t('Paid invoices')}</h2>
              <Table
                keyPrefix="pending-"
                headArray={[t('Date'), t('Due date'), t('Amount paid'), t('Amount pending'), t('Currency'), '']}
                rows={payedTransactions.map(transaction => [
                  moment(transaction.invoiceDate).format('DD.MM.YYYY'),
                  moment(transaction.dueDate).format('DD.MM.YYYY'),
                  prettyPrice(transaction.amountPayed, 'NOK'),
                  prettyPrice(transaction.amountPending, 'NOK'),
                  transaction.currency,
                  <Link to={`/billing-accounts/${billingAccountId}/invoices/${transaction.id}`}>{t('Show invoice')}</Link>
                ])}
                />
            </div>
          }

          {transactions.length === 0 &&
            <div className="lvl-callout">
              {t('No invoices, yet')}&nbsp;👌
            </div>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsBilling
