import React, { useState, useEffect, Fragment } from 'react'
import { useParams } from "react-router-dom"
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'

const Logs = ({ setLoading, service }) => {
  const [ events, setEvents ] = useState([])
  const [ count, setCount ] = useState()
  const [ limit, setLimit ] = useState(50)
  const [ offset, setOffset ] = useState(0)

  const { t } = useTranslation()
  const { billingAccountId, workspaceId, serviceId } = useParams()
  const { getBillingAccountWorkspaceSystemSMSEvents } =  useLevelupSDK()

  const getEvents = () => {
    setEvents([])
    setLoading(true)
    getBillingAccountWorkspaceSystemSMSEvents(billingAccountId, workspaceId, serviceId, `?limit=${limit}&offset=${offset}`)
      .then(res => {
        setEvents(res.rows)
        setCount(res.count)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        console.error(e)
      })
  }

  useEffect(() => {
    getEvents()
  }, [limit, offset])

  useEffect(() => {
    getEvents()
  }, [])

  const tableRows = []

  if (events && events.length > 0 && Array.isArray(events)) {
    events.forEach(event => {
      if (event && event.data && event.data.messages && Array.isArray(event.data.messages)) {
        event.data.messages.forEach(message => {
          const row = []

          let recipient = ''
          if (message.to) {
            recipient = message.to
          }
          if (event.to) {
            recipient = event.to
          }

          let statusColor = 'blue'

          if (['0','accepted', 'delivered'].some(s => message.status === s)) {
            statusColor = 'green'
          } else if (['rejected', 'failed'].some(s => message.status === s)) {
            statusColor = 'red'
          }

          tableRows.push([
            <span style={{width: 8, height: 8, borderRadius: 8, display: 'inline-block', background: statusColor}}></span>,
            moment(event.created).format('YYYY.MM.DD HH:mm'), 
            t(event.data.event),
            message.status,
            message['message-id'] || '',
            recipient,
            event.data.fullMessage || ''
          ])
        })
      }
    })
  }

  return (events && events.length > 0) ? (
    <Fragment>
      <h2>{t('Event logs')}</h2>
      <div style={{textAlign: 'right', marginBottom: 20}}>
        <button onClick={e => getEvents()}>{t('Refresh')}</button>
      </div>
      <Table
        headArray={[
          '',
          t('Timestamp'),
          t('Event'),
          t('Status'),
          t('Message ID'),
          t('Recipient'),
          t('Message')
        ]}
        rows={tableRows} />

      <div>
        <button onClick={e => setOffset((offset - limit) <= 0 ? 0 : offset - limit)} disabled={offset === 0 && 'disabled' }>{t('Previous')}</button>
        <button onClick={e => setOffset(offset + limit)} disabled={offset + limit >= count && 'disabled'}>{t('Next')}</button>
      </div>
    </Fragment>
  ) : <div />
}

export default Logs
