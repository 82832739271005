import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import validateUUID from 'uuid-validate'
import moment from 'moment'

import mailgunLogo from '../../assets/mailgun-logo.png'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import ProviderExcerpt from '../../components/misc-ui-components/provider-excerpt'
import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'
import ContactUs from '../../components/misc-ui-components/contact-us'

import DNSRecords from './dns-records'
import SMTPCredentials from './smtp-credentials'
import Logs from './logs'

const BillingAccountsWorkspacesSystemEmailsDetails = () => {
  const [ products, setProducts ] = useState([])
  const [ service, setService ] = useState({})
  const [ systemEmailDetails, setSystemEmailDetails ] = useState()
  const [ systemEmailStatsTotal, setSystemEmailStatsTotal ] = useState()

  const [ currentTab, setCurrentTab ] = useState('details')

  const { setNotification } = useNotifications()
  const { t } = useTranslation()
  const {
    getProductsGroupsProducts,
    getBillingAccountWorkspaceService,
    getBillingAccountWorkspaceSystemEmail,
    getBillingAccountWorkspaceSystemEmailStatsTotal,
    getBillingAccountWorkspaceSystemEmailEvents
  } =  useLevelupSDK()


  const { billingAccountId, workspaceId, serviceId } = useParams()

  // HARD CODED STRING!
  const DOMAIN_PRODUCT_GROUP = '3c1b6570-b113-443d-8f35-d545d078837c'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(DOMAIN_PRODUCT_GROUP)
      .then(products => setProducts(products))
      .catch(e => console.error(e))

    getBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
      .then(service => setService(service))
      .catch(e => console.error(e))

  }, [billingAccountId, workspaceId, serviceId])

  useEffect(() => {
    getBillingAccountWorkspaceSystemEmail(billingAccountId, workspaceId, serviceId)
      .then(res => setSystemEmailDetails(res))
      .catch(e => console.error(e))

    getBillingAccountWorkspaceSystemEmailStatsTotal(billingAccountId, workspaceId, serviceId, `?event=accepted&start=${moment().subtract(24, 'h').unix()}&end=${moment().unix()}&resolution=hour`)
      .then(res => setSystemEmailStatsTotal(res))
      .catch(e => console.error(e))

  }, [service])


  // define strings
  let currentProductName = t('Could not fetch product information')
  if (products.length > 0 && service && service.productId) {
    const currentProduct = products.find(p => p.id === service.productId)
    currentProductName = currentProduct ? currentProduct.name : currentProductName
  }

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
          header={t(currentProductName)}
          backLink={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-email`}
          />
        </div>

        <div className="lvl-page__header">
          <h1>{service.name}</h1>
          <ProviderExcerpt logoHtml={<img src={mailgunLogo} />} />
        </div>

        <div className="lvl-page__body">
          <div>
            <ul className="lvl-selectable-list lvl-block">
              <li onClick={e => setCurrentTab('details')} className={currentTab == 'details' ? `-selected` : undefined}>{t('Details')}</li>
              <li onClick={e => setCurrentTab('dns-records')} className={currentTab == 'dns-records' ? `-selected` : undefined}>{t('DNS Records')}</li>
              <li onClick={e => setCurrentTab('smtp-credentials')} className={currentTab == 'smtp-credentials' ? `-selected` : undefined}>{t('SMTP Credentials')}</li>
              <li onClick={e => setCurrentTab('logs')} className={currentTab == 'logs' ? `-selected` : undefined}>{t('Event logs')}</li>
              <li onClick={e => setCurrentTab('getting-started')} className={currentTab == 'getting-started' ? `-selected` : undefined}>{t('Getting Started')}</li>
            </ul>
          </div>

          {currentTab === 'details' && systemEmailDetails && systemEmailDetails.domain &&
            <div>
              <h2>{t('Domain information')}</h2>
              <table className="lvl-table-definition-list">
                <thead></thead>
                <tbody>
                  <tr>
                    <th>{t('Domain Name')}</th>
                    <td>{systemEmailDetails.domain.name}</td>
                  </tr>
                  <tr>
                    <th>{t('Status')}</th>
                    <td>{systemEmailDetails.domain.state}</td>
                  </tr>
                  <tr>
                    <th>{t('Created at')}</th>
                    <td>{moment(systemEmailDetails.domain.created_at).format('YYYY-MM-DD')}</td>
                  </tr>
                  <tr>
                    <th>{t('Current plan')}</th>
                    <td>{currentProductName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          }

          {currentTab === 'details' && systemEmailStatsTotal && systemEmailStatsTotal.stats &&
            <div>
              <h2>{t('Stats last 24 hours')}</h2>
              <table className="lvl-table-definition-list">
                <thead>
                  <tr>
                    <td>Time</td>
                    <td>Incomming</td>
                    <td>Outgoing</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  {systemEmailStatsTotal.stats.map(stat => (
                    <tr key={stat.time}>
                      <td>{stat.time}</td>
                      <td>{stat.accepted.incomming}</td>
                      <td>{stat.accepted.outgoing}</td>
                      <td>{stat.accepted.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }

          {currentTab === 'dns-records' &&
            <DNSRecords 
              systemEmailDetails={systemEmailDetails}
              setSystemEmailDetails={setSystemEmailDetails}
              billingAccountId={billingAccountId}
              workspaceId={workspaceId}
              serviceId={serviceId} />
          }

          {currentTab === 'smtp-credentials' && <SMTPCredentials />}

          {currentTab === 'logs' && <Logs />}

          {currentTab === 'getting-started' &&
            <div>
              <h2>{t('How to send email via API')}</h2>
              <p><a href="https://levelupapis.no">{t('Link to API documentation')}</a></p>
            </div>
          }

        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesSystemEmailsDetails
