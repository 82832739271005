import React, { useState, useEffect } from 'react'
import { useNotifications } from '../../../notifications'

const UserNotification = ({text, status}) => {
  /*
  Type: success, error, warning
  */
  const [ internalClose, setInternalClose ] = useState(false)
  const { destroyNotification } = useNotifications()

  let notificationTypeClass = ``
  if (status) {
    notificationTypeClass = `lvl-user-notification--${status}`
  }

  // is closing effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setInternalClose(true)
    }, 2000)
    return () => clearTimeout(timer)
  }, [])

  // is destroy effect
  useEffect(() => {
    const timer = setTimeout(() => {
      destroyNotification()
    }, 2500)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div className={`lvl-user-notification-container ${internalClose ? 'is-closing' : ''}`}>
      <div className={`lvl-user-notification ${notificationTypeClass}`}>
        <div className="lvl-user-notification__message">
          {text}
        </div>
        <span className="lvl-user-notification__close" onClick={ e => setInternalClose(!internalClose) }></span>
      </div>
    </div>
  )
}

const UserMessage = ({text, status}) => {
  /*
  Type: success, error, warning
  */
  const [ active, setActive ] = useState(false)

  let notificationTypeClass = ``
  if (status) {
    notificationTypeClass = `lvl-user-message--${status}`
  }
  return (
    <div className={`lvl-user-message-container`}>
      <div className={`lvl-user-message ${notificationTypeClass}`}>
        <div className="lvl-user-message__message">
          {text}
        </div>
      </div>
    </div>
  )
}

export {
  UserNotification,
  UserMessage
}

