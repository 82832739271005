import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import {Line} from 'react-chartjs-2';
import validateUUID from 'uuid-validate'

import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

const KubernetesContainetsDetails = () => {
  const [ timespan, setTimespan] = useState({value: 1, key: 'd'})
  const [ usage, setUsage ] = useState([])
  const [ service, setService ] = useState({})

  const { t } = useTranslation()
  const { getBillingAccountWorkspaceService, getBillingAccountWorkspaceServiceHourlyUsages } =  useLevelupSDK()

  const { billingAccountId, workspaceId, serviceId } = useParams()

  let queryString = '?'
  let start = moment().subtract(timespan.value, timespan.key).toISOString()
  const end = moment().toISOString()
  queryString += `start=${start}&end=${end}`

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return
    if (!serviceId || !validateUUID(serviceId)) return

    getBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
      .then(service => setService(service))
      .catch(error => console.error(error))

  }, [billingAccountId, workspaceId, serviceId, getBillingAccountWorkspaceService])

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return
    if (!serviceId || !validateUUID(serviceId)) return

    getBillingAccountWorkspaceServiceHourlyUsages(billingAccountId, workspaceId, serviceId, queryString)
      .then(res => setUsage(res))
      .catch(e => console.error(e))
  }, [billingAccountId, workspaceId, serviceId, timespan, getBillingAccountWorkspaceServiceHourlyUsages, queryString])

  const cpuUsage = usage.filter(u => u.key === 'CPU') || []

  const cpuLineData = {
    labels: cpuUsage.map(u => moment(u.date).format('YYYY.MM.DD HH:mm')),
    datasets: [
      {
        label: 'My First dataset',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: cpuUsage.map(u => u.value)
      }
    ]
  }
  const ramUsage = usage.filter(u => u.key === 'RAM') || []

  const ramLineData = {
    labels: ramUsage.map(u => moment(u.date).format('YYYY.MM.DD HH:mm')),
    datasets: [
      {
        label: 'My First dataset',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: ramUsage.map(u => u.value)
      }
    ]
  }

  return (
    <Fragment>
      <div className="lvl-page">

        <div className="lvl-page__preheader">
          <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/vpsr-hosting/kubernetes-containers`}>&larr; {t('Back')}</Link>
          <span style={{margin: '0 5px 0 5px'}}></span>
          <b>{t('Kubernetes containers')}</b>
          <span style={{margin: '0 5px 0 5px'}}></span>
          <span>{t('Edit')}</span>
          <span style={{margin: '0 5px 0 5px'}}></span>
          <span>{t('Refresh')}</span>
        </div>

        <div className="lvl-page__header">
          <h1>{service.name}</h1>
        </div>

        <div className="lvl-page__body">
          <div>
            <h2>Service information</h2>
            <table className="lvl-table-definition-list">
              <thead></thead>
              <tbody>
                <tr>
                  <th>Current image</th>
                  <td>repo.io/test:test</td>
                </tr>
                <tr>
                  <th>Details</th>
                  <td>{service.details && JSON.stringify(service.details)}</td>
                </tr>
              </tbody>
            </table>
            <dl>
            </dl>
          </div>
          <div>
            <h2>Usage</h2>
            <div>
              <ul className="lvl-selectable-list lvl-block">
                <li onClick={e => setTimespan({value: 1, key: 'd'})} className={ timespan.value === 1 && `-selected`}>1 day</li>
                <li onClick={e => setTimespan({value: 2, key: 'd'})} className={ timespan.value === 2 && `-selected`}>2 days</li>
                <li onClick={e => setTimespan({value: 4, key: 'd'})} className={ timespan.value === 4 && `-selected`}>4 days</li>
                <li onClick={e => setTimespan({value: 7, key: 'd'})} className={ timespan.value === 7 && `-selected`}>7 days</li>
                <li onClick={e => setTimespan({value: 14, key: 'd'})} className={ timespan.value === 14 && `-selected`}>14 days</li>
                <li onClick={e => setTimespan({value: 30, key: 'd'})} className={ timespan.value === 30 && `-selected`}>30 days</li>
              </ul>
            </div>
            <div style={{display: 'flex', width: '100%'}}>
              <div style={{flex: 1}}>
                <h3>CPU</h3>
                <Line data={cpuLineData} />
              </div>
              <div style={{flex: 1}}>
                <h3>RAM</h3>
                <Line data={ramLineData} />
              </div>
            </div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}

export default KubernetesContainetsDetails
