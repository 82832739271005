import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import _ from 'lodash'
import validateUUID from 'uuid-validate'

import cPanelWhmLogo from '../../assets/cpanel-whm-logo.png'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import NoServices from '../../components/misc-ui-components/no-services'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'
import ProviderExcerpt from '../../components/misc-ui-components/provider-excerpt'

import { Mixpanel } from '../../mixpanel'

const BillingAccountsWorkspacesSharedHosting = () => {
  const [ products, setProducts ] = useState([])
  const [ services, setServices ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const history = useHistory()
  const { t } = useTranslation()
  const { getProductsGroupsProducts, getBillingAccountWorkspaceServices } =  useLevelupSDK()
  const { billingAccountId, workspaceId } = useParams()


  // HARD CODED STRING!
  const SHARED_HOSTING_PRODUCTS_GROUPS_ID = '6c0475bc-7a79-43bf-a39d-47143956fb74'

  useEffect(() => {
    // reset always
    setServices([])
    setProducts([])

    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(SHARED_HOSTING_PRODUCTS_GROUPS_ID).then(products => {
      setProducts(products)
      Promise.all(products.map(product => getBillingAccountWorkspaceServices(billingAccountId, workspaceId, `?productId=${product.id}`)))
        .then(res => {
          let flattened = res.reduce(
            function(accumulator, currentValue) {
              return accumulator.concat(currentValue)
            },
            []
          )
          setServices(flattened)
          setLoading(false)
        })
        .catch(e => setLoading(false))
    }).catch(error => console.error(error))


  }, [])

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <div className="h-flex-wrap h-flex-align-center">
            <h1>{t('Hosting')}</h1>
            <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/add`} onClick={e => Mixpanel.track("Clicked add new hosting", {"var1": "hip-hop", "var2": 42})} className="lvl-button h-margin-left-one-unit">{t('Create new hosting')}</Link>
          </div>
          <ProviderExcerpt logoHtml={<img src={cPanelWhmLogo} />} />
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">
          {!loading && services.length > 0 &&
            <Fragment>
              <Table
                headArray={[
                  t('Name'),
                  t('Product'),
                  // t('Actions')
                ]}
                rows={_.orderBy(services, [service => service.name.toLowerCase()], ['asc']).map(service => [
                  <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/${service.id}`}>{service.name}</Link>,
                  products.find(p => p.id === service.productId).name,
                  // <Fragment>
                  //   <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/${service.id}`}>{t('View')}</Link>, {t('Link to cPanel')}
                  // </Fragment>
                ])} />

                <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/add`} onClick={e => Mixpanel.track("Clicked add new hosting", {"var1": "hip-hop", "var2": 42})} className="lvl-button">{t('Create new hosting')}</Link>
              </Fragment>
          }

          {!loading && services.length === 0 &&
            <NoServices
              productName={t('great hosting')}
              ctaOnClick={() => history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/add`)}
              />
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesSharedHosting
