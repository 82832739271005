import React, { useState, useEffect } from 'react'
import { useModal } from '../../../modal'
import { useTranslation } from 'react-i18next'
import RichTextEditor from 'react-rte';

import { useLevelupSDK } from '../../../levelup-sdk'
import { useNotifications } from '../../../notifications'


const Modal = ({}) => {
  const { setNotification } = useNotifications()
  const { postTicket } = useLevelupSDK()

  const [ internalClose, setInternalClose ] = useState(false)
  const { modal, destroyModal } = useModal()

  const [ rteContent, setRteContent ] = useState(RichTextEditor.createEmptyValue())
  const [ message, setMessage] = useState('')

  const { t } = useTranslation()

  const close = () => {
    setInternalClose(true)
    setTimeout(() => {
      console.log('destroy')
      destroyModal()
      setInternalClose(false)
    }, 500)
  }

  const submit = (e) => {
    e.preventDefault()

    postTicket({
      subject: 'Request from the console',
      message: message
    }).then(res => {
      setNotification({text: t('Message submitted 🎉'), status: 'success'})
      close()
    }).catch(err => {
      setNotification({text: t('Failed to submit message, please try again.'), status: 'error'})
    })
  }

  return (
    <div className={`lvl-modal ${internalClose ? 'is-closing' : ''}`}>
      <div className="lvl-modal__content">
        <div className="lvl-modal__message">
            {modal.content}
            <div className="lvl-block">
              <form onSubmit={e => submit(e)}>
                <RichTextEditor
                  value={rteContent}
                  toolbarConfig={{
                    // Optionally specify the groups to display (displayed in the order listed).
                    display: [],
                    // INLINE_STYLE_BUTTONS: [
                    //   {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                    //   {label: 'Italic', style: 'ITALIC'},
                    //   {label: 'Underline', style: 'UNDERLINE'}
                    // ],
                    // BLOCK_TYPE_DROPDOWN: [
                    //   {label: 'Normal', style: 'unstyled'},
                    //   {label: 'Heading Large', style: 'header-one'},
                    //   {label: 'Heading Medium', style: 'header-two'},
                    //   {label: 'Heading Small', style: 'header-three'}
                    // ],
                    // BLOCK_TYPE_BUTTONS: [
                    //   {label: 'UL', style: 'unordered-list-item'},
                    //   {label: 'OL', style: 'ordered-list-item'}
                    // ]
                  }}
                  onChange={(value) => {
                    setRteContent(value) // set the component value (more advanced)
                    setMessage(value.toString('html')) // set the string formated data to message
                  }}
                />
                <p>{t("Please make sure to include all details in your request.")}</p>
                <button type="submit">{t("Send")}</button>
              </form>
            </div>

            <div className="lvl-block">
              <span className="demo">{t("Prefer to call?")}</span>
              <h2 className="demo">{t("Reach out on +47 333 22 122 🤙")}</h2>
            </div>

            <div className="lvl-block">
              <span className="demo">{t("Or maybe email is your thing?")}</span>
              <h2 className="demo">{t("Hit us at hei@levelup.no ✍️")}</h2>
            </div>
        </div>
        <span className="lvl-modal__close" onClick={e => close()}></span>
      </div>
    </div>
  )
}

export {
  Modal
}

