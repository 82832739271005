import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import validateUUID from 'uuid-validate'

import vonageLogo from '../../assets/vonage-logo.jpeg'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'
import ProviderExcerpt from '../../components/misc-ui-components/provider-excerpt'
import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'
import ContactUs from '../../components/misc-ui-components/contact-us'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

import Details from './details'
import Logs from './logs'
import GettingStarted from './getting-started'

const BillingAccountsWorkspacesSystemEmailsDetails = () => {
  const [ currentTab, setCurrentTab ] = useState('details')
  const [ products, setProducts ] = useState([])
  const [ service, setService ] = useState({})
  const [ loading, setLoading ] = useState(true)

  const { setNotification } = useNotifications()

  const { t } = useTranslation()
  const history = useHistory()
  const {
    getProductsGroupsProducts,
    getBillingAccountWorkspaceService,
    getBillingAccountWorkspaceSystemSMSEvents,
    deleteBillingAccountWorkspaceService
  } =  useLevelupSDK()


  const { billingAccountId, workspaceId, serviceId } = useParams()


  // HARD CODED STRING!
  const SMS_PRODUCT_GROUP = '11211e70-37ea-43b8-a4fa-cc662f59a4b2'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(SMS_PRODUCT_GROUP)
      .then(products => setProducts(products))
      .catch(e => console.error(e))

    getBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
      .then(service => {
        setService(service)
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        setLoading(false)
      })

  }, [billingAccountId, workspaceId, serviceId])

  // define strings
  let currentProductName = t('Could not fetch product information')
  const currentProduct = products.find(p => p.id === service.productId)
  if (products.length > 0 && service && service.productId) {
    currentProductName = currentProduct ? currentProduct.name : currentProductName
  }

  const deleteService = async () => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      deleteBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
        .then(res => {
          history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms`)
          setNotification({text: t('Deleted ✌️'), status: 'success'})
        })
        .catch(error => console.error(error.message))
    }
  }


  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
            header={t(currentProductName)}
            backLink={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms`}
            options={[
              <Link className="lvl-button" to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms/${serviceId}/edit`}><i className="fa fa-pencil"></i>{t('Edit')}</Link>,
              <button className="lvl-button lvl-button--dangerous" onClick={e => deleteService()}><i className="fa fa-trash-o"></i>{t('Delete')}</button>
              ]}
          />
        </div>

        {loading && <ProgressIndicator />}


        <div className="lvl-page__header">
          <h1>{service.name}</h1>
          <ProviderExcerpt logoHtml={<img src={vonageLogo} />} />
        </div>

        <div className="lvl-page__body">

          <div>
            <ul className="lvl-selectable-list lvl-block">
              <li onClick={e => setCurrentTab('details')} className={currentTab == 'details' ? `-selected` : undefined}>{t('Details')}</li>
              <li onClick={e => setCurrentTab('logs')} className={currentTab == 'logs' ? `-selected` : undefined}>{t('Event logs')}</li>
              <li onClick={e => setCurrentTab('getting-started')} className={currentTab == 'getting-started' ? `-selected` : undefined}>{t('Getting Started')}</li>
            </ul>
          </div>

          {currentTab === 'details' && <Details service={service} currentProduct={currentProduct} />}

          {currentTab === 'logs' && <Logs service={service} setLoading={setLoading} />}

          {currentTab === 'getting-started' && <GettingStarted />}


          <div>
            <ContactUs preText={t('Do you want any of the values above changed or deleted?')} />
          </div>

        </div>


      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesSystemEmailsDetails
