import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import Table from '../../components/table'
import NoServices from '../../components/misc-ui-components/no-services'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

const PrioritySupport = () => {
  const [ products, setProducts ] = useState([])
  const [ services, setServices ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const history = useHistory()
  const { t } = useTranslation()
  const { getProductsGroupsProducts, getBillingAccountServices } =  useLevelupSDK()
  const { billingAccountId } = useParams()
  const { setNotification } = useNotifications()

  // HARD CODED STRING!
  const PRIORITY_SUPPORT_PRODUCTS_GROUPS_ID = 'd861ea2d-9fed-4426-b43e-2d6f201e9d28'

  useEffect(() => {
    reload()
  }, [billingAccountId])

  const reload = () => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return

    getProductsGroupsProducts(PRIORITY_SUPPORT_PRODUCTS_GROUPS_ID)
      .then(products => {
        setProducts(products)
        Promise.all(products.map(product => getBillingAccountServices(billingAccountId, `?productId=${product.id}`)))
          .then(res => {
            const combinedServices = Array.prototype.concat.apply([], res)
            setServices(combinedServices)
            setLoading(false)
          }).catch(e => console.error(e))
      })
      .catch(error => console.error(error))
  }

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Priority Support')}</h1>
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">
          {!loading && services.length > 0 &&
            <Table
              headArray={[
                t('Product'),
                t('Actions')
              ]}
              rows={services.map(service => {
                const product = products.find(p => p.id === service.productId)

                return [
                  product.name || 'Laster...',
                  <span><Link to={`/billing-accounts/${billingAccountId}/priority-support/${service.id}/edit`}>{t('Edit')}</Link></span>
                ]
              })} />
          }

          {!loading && services.length === 0 &&
            <NoServices
              productName={t('smooth priority support')}
              ctaOnClick={e => history.push(`/billing-accounts/${billingAccountId}/priority-support/add`)}
              inBillingAccount={true}
              />
          }
        </div>
      </div>
    </Fragment>
  )
}

export default PrioritySupport
