import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'


const RECORD_TYPES = ['A', 'AAAA', 'CNAME', 'TXT', 'SRV', 'LOC', 'MX', 'NS', 'SPF', 'CERT', 'DNSKEY', 'DS', 'NAPTR', 'SMIMEA', 'SSHFP', 'TLSA', 'URI']

const DNSRecordRow = ({record, billingAccountId, workspaceId, serviceId, reloadDNSRecords}) => {
  const [editMode, setEditMode ] = useState(false)
  const [ cloudflareErrors, setCloudflareErrors] = useState([])

  const { t } = useTranslation()
  const { setNotification } = useNotifications()
  const {
    putBillingAccountWorkspaceZoneFileDNSRecord,
    deleteBillingAccountWorkspaceZoneFileDNSRecord
  } =  useLevelupSDK()


  return editMode ? (
    <Formik
      initialValues={{
        type: record.type,
        name: record.name,
        content: record.content,
        ttl: record.ttl,
        priority: record.priority,
        proxied: record.proxied
      }}
      validationSchema={yup.object({
        name: yup.string().min(1, t('Minimum 1 character')).max(255, t('Minimum 255 characters')).required(t('Required')),
        content: yup.string().required(t('Required')),
        ttl: yup.number().required(t('Required')),
        proxied: yup.boolean()
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)

        putBillingAccountWorkspaceZoneFileDNSRecord(billingAccountId, workspaceId, serviceId, record.id, values)
          .then(result => {
            if (result && !result.success) {
              setNotification({text: t('Failed'), status: 'error'})
              setCloudflareErrors(result.errors)
              setSubmitting(false)
            } else {
              setSubmitting(false)
              setEditMode(false)
              reloadDNSRecords()
              setCloudflareErrors([])
              setNotification({text: t('Updated 🎉'), status: 'success'})
            }
          })
          .catch(e => {
            setNotification({text: t('Failed'), status: 'error'})
            setCloudflareErrors([])
            setSubmitting(false)
          })
      }}
      >
      {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
          <tr>
            <td>
              <Form>

                <div className="lvl-input lvl-input--select">
                  <label htmlFor="type">{t('Type')}</label>
                  <Field as="select" id="type" name="type">
                    {RECORD_TYPES.map(r => <option value={r} key={r}>{r}</option>)}
                  </Field>
                </div>

                <div className="lvl-input">
                  <label htmlFor="name">{t('Name')}</label>
                  <Field type="text" id="name" name="name" />
                  <ErrorMessage name="name" component="div" className="validation-message" />
                </div>

                <div className="lvl-input">
                  <label htmlFor="content">{t('Content')}</label>
                  <Field type="text" id="content" name="content" />
                  <ErrorMessage name="content" component="div" className="validation-message" />
                </div>

                <div className="lvl-input">
                  <label htmlFor="ttl">{t('TTL')}</label>
                  <Field type="number" id="ttl" name="ttl" />
                  <ErrorMessage name="ttl" component="div" className="validation-message" />
                </div>

                <label className="lvl-input-checkbox">
                  {t('Cloudflare Proxy')}
                  <input type="checkbox" 
                         checked={values.proxied} 
                         id="proxied" 
                         onChange={handleChange}
                         onBlur={handleBlur} />
                  <span className="checkmark"></span>
                </label>

                {cloudflareErrors.length > 0 &&
                  <div style={{background: '#E25F4A', padding: 20}}>
                    {cloudflareErrors.map(cfe => (
                      <div>
                        <div className="validation-message">{cfe.message}</div>
                        {cfe.error_chain && cfe.error_chain.map(chain => <div className="validation-message">- {chain.message}</div>)}
                      </div>
                    ))}
                  </div>
                }

                <div className="lvl-button-group">
                  <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Save')}</button>
                  <button type="button" onClick={e => setEditMode(false)}>{t('Cancel')}</button>
                </div>
            </Form>
          </td>
        </tr>
      )}
    </Formik>
  ) : (
    <tr>
      <td>{record.type}</td>
      <td>{record.name}</td>
      <td>{record.content}</td>
      <td>{record.ttl === 1 ? 'Auto' : record.ttl}</td>
      <td>{record.proxied ? <i className="fa fa-cloud" style={{color: '#f38020', fontSize: '150%'}}></i> : <i className="fa fa-cloud" style={{color: 'gray', fontSize: '150%'}}></i> }</td>
      <td>
        <button onClick={e => setEditMode(true)}>Edit</button>
        <button onClick={e => {
          deleteBillingAccountWorkspaceZoneFileDNSRecord(billingAccountId, workspaceId, serviceId, record.id)
            .then(res => {
              reloadDNSRecords()
              setNotification({text: t('Deleted 🎉'), status: 'success'})
            })
            .catch(e => {
              setNotification({text: t('Failed'), status: 'error'})
            })
        }} className="lvl-button--dangerous">Delete</button>
      </td>
    </tr>
  )
}

export default DNSRecordRow
