import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
    }
  },
  nb: {
    translation: {
      "Back": "Tilbake",
      "Dashboard": "Oversikt",
      "Services": "Tjenester",
      "Service": "Tjeneste",
      "Product": "Produkt",
      "Team": "Team",
      "Billing": "Fakturering",
      "Invoices": "Fakturaer",
      "Invoice": "Faktura",
      "Payment methods": "Betalingsmåter",
      "Profile": "Profil",
      "Name": "Navn",
      "Email": "Epost",
      "Verified": "Verifisert",
      "Not verified": "Ikke verifisert",
      "Language": "Språk",
      "English": "Engelsk",
      "Norwegian": "Norsk",
      "Update": "Oppdater",
      "Log out": "Logg ut",
      "Choose": "Velg",
      "Change to": "Bytt til",
      "Delete": "Slett",
      "Edit": "Endre",
      "Refresh": "Last inn på nytt",
      "View": "Vis",
      "Usage": "Forbruk",
      "Discount": "Rabatt",
      "Discounts": "Rabatter",
      "No products in the group": "Ingen produkter i gruppen",
      "Remove from team": "Fjern fra team",
      "Invite new team member": "Inviter nytt team-medlem",
      "Add new service": "Legg til ny tjeneste",
      "Add new": "Legg til",
      "Select workspace": "Velg arbeidsområde",
      "Create new workspace": "Opprett nytt arbeidsområde",
      "Manage services": "Administrer tjenester",
      "Manage team": "Administrer team",
      "Go to billing": "Gå til fakturering",
      "Actions": "Handlinger",
      "Workspace settings": "Innstillinger for arbeidsområde",
      "Workspace Settings": "Innstillinger for arbeidsområde",
      "No services found": "Ingen tjenester funnet",
      "Link to cPanel": "Link til cPanel",
      "Hosting": "Webhotell",
      "Monitoring": "Overvåkning",
      "System Email": "System-epost",
      "System SMS": "System-SMS",
      "Service information": "Tjenesteinformasjon",
      "Logs last 24 hours": "Logger de siste 24 timene",
      "Priority Support": "Prioritert Support",
      "Select Account": "Velg konto",
      "Create new Account": "Opprett ny konto",
      "Account Settings": "Kontoinnstillinger",
      "Account": "Konto",
      "Search": "Søk",
      "Not selected": "Ikke valgt",
      "Current month": "Innværende måned",
      "Last month": "Siste måned",
      "Last 30 days": "Siste 30 dager",
      "Last 90 days": "Siste 90 dager",
      "Cardholder Name": "Navn på kortet",
      "City": "Sted/By",
      "Country": "Land",
      "Address": "Adresse",
      "Address, line 1": "Adresse, linje 1",
      "Address, line 2": "Adresse, linje 2",
      "Postal code": "Postnr.",
      "State": "Fylke",
      "Phone": "Telefon",
      "Cancel": "Avbryt",
      "Submit": "Registrer",
      "Billing report": "Faktureringsrapport",
      "Usage & Costs": "Forbruk & Kostnader",
      "Expires": "Utløper",
      "Remove": "Fjern",
      "Percentage discounts": "Prosentrabatt",
      "Percentage discount": "Rabatt i prosent",
      "Percentage Discounts": "Prosentrabatt",
      "Valid from": "Gyldig fra",
      "Valid to": "Gyldig til",
      "Limited to product": "Begrenset til produkt",
      "Limited to service": "Begrenset til tjeneste",
      "Unit key": "Enhet",
      "Cost": "Kostnad",
      "One time credits": "Engangsrabatt",
      "Total discount": "Sum rabatter",
      "Sub total": "Subtotal",
      "Invite": "Inviter",
      "Date": "Dato",
      "Due date": "Forfalls-/trekkdato",
      "Amount paid": "Betalt beløp",
      "Amount pending": "Utestående beløp",
      "Currency": "Valuta",
      "Show invoice": "Vis faktura",
      "Paid invoices": "Betalte fakturaer",
      "Pending invoices": "Ubetalte fakturaer",
      "Primary": "Primær",
      "Backup": "Backup",
      "Inactive": "Inaktiv",
      "Norway": "Norge",
      "Company": "Firma",
      "Organization Number": "Orgnr.",
      "Organization number": "Orgnr.",
      "Transaction ID": "Transaksjons ID",
      "Billing date": "Fakturadato",
      "Amount ex VAT": "Beløp eks MVA",
      "VAT": "MVA",
      "Amount": "Beløp",
      "N-3163": "3163",
      "Workspace": "Arbeidsområde",
      "Workspaces": "Arbeidsområder",
      "Edit Workspace": "Rediger Arbeidsområde",
      "Delete workspace": "Slett arbeidsområde",
      "Administration": "Administrasjon",
      "If you submit your workspace for deletion a request will be sent to Levelup, and they will manualy validate the deletion. Once your workspace is deleted, you can never get back your data!": "Hvis du ber om sletting av ditt arbeidsområdet vil en forespørsel bli sendt til Levelup, og de validerer slettingen manuelt. Når arbeidsområdet er slettet, kan du aldri få tilbake dataene!",
      "Save": "Lagre",
      "Add Account": "Ny Konto",
      "Edit Account": "Rediger Konto",
      "Preferred contact language": "Ønsket kontaktspråk",
      "Settings": "Innstillinger",
      "Contact person": "Kontaktperson",
      "currentlyNoWorkspace": "Ingen arbeidsområder her, men du kan <1>lage ett</1>",
      "No invoices, yet": "Ingen fakturaer registrert enda",
      "beforeCreateWorkspaceAddPaymentMethod": "Før du kan lage arbeidsområder og legge til tjenester må du <1>legge til en betalingsmåte</1>",
      "Missing billing information": "Betalingsmåte mangler",
      "Missing": "Mangler",
      "Required": "Påkrevd",
      "Status": "Status",
      "Paused": "Pauset",
      "Cache": "Cache",
      "Name Server": "Navneserver",
      "Name Servers": "Navneservere",
      "Domain Administration": "Domeneadministrasjon",
      "Zone File": "Sonefil",
      "Zone Files": "Sonefiler",
      "MX Mapping": "MX-Mapping",
      "DNS Records": "DNS-Oppføringer",
      "Account ID": "Konto ID",
      "Private API Key": "Privat API-nøkkel",
      "Show": "Vis",
      "Red": "Rød",
      "Green": "Grønn",
      "Error": "Feil",
      "24 hours ago": "24 timer siden",
      "12 hours ago": "12 timer siden",
      "Right now": "Akkurat nå",
      "Updated 🎉": "Oppdatert 🎉",
      "Message submitted 🎉": "Melding sendt 🎉",
      "Failed to submit message, please try again.": "Klarte ikke å sende melding, vennligst prøv igjen.",
      "Ticket submitted 🎉": "Henvendelse sendt 🎉",
      "Failed to submit ticket, please try again.": "Klarte ikke å sende henvendelsen, vennligst prøv igjen.",
      "Failed to update": "Klarte ikke å oppdatere",
      "Deleted ✌️": "Slettet ✌️",
      "Failed to delete service": "Klarte ikke å slette tjeneste",
      "Team member removed": "Teammedlem fjernet",
      "Invitation sent 🎉": "Invitasjon sendt 🎉",
      "Cache purged 🎉": "Cache tømt 🎉",
      "Failed to purge cache": "Klarte ikke å tømme cache",
      "Refreshed 🙌": "Oppdatert 🙌",
      "Password updated 🎉": "Passord oppdatert 🎉",
      "Failed. Please try again or contact support": "Feilet. Vennligst prøv igjen eller kontakt support",
      "DNS checked 🎉": "DNS sjekket 🎉",
      "Failed to check DNS": "Klarte ikke å sjekke DNS",
      "Select account": "Velg konto",
      "View all accounts": "Se alle kontoene",
      "View all workspaces": "Se alle arbeidsområder",
      "SSL Certificates": "SSL-sertifikater",
      "Create new monitor": "Opprett ny overvåkning",
      "Add new monitor": "Legg til ny overvåkning",
      "Monitor type": "Type overvåkning",
      "Error Monitoring": "Overvåkning av feil",
      "HTTP Monitoring": "HTTP Overvåkning",
      "SSL Monitoring": "SSL Overvåkning",
      "CRON Monitoring": "CRON Overvåkning",
      "Domain": "Domene",
      "Estimated price": "Estimert pris",
      "Price per hour": "Pris pr time",
      "Estimated monthly price": "Estimert pris pr mnd",
      "Does not include any discounts": "Inkluderer ikke eventuelle rabatter",
      "Create": "Opprett",
      "Add new payment method": "Legg til ny betalingsmåte",
      "Add payment method": "Legg til betalingsmåte",
      "Add Payment Method": "Legg til betalingsmåte",
      "No product restriction": "Ingen produktbegrensning",
      "No service restriction": "Ingen tjenestebegrensning",
      "Print": "Skriv ut",
      "Payment method": "Betalingsmåte",
      "Payed": "Betalt",
      "Requested": "Forespurt",
      "Create new hosting": "Opprett nytt webhotell",
      "Domains": "Domener",
      "Billing contact": "Faktureringskontakt",
      "Technical contact": "Teknisk kontaktperson",
      "Contact us if you need to change your email address": "Ta kontakt med oss om du trenger å oppdatere epostadressen din",
      "How to send SMS": "Hvordan sende SMS?",
      "Link to API documentation": "Lenke til API dokumentasjon",
      "From field": "Fra felt",
      "Current plan": "Nåværende produktplan",
      "Latest log information": "Siste informasjon fra loggen",
      "Status code": "Status kode",
      "Status message": "Status melding",
      "Logged at": "Tidspunkt logget",
      "Valid": "Gyldig",
      "Days remaining": "Gjenstående dager",
      "Yes": "Ja",
      "No": "Nei",
      "Account services": "Kontotjenester",
      "Switch account": "Bytt konto",
      "This is the person that is going to get all technical updates.": "Dette er personen som vil motta alle de tekniske oppdateringene.",
      "Want to make changes?": "Ønsker du å gjøre endringer?",
      "cPanel details": "cPanel detaljer",
      "Username": "Brukernavn",
      "External IP": "Ekstern IP",
      "Account email": "Tilknyttet epost",
      "Plan": "Prisplan",
      "Login URL": "URL for administrasjon",
      "Set new password": "Sett nytt passord",
      "Password": "Passord",
      "Password strength": "Passord styrke",
      "Must be greater than 65": "Må være over 65",
      "Change plan": "Endre prisplan",
      "Contact us": "Kontakt oss",
      "Do you want any of the values above changed or deleted?": "Ønsker du noen av verdiene ovenfor endret eller slettet?",
      "Account home": "Startsiden for konto",
      "Add Priority Support": "Legg til Prioritert Support",
      "Forecast for whole month": "Progonse for hele måneden",
      "usageDiscountStatement": "Inkluderer <1>{prettyPrice(totalDiscounts, 'NOK')}</1> i rabatt.",
      "Deletion requested": "Sletting forespurt",
      "noServiceRunning": "Ingen <1>{productName}</1> tjeneste kjører på dette arbeidsområdet.",
      "great hosting": "suveren hosting",
      "domains": "domene",
      "Get started": "Kom i gang",
      "monitoring": "overvåkning",
      "Add New Hosting": "Legg til nytt webhotell",
      "Account Name": "Kontonavn",
      "contactUsToCreateService": "Vennligst <1>kontakt oss</1> for å opprette din første 🥳",
      "Order": "Bestill",
      "Please make sure to include all details in your request.": "Husk å inkludere alle nødvendige detaljer.",
      "Prefer to call?": "Foretrekker du å ringe?",
      "Reach out on +47 333 22 122 🤙": "Kontakt oss på +47 333 22 122 🤙",
      "Or maybe email is your thing?": "Eller kanskje epost er din greie?",
      "Hit us at hei@levelup.no ✍️": "Skriv til oss på hei@levelup.no ✍️",
      "We'd love to help you": "Vi hjelper deg gjerne",
      "weAreImprovingTheConsole": "Vi jobber med forbedringer for å gjøre konsollen bedre for deg. Ikke nøl med å <1>ta kontakt</1> 🤗",
      "Credit card": "Kort",
      "No usage or costs in this period": "Ingen forbruk eller kostnad i denne perioden",
      "Create your first account": "Opprett din første konto",
      "You have to create an account to hold your billing information before you can start creating services.": "Du må opprette en konto som kan holde betalingsinformasjonen din før du kan opprette tjenester.",
      "Event logs": "Hendelseslogg",
      "Timestamp": "Tidsstempel",
      "Recipient": "Mottaker",
      "Message ID": "Meldings ID",
      "Message": "Melding",
      "Next": "Neste",
      "Previous": "Tilbake",
      "Getting Started": "Kom i gang",
      "Details": "Detaljer",
      "Monitor your CRON by checking in to this address in the end of your cron job.": "Overvåk din CRON ved å gjøre et kall til denne adressen i slutten av din CRON.",
      "Example": "Eksempel",
      "Reseller Details": "Reseller detaljer",
      "Overview current month": "Oversikt inneværende måned",
      "Estimated kickback so far this month": "Estimert kickback så langt denne måneden",
      "Estimated kickback this month": "Estimert kickback denne måneden",
      "History": "Historikk",
      "Services reporting errors": "Tjenester som rapporterer om feil",
      "Purge cache": "Tøm cache",
      "Cache purged 🎉": "Cache tømt 🎉",
      "Failed to purge cache": "Klarte ikke å tømme cachen",
      "Add new record": "Legg til ny oppføring",
      "Search accounts": "Søk i kontoer",
      "Search workspaces": "Søk i arbeidsområder",
      "Select your plan": "Velg pakke"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
