import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import Table from '../../components/table'

import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'


import { prettyPrice } from '../../utils/pretty-prices'

const RenderProductPrices = ({productId}) => {
  const [ productPrice, setProductPrice] = useState({})

  const { t } = useTranslation()
  const { getProductPrice } =  useLevelupSDK()

  useEffect(() => {
    if (!productId) return 

    getProductPrice(productId)
    .then(r => setProductPrice(r))
    .catch(e => console.error(e))
  }, [productId])

  return (
    <div className="lvl-callout">
      <h3>{t('Estimated price')}</h3>
      <p>{t('Price per SMS')}: {prettyPrice(productPrice.pricePerHour, 'NOK')}<br />
        <small><i>* {t('Does not include any discounts')}.</i></small>
      </p>
    </div>
  )
}

const BillingAccountsWorkspacesSystemSMSAdd = () => {
  const [ loading, setLoading ] = useState(false)
  const [ product, setProduct ] = useState([])

  const { setNotification } = useNotifications()
  const history = useHistory()
  const { t } = useTranslation()
  const {
    getProduct,
    postBillingAccountWorkspaceServices
  } =  useLevelupSDK()

  const { billingAccountId, workspaceId } = useParams()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProduct('b974d8cd-9535-4100-ba31-9bfc6bc75c55')
      .then(res => setProduct(res))
      .catch(error => {
         setNotification({text: t('Failed'), status: 'error'})
         history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms`)
      })
  }, [billingAccountId, workspaceId])


  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
            header={t('Add System SMS')}
            backLink={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms`}
          />
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__header">
          <h1>{t('Add System SMS')}</h1>
        </div>

        <div className="lvl-page__body">
          <Formik
            initialValues={{
              name: '',
              productId: 'b974d8cd-9535-4100-ba31-9bfc6bc75c55'
            }}
            validationSchema={yup.object({
              name: yup.string().min(2, t('Minimum 2 characters')).max(20, t('Maximum 20 characters')).required(t('Required')),
            })}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              setSubmitting(true)
              postBillingAccountWorkspaceServices(billingAccountId, workspaceId, values)
                .then(data => {
                  if (data && data.id) {
                    history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms/${data.id}`)
                    setNotification({text: t('Updated 🎉'), status: 'success'})
                    setSubmitting(false)
                  } else {
                    setNotification({text: t('Failed to create'), status: 'error'})
                    setSubmitting(false)
                  }
                }).catch(error => {
                  setNotification({text: t('Failed to create'), status: 'error'})
                  setSubmitting(false)
                })
            }}
            >
            {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
              <Form>

                <div className="lvl-input">
                  <label htmlFor="name">{t('Name')}</label>
                  <Field type="text" id="name" name="name" />
                  <ErrorMessage name="name" component="div" className="validation-message" />
                </div>

                {product &&
                  <RenderProductPrices productId={product.id} />
                }

                <div className="lvl-button-group">
                  <button type="button" onClick={e => history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms`)}>{t('Cancel')}</button>
                  <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Create')}</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesSystemSMSAdd
