import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import _ from 'lodash'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import NoServices from '../../components/misc-ui-components/no-services'

const BillingAccountsWorkspacesVPSRHosting = () => {
  const [ products, setProducts ] = useState([])
  const [ services, setServices ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const history = useHistory()
  const { t } = useTranslation()
  const { getProductsGroupsProducts, getBillingAccountWorkspaceServices } =  useLevelupSDK()
  const { billingAccountId, workspaceId } = useParams()

  // HARD CODED STRING!
  const VPSR_PRODUCTS_GROUPS_ID = 'e517a16e-907a-40c5-b9e0-43022190eecf'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(VPSR_PRODUCTS_GROUPS_ID)
      .then(products => {
        setProducts(products)
        Promise.all(products.map(product => getBillingAccountWorkspaceServices(billingAccountId, workspaceId, `?productId=${product.id}`)))
          .then(res => {
            const combinedServices = Array.prototype.concat.apply([], res)
            setServices(combinedServices)
            setLoading(false)
          }).catch(e => console.error(e))
      })
      .catch(error => console.error(error))
  }, [billingAccountId, workspaceId, getProductsGroupsProducts, getBillingAccountWorkspaceServices])

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('VPSR Hosting')}</h1>
        </div>

        <div className="lvl-page__body">
          {loading && t('Loading...')}

          {!loading && services.length > 0 &&
            <Table
              headArray={[
                t('Name'),
                t('Product'),
                t('Actions')
              ]}
              rows={_.orderBy(services, [service => service.name.toLowerCase()], ['asc']).map(service => {
                const product = products.find(p => p.id === service.productId)

                return [
                  <Link to={`/system-sms/${service.id}`}>{service.name}</Link>,
                  product.name || 'Laster...',
                  t('Edit')
                ]
              })} />
          }

          {!loading && services.length === 0 &&
            <NoServices
              productName={t('VPSR hosting')}
              />
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesVPSRHosting
