import React, { Fragment, useState, useEffect, useCallback } from "react"
import { Link, useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'

import { useAuth0 } from "../../react-auth0-spa"
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'
import { useNotifications } from '../../notifications'

const Team = () => {
  const [billingAccountUsers, setBillingAccountUsers] = useState([])
  const [ loading, setLoading ] = useState(true)

  const { user } = useAuth0()
  const { getBillingAccountUsers, deleteBillingAccountUser } =  useLevelupSDK()

  const { t } = useTranslation()
  const history = useHistory()
  const { billingAccountId } = useParams()
  const { setNotification } = useNotifications()


  const refresh = useCallback(() => {
    getBillingAccountUsers(billingAccountId)
      .then(users => {
        setBillingAccountUsers(users.map(u => {
          const newUser = Object.assign({}, u)
          if (!newUser.name) newUser.name = t('Unknown')

          return newUser
        }))
        setLoading(false)
      }).catch(error => console.error(error.message))

  }, [getBillingAccountUsers, billingAccountId, t])

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return

    refresh()
  }, [billingAccountId, refresh])

  const deleteUser = (e, userId) => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm('Are you sure you wish to delete this item?')) {
      deleteBillingAccountUser(billingAccountId, userId)
        .then(res => {
          if (userId === user['sub']) {
            history.push('/')
          } else {
            setNotification({text: t('Team member removed'), status: 'success'})
            refresh()
          }
        })
        .catch(e => console.error(e))
    }
  }

  return (
    <Fragment>
      <div className="lvl-page">

        <div className="lvl-page__header">
          <h1>{t('Team')}</h1>

          <div>
            <Link to={`/billing-accounts/${billingAccountId}/team/add`} className="lvl-button lvl-button--large"><i className="fa fa-user-plus"></i>{t('Invite new team member')}</Link>
          </div>
        </div>
        {loading && <ProgressIndicator />}
        {!loading &&
          <div className="lvl-page__body">
            <Table
              headArray={[
                '',
                t('Name'),
                t('Email'),
                ''
              ]}
              rows={_.orderBy(billingAccountUsers, [bau => bau.name.toLowerCase()], ['asc']).map(bau => [
                <img src={bau.picture} alt={bau.name} className="lvl-temp-profile-image" />,
                bau.name,
                bau.email,
                <button type="button" className="lvl-botton lvl-button--dangerous" onClick={e => deleteUser(e, bau['user_id'] || bau.userId)}>{t('Remove from team')}</button>
              ])} />
          </div>
        }
      </div>
    </Fragment>
  );
};

export default Team
