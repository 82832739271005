import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

const BillingAccountsWorkspacesGoogleMapsDetails = () => {
  const [ products, setProducts ] = useState([])
  const [ service, setService ] = useState({})

  const { t } = useTranslation()
  const { getProductsGroupsProducts, getBillingAccountWorkspaceService } =  useLevelupSDK()


  const { billingAccountId, workspaceId, serviceId } = useParams()

  // HARD CODED STRING!
  const GOOGLE_MAPS_PRODUCTS_GROUPS_ID = 'fc440e51-455b-4b49-b8dd-e303cf2cb917'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(GOOGLE_MAPS_PRODUCTS_GROUPS_ID)
      .then(products => setProducts(products))
      .catch(e => console.error(e))

    getBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
      .then(service => setService(service))
      .catch(e => console.error(e))

  }, [billingAccountId, workspaceId, serviceId, getProductsGroupsProducts, getBillingAccountWorkspaceService])

  // define strings
  let currentProductName = t('Could not fetch product information')
  if (products.length > 0 && service && service.productId) {
    const currentProduct = products.find(p => p.id === service.productId)
    currentProductName = currentProduct ? currentProduct.name : currentProductName
  }

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <div className="lvl-options-bar">
            <div className="lvl-options-bar__header">
              <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/google-maps`} className="lvl-button lvl-button--hollow"><i className="fa fa-arrow-left"></i>Back</Link>
              <b>{t('Google Maps')}</b>
            </div>
          </div>
        </div>

        <div className="lvl-page__header">
          <h1>{service.name}</h1>
        </div>

        <div className="lvl-page__body">
          <div>
            <h3>Service information</h3>
            <table className="lvl-table-definition-list">
              <thead></thead>
              <tbody>
                <tr>
                  <th>{t('Current product')}</th>
                  <td>{currentProductName}</td>
                </tr>
              </tbody>
            </table>
            <dl>
            </dl>
          </div>

          <div className="lvl-callout lvl-callout--info -emphasized-header">
            <h2>Deprecated product</h2>
            <div>
              <p>Dear customer,</p>
              <p>Google Maps is a depracated product. It means that we dont have any plans to improve it. We will continue to serve and do basic maintenance for your comfort. </p>
              <p>You will be noticed when the date for discontinuation is set.</p>
            </div>
          </div>

        </div>


      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesGoogleMapsDetails
