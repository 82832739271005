import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import _ from 'lodash'
import validateUUID from 'uuid-validate'

import cPanelWhmLogo from '../../assets/cpanel-whm-logo.png'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import Table from '../../components/table'
import ContactUs from '../../components/misc-ui-components/contact-us'
import ProviderExcerpt from '../../components/misc-ui-components/provider-excerpt'
import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

import { prettyPrice } from '../../utils/pretty-prices'

const RenderProductPrices = ({productId}) => {
  const [ productPrice, setProductPrice] = useState({})

  const { t } = useTranslation()
  const { getProductPrice } =  useLevelupSDK()

  useEffect(() => {
    if (!productId) return 

    getProductPrice(productId)
    .then(r => setProductPrice(r))
    .catch(e => console.error(e))
  }, [productId])

  return (
    <div className="lvl-callout">
      <h3>{t('Estimated price')}</h3>
      <p>{t('Price per hour')}: {prettyPrice(productPrice.pricePerHour, 'NOK')}<br />
        {t('Estimated monthly price')}: {prettyPrice(productPrice.pricePerHour * 730.484398, 'NOK')}<br /><br />
        <small><i>* {t('Does not include any discounts')}.</i></small>
      </p>
    </div>
  )
}

const BillingAccountsWorkspacesSharedHostingDetails = () => {
  const [ loading, setLoading ] = useState(false)
  const [ products, setProducts ] = useState([])
  const [ service, setService ] = useState({})
  const [ sharedHostingDetails, setSharedHostingDetails ] = useState({})
  const [ newPassword, setNewPassword ] = useState('')
  const [ passwordError, setPasswordError ] = useState('')
  const [ passwordStrength, setPasswordStrength ] = useState(0)
  const [ newProductId, setNewProductId ] = useState()
  const delayedRequest = useRef(_.debounce(p => checkPasswordStrength(p), 500)).current;


  const history = useHistory()
  const { t } = useTranslation()
  const {
    getProductsGroupsProducts,
    getBillingAccountWorkspaceService,
    getBillingAccountWorkspaceSharedHosting,
    deleteBillingAccountWorkspaceSharedHosting,
    postBillingAccountWorkspaceSharedHostingSetPassword,
    postBillingAccountWorkspaceSharedHostingGetPasswordStrength,
    postBillingAccountWorkspaceShardHostingChangePackage
   } =  useLevelupSDK()

  const { setNotification } = useNotifications()


  const { billingAccountId, workspaceId, serviceId } = useParams()

  // HARD CODED STRING!
  const SHARED_HOSTING_PRODUCTS_GROUPS_ID = '6c0475bc-7a79-43bf-a39d-47143956fb74'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(SHARED_HOSTING_PRODUCTS_GROUPS_ID)
      .then(products => setProducts(products))
      .catch(error => console.error(error))

    getBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
      .then(service => {
        setService(service)
        setNewProductId(service.productId)
      })
      .catch(error => console.error(error))

  }, [billingAccountId, workspaceId, serviceId, getProductsGroupsProducts, getBillingAccountWorkspaceService])

  useEffect(() => {
    if (!billingAccountId || !workspaceId || !serviceId) return

    getBillingAccountWorkspaceSharedHosting(billingAccountId, workspaceId, serviceId)
      .then(res => setSharedHostingDetails(res))
      .catch(e => console.error(e))
  }, [service])

  // define strings
  let currentProductName = t('Could not fetch product information')
  if (products.length > 0 && service && service.productId) {
    const currentProduct = products.find(p => p.id === service.productId)
    currentProductName = currentProduct.name || currentProductName
  }

  const deleteService = (e) => {
    e.preventDefault()
    setLoading(true)

    if (window.confirm('Are you sure you wish to delete this item?')) {
      deleteBillingAccountWorkspaceSharedHosting(billingAccountId, workspaceId, serviceId)
        .then(res => {
          setLoading(false)
          history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting`)
        })
        .catch(e => {
          setLoading(false)
          alert('Failed to delete')
        })
    }
  }

  useEffect(() => {
    if (newPassword === '') return

    delayedRequest(newPassword)

  }, [newPassword])

  const checkPasswordStrength = (p) => {
    postBillingAccountWorkspaceSharedHostingGetPasswordStrength(billingAccountId, workspaceId, serviceId, {password: p})
      .then(res => {
        if (res.metadata.result === 1){
          setPasswordStrength(res.data.strength)
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  const setPassword = (e) => {
    e.preventDefault()
    setPasswordError('')

    postBillingAccountWorkspaceSharedHostingSetPassword(billingAccountId, workspaceId, serviceId, {password: newPassword})
      .then(res => {
        if (res.metadata && res.metadata.result === 0) {
          setPasswordError(res.metadata.reason)
        } else if (res.metadata && res.metadata.result === 1) {
          setNotification({text: t('Password updated 🎉'), status: 'success'})
        }
      })
      .catch(e => {
        console.error('set password error: ', e)
      })
  }

  const changePackage = (e) => {
    e.preventDefault()

    postBillingAccountWorkspaceShardHostingChangePackage(billingAccountId, workspaceId, serviceId, {productId: newProductId})
      .then(res => {
        if (res.metadata && res.metadata.result === 1) {
          window.location.href = window.location.href
        } else {
          setNotification({text: t('Failed. Please try again or contact support'), status: 'error'})
        }
      })
      .catch(e => console.error(e))
  }

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
            header={t('Hosting')}
            backLink={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting`}
            options={[<button className="lvl-button lvl-button--dangerous" onClick={e => deleteService(e)}><i className="fa fa-trash-o"></i>{t('Delete')}</button>]}
          />
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__header">
          <h1>{service.name}</h1>
          <ProviderExcerpt logoHtml={<img src={cPanelWhmLogo} />} />
        </div>


        <div className="lvl-page__body">
          <div>
            <h2>{t('cPanel details')}</h2>
            <table className="lvl-table-definition-list">
              <thead></thead>
              <tbody>
                <tr>
                  <th>{t('Username')}</th>
                  <td>{sharedHostingDetails.user}</td>
                </tr>
                <tr>
                  <th>{t('Domain')}</th>
                  <td>{sharedHostingDetails.domain}</td>
                </tr>
                <tr>
                  <th>{t('External IP')}</th>
                  <td>{sharedHostingDetails.ip}</td>
                </tr>
                <tr>
                  <th>{t('Account email')}</th>
                  <td>{sharedHostingDetails.email}</td>
                </tr>
                <tr>
                  <th>{t('Plan')}</th>
                  <td>{sharedHostingDetails.plan}</td>
                </tr>
                <tr>
                  <th>{t('Login URL')}</th>
                  <td><a href={`${sharedHostingDetails.host}:2083`} target="_bank">{sharedHostingDetails.host}:2083</a></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <h2>{t('Set new password')}</h2>
            <div className="lvl-input">
              <label htmlFor="password">{t('Password')}</label>
              <input type="password" id="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
              <div>{t('Password strength')}: {passwordStrength}. {t('Must be greater than 65')}</div>
              {passwordError && <div><span className="validation-message" style={{color: 'red'}}>{passwordError}</span></div>}
            </div>

            <div><button onClick={e => setPassword(e)}>{t('Update')}</button></div>
          </div>


          <div>
            <h2>{t('Change plan')}</h2>
            <div className="lvl-input">
              <label htmlFor="plan">{t('Plan')}</label>
              <div className="lvl-input--select">
                <select value={newProductId} onChange={e => setNewProductId(e.target.value)}>
                  {products.map(p => <option value={p.id}>{p.name}</option>)}
                </select>
              </div>
            </div>

            {newProductId &&
              <RenderProductPrices productId={newProductId} />
            }

            <div><button onClick={e => changePackage(e)}>{t('Update')}</button></div>
          </div>

          <div>
            <ContactUs preText={t('Want to make changes?')} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesSharedHostingDetails
