import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import forwardMxLogo from '../../assets/forwardmx-logo.png'

import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'
import ContactUs from '../../components/misc-ui-components/contact-us'


const BillingAccountsWorkspacesDomainDetails = () => {
  const [ products, setProducts ] = useState([])
  const [ service, setService ] = useState({})
  const { t } = useTranslation()
  const {
    getProductsGroupsProducts,
    getBillingAccountWorkspaceService,
  } =  useLevelupSDK()


  const { billingAccountId, workspaceId, serviceId } = useParams()

  const { setNotification } = useNotifications()


  // HARD CODED STRING!
  const DOMAIN_PRODUCT_GROUP = '2c4f9139-642c-4554-a635-0aade7df1233'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts(DOMAIN_PRODUCT_GROUP)
      .then(products => setProducts(products))
      .catch(e => console.error(e))

    getBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
      .then(service => setService(service))
      .catch(e => console.error(e))

  }, [billingAccountId, workspaceId, serviceId, getProductsGroupsProducts, getBillingAccountWorkspaceService])


  // define strings
  let currentProductName = t('Could not fetch product information')
  if (products.length > 0 && service && service.productId) {
    const currentProduct = products.find(p => p.id === service.productId)
    currentProductName = currentProduct ? currentProduct.name : currentProductName
  }

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
            header={t(currentProductName)}
            backLink={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/domains`}
          />
        </div>

        <div className="lvl-page__header">
          <h1>{service.name}</h1>
          {currentProductName === 'MX Manipulation' && <img style={{display: 'inline', width: '80px'}} src={forwardMxLogo} />}
        </div>

        <div className="lvl-page__body">

          {service && service.details && service.details['name-servers'] &&
            <div>
              <h3>{t('Name Servers')}</h3>
              <table className="lvl-table-definition-list">
                <thead></thead>
                <tbody>
                  {service.details['name-servers'].map(val => (
                    <tr>
                      <td>{val}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }

          {service.details && service.details['mx-mapping'] &&
            <div>
              <h3>{t('MX Mapping')}</h3>
              <table className="lvl-table-definition-list">
                <thead>
                  <tr>
                    <th>{t('Source')}</th>
                    <th>{t('Destination')}</th>
                  </tr>
                </thead>
                <tbody>
                  {service.details['mx-mapping'].map(mapping => {
                    if (!mapping.source || !mapping.destination) return <tr></tr>
                    return (
                      <tr>
                        <td>{mapping.source}</td>
                        <td>{mapping.destination}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          }

          <div>
            <ContactUs preText={t('Do you want any of the values above changed or deleted?')} />
          </div>
        </div>


      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesDomainDetails
