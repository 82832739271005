import React, { Fragment, useState, useEffect } from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import { useAuth0 } from "../../react-auth0-spa"
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import { useTranslation } from 'react-i18next'
import './index.scss'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

const Profile = () => {
  const [ userDetails, setUserDetails ] = useState()
  const [ isSubmitting, setIsSubmitting ] = useState()


  const { t } = useTranslation()
  const { loading, isAuthenticated } = useAuth0()
  const { getMe, updateMe } =  useLevelupSDK()
  const { setNotification } = useNotifications()

  useEffect(() => {
    if (!isAuthenticated) return

    getMe()
      .then(user => {
        setUserDetails(Object.assign({}, {user_metadata: { language: 'en'}}, user))
      })
      .catch(e => console.error(e))

  }, [isAuthenticated])

  return (
    <div className="lvl-page">
      <div className="lvl-page__header">
        <h1>{t('Profile')}</h1>
      </div>
      {(loading || !userDetails) &&
        <ProgressIndicator />
      }
      {(!loading && userDetails) &&
         <div className="lvl-page__body">
          <div className="lvl-profile">
            <div className="lvl-profile__header">
              <img src={userDetails.picture} alt="Profile" />
            </div>

            <div className="lvl-profile__body">
              <Formik
                initialValues={{
                  name: userDetails.name || '', 
                  email: userDetails.email || '', 
                  language: userDetails && userDetails['user_metadata'] && userDetails['user_metadata'].language || 'en'
                }}
                validationSchema={yup.object({
                  name: yup.string().min(2, t('Minimum length of 2 characters')).required(t('Required')),
                  email: yup.string().email().required(t('Required')),
                  language: yup.string().required(t('Required')),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  // first, make the object to post ready
                  const user_metadata = Object.assign({}, userDetails.user_metadata)
                  user_metadata.language = values.language

                  updateMe({name: values.name, user_metadata: user_metadata})
                    .then(user => {
                      setUserDetails(user)
                      setNotification({text: t('Updated 🎉'), status: 'success'})
                      setSubmitting(false)
                      // dirty reload because we can change language from here...
                      window.location.href = window.location.href
                    })
                    .catch(error => {
                      setNotification({text: t('Failed to update'), status: 'error'})
                    })
                }}
                >
                <Form>
                  <div className="lvl-input">
                    <label htmlFor="name">{t('Name')}</label>
                    <Field type="text" id="name" name="name" />
                    <ErrorMessage name="name" component="div" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="email">{t('Email')} ({userDetails['email_verified'] ? t('Verified') : t('Not verified')})</label>
                    <Field type="email" id="email" name="email" readOnly disabled />
                    <ErrorMessage name="email" component="div" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="language">{t('Language')}</label>

                    <div className="lvl-input--select">
                      <Field as="select" id="language" name="language">
                        <option value="en">{t('English')}</option>
                        <option value="nb">{t('Norwegian')}</option>
                      </Field>
                    </div>

                    <ErrorMessage name="language" component="div" />
                  </div>

                  <div>
                    <button type="submit" disabled={isSubmitting}>
                      {t('Update')}
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Profile
