import React, { Fragment } from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import Table from '../../components/table'

import { StatusLabel } from '../../components/misc-ui-components/labels'
import { DropdownNav } from '../../components/misc-ui-components/dropdown-nav'
import { SelectorList } from '../../components/misc-ui-components/selector-list'
import InfoCard from '../../components/misc-ui-components/info-card'
import { LoadingPage, ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'
import { useNotifications } from '../../notifications'
import { Modal } from '../../components/misc-ui-components/modal'

import './index.scss'

const ComponentsOverview = () => {
  const { t } = useTranslation()
  const { setNotification } = useNotifications()

  let items = [
    {id: 35, item: 'jumper', default: false},
    {id: 42, item: 'shirt', default: true},
    {id: 71, item: 'socks'},
  ]

  // Selector list
  let sel_list_items = [
    {id: 35, label: 'Merkantilbygg AS', link: 'https://vg.no'},
    {id: 42, label: 'Prime Executive AS', link: 'https://db.no'},
  ]


  return (
    <div className="lvl-page lvl-page--components-overview">


      <Modal content={
        <Fragment>
          <h1 className="demo">{t("We'd love to help you")}</h1>
          <form>
            <p>{t("Please try to include all details we need to complete your request.")}</p>
            <textarea rows="4" cols="50" placeholder={t("Your message...")}></textarea>
            <button type="submit">{t("Send")}</button>
          </form>

          <div className="lvl-block">
            <span className="demo">{t("Prefer to call?")}</span>
            <h2 className="demo">{t("Reach out on +47 333 22 122 🤙")}</h2>
          </div>

          <div className="lvl-block">
            <span className="demo">{t("Or maybe email is your thing?")}</span>
            <h2 className="demo">{t("Hit us at hei@levelup.no ✍️")}</h2>
          </div>

        </Fragment>
      } />

      <div className="lvl-page__header">
        <h1>Comonents overview</h1>

        <div>
          <a className="lvl-button">Button to the right!</a>
        </div>
      </div>

      <div className="lvl-page__body">
        <div className="comp-out">

            <hr/>
            <h1>Selector list</h1>
            <p>.lvl-selector-list - Here also wrapped around with .h-layout-container-narrow for demonstration purposes</p>

            <div className="h-layout-container-narrow">
              <SelectorList items={sel_list_items} />
            </div>

            <hr/>
            <h1>Collapisble list</h1>
            <p>INSTRUCTIONS</p>


            { /* 1 */ }
            <hr/>
            <h1>Typography</h1>
            <h2>Headings</h2>
            <h1 className="demo">This is h1</h1>
            <h2>This is h2</h2>
            <h3>This is h3</h3>
            <p>We do not use h4!</p>

            { /* 1 */ }
            <hr/>
            <h1>UI elements</h1>


            <h2>Status labels</h2>
            <p>success, error, warning</p>
            <StatusLabel type="success" text="Test label" />
            <StatusLabel type="error" text="Test label" />
            <StatusLabel type="warning" text="Test label" />

            <h2>Buttons</h2>


            <h3>As a-tag</h3>
            <p>Large</p>
            <p>.lvl-button--large</p>
            <div className="lvl-button-group">
            <a className="lvl-button lvl-button--large">Normal</a>
            <a className="lvl-button lvl-button--large lvl-button--proceed">Proceed</a>
            <a className="lvl-button lvl-button--large lvl-button--dangerous">Dangerous</a>
            </div>

            <p>Normal</p>
            <div className="lvl-button-group">
            <a className="lvl-button">Normal</a>
            <a className="lvl-button lvl-button--proceed">Proceed</a>
            <a className="lvl-button lvl-button--dangerous">Dangerous</a>
            </div>

            <p>Small</p>
            <p>.lvl-button--small</p>
            <div className="lvl-button-group">
            <a className="lvl-button lvl-button--small">Normal</a>
            <a className="lvl-button lvl-button--small lvl-button--proceed">Proceed</a>
            <a className="lvl-button lvl-button--small lvl-button--dangerous">Dangerous</a>
            </div>

            <h3>As button-tag</h3>
            <h3>Remember to always add the attribute type="button"</h3>
            <div className="lvl-button-group">
            <button type="button" className="lvl-button">Normal</button>
            <button type="button" className="lvl-button lvl-button--proceed">Proceed</button>
            <button type="button" className="lvl-button lvl-button--dangerous">Dangerous</button>
            </div>

            <div className="lvl-button-group">
              <button type="button" className="lvl-button lvl-button--small">Normal</button>
              <button type="button" className="lvl-button lvl-button--small lvl-button--proceed">Proceed</button>
              <button type="button" className="lvl-button lvl-button--small lvl-button--dangerous">Dangerous</button>
            </div>

            <p>Disabled button tags</p>
            <div className="lvl-button-group">
              <button type="button" className="lvl-button" disabled>Normal</button>
              <button type="button" className="lvl-button lvl-button--proceed" disabled>Proceed</button>
              <button type="button" className="lvl-button lvl-button--dangerous" disabled>Dangerous</button>
            </div>

            <h3>Button groups</h3>
            <p>please add .lvl-button-group to the parent container if using more than one button</p>
            <div className="lvl-button-group">
              <a className="lvl-button">Normal</a>
              <a className="lvl-button lvl-button--proceed">Proceed</a>
              <a className="lvl-button lvl-button--dangerous">Dangerous</a>
            </div>



            { /* 1 */ }
            <hr/>
            <h1>Cards</h1>

            <h2>Info card</h2>
            <InfoCard heading="Team"
                        body="Antall team members: {workspaceUsers.length}"
                        // ctaOnClick={e => history.push('monitoring/add')}
                        ctaText="Test" />

            <hr/>
            <h1>Tables</h1>
            <h2>Tables definition list</h2>
            <p>Add class .lvl-table-definition-list to the table-element</p>
            <table className="lvl-table-definition-list">
              <thead></thead>
              <tbody>
                <tr>
                  <th>Label</th>
                  <td>Data</td>
                </tr>
                <tr>
                  <th>Label</th>
                  <td>Longer data</td>
                </tr>
                <tr>
                  <th>Label</th>
                  <td>Data</td>
                </tr>
              </tbody>
            </table>

            <p>--positive</p>
            <table className="lvl-table-definition-list lvl-table-definition-list--positive">
              <thead></thead>
              <tbody>
                <tr>
                  <th>Label</th>
                  <td>Data</td>
                </tr>
                <tr>
                  <th>Label</th>
                  <td>Longer data</td>
                </tr>
                <tr>
                  <th>Label</th>
                  <td>Data</td>
                </tr>
              </tbody>
            </table>

            <p>--info</p>
            <table className="lvl-table-definition-list lvl-table-definition-list--info">
              <thead></thead>
              <tbody>
                <tr>
                  <th>Label</th>
                  <td>Data</td>
                </tr>
                <tr>
                  <th>Label</th>
                  <td>Longer data</td>
                </tr>
                <tr>
                  <th>Label</th>
                  <td>Data</td>
                </tr>
              </tbody>
            </table>

            <h2>Tables</h2>
            <Table
              headArray={[
                '',
                'Name',
                'Email',
                'Label',
                ''
              ]}
              rows={[
                [
                  'test1',
                  'Test',
                  'test@test.com',
                  <StatusLabel type="success" text="Test label" />,
                  <button>Remove from team</button>
                ],
                [
                  'row2',
                  'Test',
                  'test@test.com',
                  <StatusLabel type="success" text="Test label" />,
                  <button>Remove from team</button>
                ]
              ]} />

            <hr/>
            <h1>Forms</h1>
            <h2>Inline input</h2>
            <p>Class added: lvl-input-inline to parent container</p>
            <p>Please note, these should not be used more than 1 at the time because the labels width are not aligned</p>
            <div className="lvl-input-inline">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" />
            </div>

            <h2>Default input</h2>
            <p>Class added: lvl-input to parent container</p>
            <div className="lvl-input">
              <label htmlFor="last_name">Last name</label>
              <input type="text" id="last_name" />
            </div>

            <p>Class added: lvl-input and lvl-input--select to parent container</p>
            <div className="lvl-input lvl-input--select">
              <label htmlFor="select">Select</label>
              <select id="select">
                <option value="1">Velg</option>
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
              </select>
            </div>

            <p>Custom checkbox</p>
            <p>Please inspect the markup</p>
            <label className="lvl-input-checkbox">
              Checkbox label
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>

            <hr/>
            <h1>Progress Indicators</h1>
            <h2>LoadingPage</h2>
            <LoadingPage />

            <h2>ProgressIndicator</h2>
            <div style={{position: 'relative'}}>
            <ProgressIndicator />
            </div>

            <hr/>
            <h1>Selectable list</h1>
            <p>.lvl-selectable-list</p>
            <ul className="lvl-selectable-list">
              <li className="-selected">1 day</li>
              <li>2 day</li>
              <li>3 day</li>
            </ul>

            <hr/>
            <h1>Callouts</h1>
            <p>.lvl-callout</p>
            <div className="lvl-callout">
              <h2>Normal Callout</h2>
              <p>Callout. Set your own content :)</p>
            </div>

            <p>.lvl-callout lvl-callout--danger</p>
            <div className="lvl-callout lvl-callout--danger">
              <h2>Danger Callout</h2>
              <p>Callout. Set your own content :)</p>
            </div>

            <p>.lvl-callout lvl-callout--info</p>
            <div className="lvl-callout lvl-callout--info">
              <h2>Info Callout</h2>
              <p>Callout. Set your own content :)</p>
            </div>

            <p>.lvl-callout lvl-callout--positive</p>
            <div className="lvl-callout lvl-callout--positive">
              <h2>Positive Callout</h2>
              <p>Callout. Set your own content :)</p>
            </div>

            <p>.lvl-callout .-emphasized-header</p>
            <p>Please inspect the markup to see how this shold be in this case. There are a few requirements</p>
            <div className="lvl-callout -emphasized-header">
              <h2>Normal Callout</h2>
              <div>
                <p>Callout with .-emphasized-header</p>
              </div>
            </div>

            <p>.lvl-callout lvl-callout--danger .-emphasized-header</p>
            <p>Please inspect the markup to see how this shold be in this case. There are a few requirements</p>
            <div className="lvl-callout lvl-callout--danger -emphasized-header">
              <h2>Danger Callout</h2>
              <div>
                <p>Callout with .-emphasized-header</p>
              </div>
            </div>

            <p>.lvl-callout lvl-callout--info .-emphasized-header</p>
            <p>Please inspect the markup to see how this shold be in this case. There are a few requirements</p>
            <div className="lvl-callout lvl-callout--info -emphasized-header">
              <h2>Info Callout</h2>
              <div>
                <p>Callout with .-emphasized-header</p>
              </div>
            </div>

            <p>.lvl-callout lvl-callout--positive .-emphasized-header</p>
            <p>Please inspect the markup to see how this shold be in this case. There are a few requirements</p>
            <div className="lvl-callout lvl-callout--positive -emphasized-header">
              <h2>Positive Callout</h2>
              <div>
                <p>Callout with .-emphasized-header</p>
              </div>
            </div>

            <hr/>
            <h1>Option bar</h1>
            <p>.lvl-option-bar</p>
            <div className="lvl-options-bar">
              <div className="lvl-options-bar__header">
                <Link to="/monitoring" className="lvl-button lvl-button--hollow"><i className="fa fa-arrow-left"></i>Back</Link>
                <b>{t('Monitoring')}</b>
              </div>
              <div className="lvl-options-bar__body">
                <Link className="lvl-button" to={`/#`}><i className="fa fa-pencil"></i>{t('Edit')}</Link>
                <button onClick={e => e => console.log('click')}><i className="fa fa-sync-alt"></i>{t('Refresh')}</button>
                <button className="lvl-button lvl-button--dangerous"><i className="fa fa-trash-o"></i>{t('Delete')} </button>
              </div>
            </div>

            <hr/>
            <h1>Navigation</h1>
            <p>INSTRUCTIONS</p>
            <DropdownNav items={items} />

            <hr/>
            <h1>User notification</h1>
            <p>INSTRUCTIONS</p>
            <button type="button" onClick={ e => setNotification({text: t('Updated 🎉'), status: 'success'}) } >Run Success</button>
            <button type="button" onClick={ e => setNotification({text: 'Error ', status: 'error'}) } >Run Error</button>
            <button type="button" onClick={ e => setNotification({text: 'Warning', status: 'warning'}) } >Run Warning</button>

            <hr/>
            <h1>Markup structure</h1>
            <p>Please group sections of .lvl-page__body (immediate childrens) within an div-wrapper</p>
            <p>Example:</p>

            <code>
            &lt;div className="lvl-page"&gt;
            &lt;div&gt;
            &lt;/div&gt;
            &lt;/div&gt;
            </code>

<br/><br/><br/><br/><br/><br/>


          </div>

      </div>

    </div>
  )
}

export default ComponentsOverview
