import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import Table from '../../components/table'

import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'


import { prettyPrice } from '../../utils/pretty-prices'

const RenderProductPrices = ({productId}) => {
  const [ productPrice, setProductPrice] = useState({})

  const { t } = useTranslation()
  const { getProductPrice } =  useLevelupSDK()

  useEffect(() => {
    if (!productId) return 

    getProductPrice(productId)
    .then(r => setProductPrice(r))
    .catch(e => console.error(e))
  }, [productId])

  return (
    <div className="lvl-callout">
      <h3>{t('Estimated price')}</h3>
      <p>{t('Price per hour')}: {prettyPrice(productPrice.pricePerHour, 'NOK')}<br />
        {t('Estimated monthly price')}: {prettyPrice(productPrice.pricePerHour * 730.484398, 'NOK')}<br /><br />
        <small><i>* {t('Does not include any discounts')}.</i></small>
      </p>
    </div>
  )
}

const BillingAccountsWorkspacesSharedHostingAdd = () => {
  const [ loading, setLoading ] = useState(false)
  const [ products, setProducts ] = useState([])

  const { setNotification } = useNotifications()
  const history = useHistory()
  const { t } = useTranslation()
  const {
    getProductsGroupsProducts,
    postBillingAccountWorkspaceSharedHostingVerifyNewUsername,
    postBillingAccountWorkspaceSharedHosting
  } =  useLevelupSDK()

  const { billingAccountId, workspaceId } = useParams()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts('6c0475bc-7a79-43bf-a39d-47143956fb74')
      .then(products => {
        setProducts(products)
      })
      .catch(error => {
         setNotification({text: t('Failed'), status: 'error'})
         history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting`)
      })
  }, [billingAccountId, workspaceId, getProductsGroupsProducts])


  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
            header={t('Add New Hosting')}
            backLink={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting`}
          />
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__header">
          <h1>{t('Add New Hosting')}</h1>
        </div>

        <div className="lvl-page__body">
          {products && products.length > 0 && 
            <Formik
              initialValues={{
                username: '',
                domain: '',
                productId: products[0].id
              }}
              validationSchema={yup.object({
                username: yup.string().matches(/[a-zA-Z]/, t('Only use letters and no spaces')).min(2, t('Minimum 2 characters')).max(8, t('Max 8 characters')).max(20, t('Maximum 20 characters')).required(t('Required')),
                domain: yup.string().matches(/[a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/, t('Please provide a valid domain name')).min(3, t('Minimum 3 characters')).required(t('Required'))
              })}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(true)
                setLoading(true)
                postBillingAccountWorkspaceSharedHostingVerifyNewUsername(billingAccountId, workspaceId, {username: values.username})
                  .then(res => {
                    if (res.metadata && res.metadata.result === 0) {
                      // it failed
                      setErrors({username: res.metadata.reason})
                      setLoading(false)
                      setSubmitting(false)
                    } else {
                      // Create the account
                      postBillingAccountWorkspaceSharedHosting(billingAccountId, workspaceId, values)
                        .then(res => {
                          setLoading(false)
                          if (res.metadata && res.metadata.result === 0) {
                            setNotification({text: `${t('Failed')} - ${res.metadata.reason}`, status: 'error'})
                          } else if (res.id) {
                            // success
                            setSubmitting(false)
                            setNotification({text: t('Created 🎉'), status: 'success'})
                            history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/${res.id}`)
                          }
                        })
                        .catch(e => {
                           setLoading(false)
                           setSubmitting(false)
                           setNotification({text: t('Failed'), status: 'error'})
                        })
                    }
                  })
                  .catch(e => {
                     setLoading(false)
                     setSubmitting(false)
                     setNotification({text: t('Failed'), status: 'error'})
                  })
              }}
              >
              {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
                <Form>

                  <div className="lvl-input">
                    <label htmlFor="username">{t('Username')}</label>
                    <Field type="text" id="username" name="username" />
                    <ErrorMessage name="username" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-input">
                    <label htmlFor="domain">{t('Domain')}</label>
                    <Field type="text" id="domain" name="domain" />
                    <ErrorMessage name="domain" component="div" className="validation-message" />
                  </div>

                  <div className="lvl-input lvl-input--select">
                    <label htmlFor="productId">{t('Preferred contact language')}</label>
                    <Field as="select" id="productId" name="productId">
                      {products.map(p => <option value={p.id}>{p.name}</option>)}
                    </Field>
                  </div>

                  {values.productId &&
                    <RenderProductPrices productId={values.productId} />
                  }

                  <div className="lvl-button-group">
                    <button type="button" onClick={e => history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting`)}>{t('Cancel')}</button>
                    <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Create')}</button>
                  </div>
                </Form>
              )}
            </Formik>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesSharedHostingAdd
