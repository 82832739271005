import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'


const DNSRecords = ({ billingAccountId, workspaceId, serviceId, systemEmailDetails, setSystemEmailDetails }) => {
  const { putBillingAccountWorkspaceSystemEmailVerify } =  useLevelupSDK()

  const { t } = useTranslation()
  const { setNotification } = useNotifications()

  const verify = (e) => {
    putBillingAccountWorkspaceSystemEmailVerify(billingAccountId, workspaceId, serviceId)
      .then(res => {
        setSystemEmailDetails(res)
        setNotification({text: t('DNS checked 🎉'), status: 'success'})
      })
      .catch(e => {
        setNotification({text: t('Failed to check DNS'), status: 'error'})
      })
  }

  return (
    <div>
      <div>
        <h2>{t('DNS Records')}</h2>
        <button className="lvl-button" onClick={e => verify()}>Check DNS Records</button>
      </div>

      {systemEmailDetails && systemEmailDetails.receiving_dns_records &&
        <div>
          <h3>{t('DNS Records to receiving emails')}</h3>
          <table className="lvl-table-definition-list">
            <thead>
              <tr>
                <th>Valid</th>
                <th>Type</th>
                <th>Hostname</th>
                <th>Priority</th>
                <th>Enter this value</th>
                <th>Current value</th>
              </tr>
            </thead>
            <tbody>
              {systemEmailDetails.receiving_dns_records.map((record, i) => (
                <tr key={`dns-record-${i}`}>
                  <td>{record.valid}</td>
                  <td>{record.record_type}</td>
                  <td>{record.name}</td>
                  <td>{record.priority}</td>
                  <td><pre>{record.value}</pre></td>
                  <td>
                    {record.cached.map(val => <span style={{backgrund: 'whitemsoke', marginRight: 10, padding: 5}}>{val}</span>)}
                    {record.cached.length === 0 && <span>{t('Not found')}</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }


      {systemEmailDetails && systemEmailDetails.sending_dns_records &&
        <div>
          <h3>{t('DNS Records to sending emails')}</h3>
          <table className="lvl-table-definition-list">
            <thead>
              <tr>
                <th>Valid</th>
                <th>Type</th>
                <th>Hostname</th>
                <th>Priority</th>
                <th>Enter this value</th>
                <th>Current value</th>
              </tr>
            </thead>
            <tbody>
              {systemEmailDetails.sending_dns_records.map((record, i) => (
                <tr key={`sending-dns-records-${i}`}>
                  <td>{record.valid}</td>
                  <td>{record.record_type}</td>
                  <td>{record.name}</td>
                  <td>{record.priority}</td>
                  <td>{record.value}</td>
                  <td>
                    {record.cached.map(val => <span style={{backgrund: 'whitemsoke', marginRight: 10, padding: 5}}>{val}</span>)}
                    {record.cached.length === 0 && <span>{t('Not found')}</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </div>
  )
}

export default DNSRecords
