import React, { useContext, useState } from "react"

export const NotificationsContext = React.createContext()
export const useNotifications = () => useContext(NotificationsContext)
export const NotificationsProvider = ({
  children,
  ...initOptions
}) => {
  const defaultValues = {
    active: false,
    text: '',
    status: 'success'
  }
  const [ notification, setNotification] = useState(defaultValues)

  return (
    <NotificationsContext.Provider
      value={{
        notification: notification,
        setNotification: ({text, status}) => {
          console.log('trigger notification', text, status)
          setNotification({
            active: true,
            text: text,
            status: status
          })
        },
        destroyNotification: () => {
          setNotification(defaultValues)
        }
      }}>
      {children}
    </NotificationsContext.Provider>
  )
}
