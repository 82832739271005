import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'


import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

const WorkspaceAdd = () => {
  const { postBillingAccountUsers } =  useLevelupSDK()
  const { setNotification } = useNotifications()
  const { t } = useTranslation()
  const history = useHistory()
  const { billingAccountId } = useParams()


  return (
    <Fragment>
      <div className="lvl-page">

        <div className="lvl-page__header">
          <h1>{t('Invite new team member')}</h1>
        </div>

        <div className="lvl-page__body">
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={yup.object({
              email: yup.string().email(t('Email must be valid')).required(t('Required')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              postBillingAccountUsers(billingAccountId, values)
                .then(user => {
                  history.push(`/billing-accounts/${billingAccountId}/team`)
                  setNotification({text: t('Invitation sent 🎉'), status: 'success'})
                  setSubmitting(false)
                })
                .catch(e => {
                  setNotification({text: t('Failed to invite'), status: 'error'})
                  setSubmitting(false)
                })
            }}
            >
            {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
              <Form>

                <div className="lvl-input">
                  <label htmlFor="email">{t('Email')}</label>
                  <Field type="email" id="email" name="email" />
                  <ErrorMessage name="email" component="div" className="validation-message" />
                </div>

                <div className="lvl-button-group">
                  <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Invite')}</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Fragment>
  )
}

export default WorkspaceAdd
