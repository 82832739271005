import React, { useContext, useState } from "react"
import axios from 'axios'

import config from './config'

import { useAuth0 } from "./react-auth0-spa"

import { ProgressIndicator } from './components/misc-ui-components/progress-indicators'

export const LevelupSDKContext = React.createContext()
export const useLevelupSDK = () => useContext(LevelupSDKContext)
export const LevelupSDKProvider = ({
  children,
  ...initOptions
}) => {
  const { getTokenSilently } = useAuth0()

  const _doRequest = async ({path, method, data, queryString, allowedFields}) => {
    // Get the auth token
    const token = await getTokenSilently()

    // start building the axios object
    const axiosObj = {
      method: method,
      url: `${config.apiUrl}${path}`,
      headers: { Authorization: `Bearer ${token}` }
    }

    // if data is defined, then add it to the object
    if (data) {
      if (allowedFields) {
        // make sure to only apply the allowed fields
        axiosObj.data = {}
        allowedFields.forEach(key => axiosObj.data[key] = data[key])
      } else {
        axiosObj.data = data
      }
    }

    // if the queryStirng is defined, then add it to the url
    if (queryString) {
      axiosObj.url = `${axiosObj.url}${queryString}`
    }

    return await new Promise((resolve, reject) => {
      axios(axiosObj).then(res => {
        if (res.status !== 200) {
          reject(res.response)
        }

        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  return (
    <LevelupSDKContext.Provider
      value={{
        // GET - /me
        getMe: () => _doRequest({path: '/me',method: 'GET'}),
        
        // PATCH - /me
        updateMe: data => _doRequest({path: '/me', method: 'PATCH', data: data, allowedFields: ['name', 'user_metadata']}),

        // GET - /me/get-status
        getStatus: () => _doRequest({path: '/me/get-status',method: 'GET'}),

        // POST - /tickets
        postTicket: (data) => _doRequest({path: `/tickets`,method: 'POST', data: data}),

        // GET - /billing-accounts
        getBillingAccounts: () => _doRequest({path: '/billing-accounts',method: 'GET'}),

        // POST - /billing-accounts
        postBillingAccount: data => _doRequest({path: '/billing-accounts',method: 'POST', data: data}),

        // GET - /billing-accounts/:billingAccountId
        getBillingAccount: (billingAccountId) => _doRequest({path: `/billing-accounts/${billingAccountId}`,method: 'GET'}),
        
        // PATCH - /billing-accounts/:billingAccountId
        patchBillingAccount: (billingAccountId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}`, method: 'PATCH', data: data}),

        // GET - /billing-accounts/:billingAccountId/reseller-accounts
        getBillingAccountResellerAccounts: (billingAccountId) => _doRequest({path: `/billing-accounts/${billingAccountId}/reseller-accounts`,method: 'GET'}),

        // GET - /billing-accounts/:billingAccountId/reseller-accounts/:resellerAccountId/billing-reports
        getBillingAccountResellerAccountBillingReports: (billingAccountId, resellerAccountId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/reseller-accounts/${resellerAccountId}/billing-reports${queryString || ''}`,method: 'GET'}),

        // GET - /billing-accounts/:billingAccountId/services
        getBillingAccountServices: (billingAccountId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/services${queryString || ''}`,method: 'GET'}),

        // POST - /billing-accounts/:billingAccountId/services
        postBillingAccountServices: (billingAccountId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/services`,method: 'POST', data: data}),

        // GET - /billing-accounts/:billingAccountId/services
        getBillingAccountService: (billingAccountId, serviceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/services/${serviceId}`,method: 'GET'}),

        // DELETE - /billing-accounts/:billingAccountId/services
        deleteBillingAccountService: (billingAccountId, serviceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/services/${serviceId}`,method: 'DELETE'}),

        // PATCH - /billing-accounts/:billingAccountId/services
        patchBillingAccountService: (billingAccountId, serviceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/services/${serviceId}`,method: 'PATCH', data: data}),


        // GET - /billing-accounts/:billingAccountId/api-key
        getBillingAccountPrivateApiKey: billingAccountId => _doRequest({path: `/billing-accounts/${billingAccountId}/private-api-key`,method: 'GET'}),

        // GET - /billing-accounts/:billingAccountId/users
        getBillingAccountUsers: billingAccountId => _doRequest({path: `/billing-accounts/${billingAccountId}/users`,method: 'GET'}),

        // POST - /billing-accounts/:billingAccountsId/users
        postBillingAccountUsers: (billingAccountId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/users`, method: 'POST', data: data}),

        // GET - /billing-accounts/:billingAccountId/users/:userId
        getBillingAccountUser: (billingAccountId, userId) => _doRequest({path: `/billing-accounts/${billingAccountId}/users/${userId}`,method: 'GET'}),

        // DELETE - /billing-accounts/:billingAccountId/users/:userId
        deleteBillingAccountUser: (billingAccountId, userId) => _doRequest({path: `/billing-accounts/${billingAccountId}/users/${userId}`, method: 'DELETE'}),

        // GET - /billing-accounts/:billingAccountId/payment-methods
        getBillingAccountPaymentMethods: billingAccountId => _doRequest({path: `/billing-accounts/${billingAccountId}/payment-methods`,method: 'GET'}),

        // GET - /billing-accounts/:billingAccountId/payment-methods/:paymentMethodsId
        getBillingAccountPaymentMethod: (billingAccountId, paymentMethodId) => _doRequest({path: `/billing-accounts/${billingAccountId}/payment-methods/${paymentMethodId}`,method: 'GET'}),

        // PATCH - /billing-accounts/:billingAccountId/payment-methods/:paymentMethodsId
        patchBillingAccountPaymentMethod: (billingAccountId, paymentMethodId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/payment-methods/${paymentMethodId}`, method: 'PATCH', data: data}),

        // DELETE - /billing-accounts/:billingAccountId/payment-methods/:paymentMethodsId
        deleteBillingAccountPaymentMethod: (billingAccountId, paymentMethodId) => _doRequest({path: `/billing-accounts/${billingAccountId}/payment-methods/${paymentMethodId}`, method: 'DELETE'}),

        // GET - /billing-accounts/:billingAccountId/setup-payment-method-intents
        getBillingAccountSetupPaymentMethodIntents: billingAccountId => _doRequest({path: `/billing-accounts/${billingAccountId}/setup-payment-method-intents`,method: 'GET'}),

        // GET - /billing-accounts/:billingAccountId/payment-method-intent-success
        postBillingAccountPaymentMethodIntentSuccess: (billingAccountId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/payment-method-intent-success`,method: 'POST', data: data}),

        // GET - /billing-accounts/:billingAccountId/transactions
        getBillingAccountTransactions: billingAccountId => _doRequest({path: `/billing-accounts/${billingAccountId}/transactions`, method: 'GET'}),

        // GET - /billing-accounts/:billingAccountId/transactions/:transactionId
        getBillingAccountTransaction: (billingAccountId, transactionId) => _doRequest({path: `/billing-accounts/${billingAccountId}/transactions/${transactionId}`, method: 'GET'}),

        // GET - /billing-accounts/:billingAccountId/transactions/:transactionId/billing-reports
        getBillingAccountTransactionBillingReports: (billingAccountId, transactionId) => _doRequest({path: `/billing-accounts/${billingAccountId}/transactions/${transactionId}/billing-reports`, method: 'GET'}),

        // GET - /billing-accounts/:billingAccountId/transactions/:transactionId/payment-method
        getBillingAccountTransactionPaymentMethod: (billingAccountId, transactionId) => _doRequest({path: `/billing-accounts/${billingAccountId}/transactions/${transactionId}/payment-method`, method: 'GET'}),

        // GET - /billing-accounts/:billingAccountId/discounts-one-time-credits
        getBillingAccountDiscountsOneTimeCredits: billingAccountId => _doRequest({path: `/billing-accounts/${billingAccountId}/discounts-one-time-credits`, method: 'GET'}),

        // GET - /billing-accounts/:billingAccountId/discounts-percentages
        getBillingAccountDiscountsPercentages: billingAccountId => _doRequest({path: `/billing-accounts/${billingAccountId}/discounts-percentages`, method: 'GET'}),

        // GET- /billing-accounts/:billingAccountId/billing-reports
        getBillingAccountBillingReports: (billingAccountId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/billing-reports${queryString || ''}`, method: 'GET'}),

        // GET- /billing-accounts/:billingAccountId/workspaces
        getBillingAccountWorkspaces: billingAccountId => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces`, method: 'GET'}),

        // POST- /billing-accounts/:billingAccountId/workspaces
        postBillingAccountWorkspace: (billingAccountId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces`, method: 'POST', data: data}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId
        getBillingAccountWorkspace: (billingAccountId, workspaceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}`, method: 'GET'}),

        // DELETE- /billing-accounts/:billingAccountId/workspaces/:worksapceId
        deleteBillingAccountWorkspace: (billingAccountId, workspaceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}`, method: 'DELETE'}),

        // PATCH- /billing-accounts/:billingAccountId/workspaces/:worksapceId
        patchBillingAccountWorkspace: (billingAccountId, workspaceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}`, method: 'PATCH', data: data}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/services
        getBillingAccountWorkspaceServices: (billingAccountId, workspaceId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/services${queryString || ''}`, method: 'GET'}),

        // POST- /billing-accounts/:billingAccountId/workspaces/:worksapceId/services
        postBillingAccountWorkspaceServices: (billingAccountId, workspaceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/services`, method: 'POST', data: data}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/services/:serviceId
        getBillingAccountWorkspaceService: (billingAccountId, workspaceId, serviceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/services/${serviceId}`, method: 'GET'}),

        // DELETE- /billing-accounts/:billingAccountId/workspaces/:worksapceId/services/:serviceId
        deleteBillingAccountWorkspaceService: (billingAccountId, workspaceId, serviceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/services/${serviceId}`, method: 'DELETE'}),

        // PATCH- /billing-accounts/:billingAccountId/workspaces/:worksapceId/services/:serviceId
        patchBillingAccountWorkspaceService: (billingAccountId, workspaceId, serviceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/services/${serviceId}`,method: 'PATCH', data: data}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/services/:serviceId/hourly-usages
        getBillingAccountWorkspaceServiceHourlyUsages: (billingAccountId, workspaceId, serviceId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/services/${serviceId}/hourly-usages${queryString || ''}`, method: 'GET'}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/services/:serviceId/logs
        getBillingAccountWorkspaceServiceLogs: (billingAccountId, workspaceId, serviceId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/services/${serviceId}/logs${queryString || ''}`, method: 'GET'}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/services/:serviceId/monitor-logs
        getBillingAccountWorkspaceServiceMonitorLogs: (billingAccountId, workspaceId, serviceId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/services/${serviceId}/monitor-logs${queryString || ''}`, method: 'GET'}),



        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/system-sms/:serviceId/events
        getBillingAccountWorkspaceSystemSMSEvents: (billingAccountId, workspaceId, serviceId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-sms/${serviceId}/events${queryString}`, method: 'GET'}),


        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/system-emails/:serviceId
        getBillingAccountWorkspaceSystemEmail: (billingAccountId, workspaceId, serviceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-emails/${serviceId}`, method: 'GET'}),

        // PUT- /billing-accounts/:billingAccountId/workspaces/:worksapceId/system-emails/:serviceId/verify
        putBillingAccountWorkspaceSystemEmailVerify: (billingAccountId, workspaceId, serviceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-emails/${serviceId}/verify`, method: 'PUT'}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/system-emails/:serviceId/stats-total
        getBillingAccountWorkspaceSystemEmailStatsTotal: (billingAccountId, workspaceId, serviceId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-emails/${serviceId}/stats-total${queryString}`, method: 'GET'}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/system-emails/:serviceId/credentials
        getBillingAccountWorkspaceSystemEmailCredentials: (billingAccountId, workspaceId, serviceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-emails/${serviceId}/credentials`, method: 'GET'}),

        // POST- /billing-accounts/:billingAccountId/workspaces/:worksapceId/system-emails/:serviceId/credentials
        postBillingAccountWorkspaceSystemEmailCredentials: (billingAccountId, workspaceId, serviceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-emails/${serviceId}/credentials`, method: 'POST', data: data}),

        // DELETE- /billing-accounts/:billingAccountId/workspaces/:worksapceId/system-emails/:serviceId/credentials/:login
        deleteBillingAccountWorkspaceSystemEmailCredentialsLogin: (billingAccountId, workspaceId, serviceId, login) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-emails/${serviceId}/credentials/${login}`, method: 'DELETE'}),

        // PUT- /billing-accounts/:billingAccountId/workspaces/:worksapceId/system-emails/:serviceId/credentials/:login
        putBillingAccountWorkspaceSystemEmailCredentialsLogin: (billingAccountId, workspaceId, serviceId, login, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-emails/${serviceId}/credentials/${login}`, method: 'PUT', data: data}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/system-emails/:serviceId/credentials
        getBillingAccountWorkspaceSystemEmailEvents: (billingAccountId, workspaceId, serviceId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/system-emails/${serviceId}/events${queryString}`, method: 'GET'}),


        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/zone-files/:serviceId
        getBillingAccountWorkspaceZoneFile: (billingAccountId, workspaceId, serviceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/zone-files/${serviceId}`, method: 'GET'}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/zone-files/:serviceId/dns-records
        getBillingAccountWorkspaceZoneFileDNSRecords: (billingAccountId, workspaceId, serviceId, queryString) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/zone-files/${serviceId}/dns-records?${queryString}`, method: 'GET'}),

       // POST- /billing-accounts/:billingAccountId/workspaces/:worksapceId/zone-files/:serviceId/dns-records
        postBillingAccountWorkspaceZoneFileDNSRecord: (billingAccountId, workspaceId, serviceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/zone-files/${serviceId}/dns-records`, method: 'POST', data: data}),

        // PUT- /billing-accounts/:billingAccountId/workspaces/:worksapceId/zone-files/:serviceId/dns-records
        putBillingAccountWorkspaceZoneFileDNSRecord: (billingAccountId, workspaceId, serviceId, recordId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/zone-files/${serviceId}/dns-records/${recordId}`, method: 'PUT', data: data}),

        // DELETE- /billing-accounts/:billingAccountId/workspaces/:worksapceId/zone-files/:serviceId/dns-records
        deleteBillingAccountWorkspaceZoneFileDNSRecord: (billingAccountId, workspaceId, serviceId, recordId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/zone-files/${serviceId}/dns-records/${recordId}`, method: 'DELETE'}),

        // POST- /billing-accounts/:billingAccountId/workspaces/:worksapceId/zone-files/:serviceId/purge-cache
        postBillingAccountWorkspaceZoneFilePurgeCache: (billingAccountId, workspaceId, serviceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/zone-files/${serviceId}/purge-cache`, method: 'POST', data: data}),

        // POST- /billing-accounts/:billingAccountId/workspaces/:worksapceId/shared-hosting/create
        postBillingAccountWorkspaceSharedHosting: (billingAccountId, workspaceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/create`, method: 'POST', data: data}),

        // GET- /billing-accounts/:billingAccountId/workspaces/:worksapceId/shared-hosting/:serviceId
        getBillingAccountWorkspaceSharedHosting: (billingAccountId, workspaceId, serviceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/${serviceId}`, method: 'GET'}),

        // DELETE- /billing-accounts/:billingAccountId/workspaces/:worksapceId/shared-hosting/:serviceId
        deleteBillingAccountWorkspaceSharedHosting: (billingAccountId, workspaceId, serviceId) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/${serviceId}`, method: 'DELETE'}),

        // POST- /billing-accounts/:billingAccountId/workspaces/:worksapceId/shared-hosting/:serviceId/set-password
        postBillingAccountWorkspaceSharedHostingSetPassword: (billingAccountId, workspaceId, serviceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/${serviceId}/set-password`, method: 'POST', data: data}),


        // POST- /billing-accounts/:billingAccountId/workspaces/:worksapceId/shared-hosting/verify-new-username
        postBillingAccountWorkspaceSharedHostingVerifyNewUsername: (billingAccountId, workspaceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/verify-new-username`, method: 'POST', data: data}),

        // POST- /billing-accounts/:billingAccountId/workspaces/:worksapceId/shared-hosting/:serviceId/get-password-strength
        postBillingAccountWorkspaceSharedHostingGetPasswordStrength: (billingAccountId, workspaceId, serviceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/${serviceId}/get-password-strength`, method: 'POST', data: data}),

        // POST- /billing-accounts/:billingAccountId/workspaces/:worksapceId/shared-hosting/:serviceId/change-package
        postBillingAccountWorkspaceShardHostingChangePackage: (billingAccountId, workspaceId, serviceId, data) => _doRequest({path: `/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/shared-hosting/${serviceId}/change-package`, method: 'POST', data: data}),



        // GET- /products
        getProducts: () => _doRequest({path: '/products', method: 'GET'}),

        // GET- /products/:productId
        getProduct: productId => _doRequest({path: `/products/${productId}`, method: 'GET'}),

        // GET- /products/:productId/price
        getProductPrice: productId => _doRequest({path: `/products/${productId}/price`, method: 'GET'}),

        // GET- /products-groups
        getProductsGroupsProducts: productsGroup =>  _doRequest({path: `/products-groups/${productsGroup}/products`, method: 'GET'}),
      }}>
        {children}
    </LevelupSDKContext.Provider>
  )
}
