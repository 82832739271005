import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import _ from 'lodash'

import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useModal } from '../../modal'

import Table from '../../components/table'
import NoServices from '../../components/misc-ui-components/no-services'

import cfLogo from '../../assets/cf-logo-v-rgb.png'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'
import ProviderExcerpt from '../../components/misc-ui-components/provider-excerpt'


const ZoneFiles = () => {
  const [ services, setServices ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const history = useHistory()
  const { t } = useTranslation()
  const { billingAccountId, workspaceId } = useParams()
  const {getBillingAccountWorkspaceServices } =  useLevelupSDK()
  const { modal, setModal } = useModal()


  // HARD CODED STRING!
  const DOMAINS_PRODUCTS_GROUPS_ID = '2c4f9139-642c-4554-a635-0aade7df1233'

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getBillingAccountWorkspaceServices(billingAccountId, workspaceId, `?productId=97f0be18-456e-4422-ae81-d868a16311d9`)
      .then(res => {
        setServices(res)
        setLoading(false)
      }).catch(e => console.error(e))

  }, [billingAccountId, workspaceId, getBillingAccountWorkspaceServices])


  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <div className="h-flex-wrap h-flex-align-center">
            <h1>{t('Zone Files')}</h1>
          </div>
          <ProviderExcerpt logoHtml={<img src={cfLogo} />} />
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">
          {!loading && services.length > 0 &&
            <Table
              headArray={[
                t('Name'),
              ]}
              rows={_.orderBy(services, [service => service.name.toLowerCase()], ['asc']).map(service => {
                return [
                  <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/zone-files/${service.id}`}>{service.name}</Link>,
                ]
              })} />
          }

          {!loading && services.length === 0 &&
            <NoServices
              productName={t('Zone Files')}
              />
          }
        </div>
      </div>
    </Fragment>
  )
}

export default ZoneFiles
