import React, { Fragment, useState, useEffect } from "react"
import {Link, useHistory, useParams} from 'react-router-dom'
import _ from 'lodash'
import validateUUID from 'uuid-validate'

import { useTranslation, Trans } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

const BillingAccountsWorkspacesList = () => {
  const [ workspaces, setWorkspaces ] = useState([])
  const [ paymentMethods, setPaymentMethods ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ searchString, setSearchString ] = useState('')

  const { getBillingAccountWorkspaces, getBillingAccountPaymentMethods } =  useLevelupSDK()
  const { t } = useTranslation()
  const history = useHistory()

  const { billingAccountId } = useParams()

  useEffect(() => {
    setLoading(true)
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return

    getBillingAccountWorkspaces(billingAccountId).then(workspaces => setWorkspaces(workspaces))

    getBillingAccountPaymentMethods(billingAccountId).then(pm => {
      setPaymentMethods(pm)
      setLoading(false)
    })

  }, [billingAccountId])

  return (
    <Fragment>
      <div className="lvl-page">
      {loading && <ProgressIndicator />}
        {!loading && paymentMethods.length === 0 &&
          <Fragment>
            <div className="lvl-page__body">
              <div className="lvl-callout lvl-callout--info -emphasized-header">
                <h2>{t('Missing billing information')}</h2>
                <div>
                  <Trans i18nKey="beforeCreateWorkspaceAddPaymentMethod">
                    Before you can create Workspaces and add Services, you have to <Link to={`/billing-accounts/${billingAccountId}/payment-methods`}>add a payment method</Link>
                  </Trans>
                  &nbsp;💳
                </div>
              </div>
            </div>
          </Fragment>
        }
        {!loading && paymentMethods.length > 0 &&
          <Fragment>
            <div className="lvl-page__header">
              <h1>{t('Workspaces')}</h1>
              <div>
                <Link to={`/billing-accounts/${billingAccountId}/workspaces/add`} className="lvl-button lvl-button--large">{t('Create new workspace')}</Link>
              </div>
            </div>


            <div className="lvl-page__body">

              {workspaces.length === 0 &&
                <div className="lvl-callout">
                  <Trans i18nKey="currentlyNoWorkspace">
                    No workspaces here now, but you can <Link to={`/billing-accounts/${billingAccountId}/workspaces/add`}>create one</Link>
                  </Trans>
                  &nbsp;🤗
                </div>
              }
              {workspaces.length > 0 &&
                <Fragment>
                  <div className="lvl-input-inline">
                    <label htmlFor="search">{t('Search')}</label>
                    <input type="text" id="search" value={searchString} onChange={e => setSearchString(e.target.value)} />
                  </div>

                  <Table
                    keyPrefix="workspaces-"
                    headArray={[t('Name'), '']}
                    rows={_.orderBy(workspaces, [workspace => workspace.name.toLowerCase()], ['asc'])
                            .filter(w => searchString.length > 0 ? w.name.toLowerCase().includes(searchString.toLowerCase()) : true)
                            .map(w => [
                      <Link to={`/billing-accounts/${billingAccountId}/workspaces/${w.id}/shared-hosting`}>{w.name} {w.submittedForDeletion && <span className="h-text-warning">({t('Deletion requested')})</span>}</Link>,
                      <Link to={`/billing-accounts/${billingAccountId}/workspaces/${w.id}/edit`}>{t('Workspace settings')}</Link>
                    ])} />
                </Fragment>
              }
            </div>
          </Fragment>
        }
      </div>
    </Fragment>
  );
};

export default BillingAccountsWorkspacesList
