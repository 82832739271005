import React, { Fragment, useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import _ from 'lodash'
import moment from 'moment'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'


import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

import BillingReportTimePicker from './billing-report-time-picker'
import RenderGraph from './render-graph'
import RenderList from './render-list'
import KeyInformation from './key-info'


const BillingAccountsBilling = () => {
  const [ loading, setLoading ] = useState(true)
  const [ billingReports, setBillingReports ] = useState([])
  const [ products, setProducts ] = useState([])

  const [ timespan, setTimespan ] = useState('current-month')
  const [ queryString, setQueryString ] = useState(null)

  const { billingAccountId } = useParams()

  const { t } = useTranslation()
  const { getBillingAccount, getBillingAccountBillingReports } =  useLevelupSDK()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId) || !queryString || queryString === '' || queryString === '?') return

    setLoading(true)

    getBillingAccountBillingReports(billingAccountId, queryString)
      .then(res => {
        setBillingReports(res)
        setLoading(false)
      })
      .catch(e => console.error(e))

  }, [billingAccountId, queryString])

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Usage & Costs')}</h1>
        </div>

        {loading && <ProgressIndicator />}

        <div className="lvl-page__body">
          <div>
            <BillingReportTimePicker setTimespan={setTimespan} timespan={timespan} setQueryString={setQueryString} />
          </div>

          {!loading && billingReports.length === 0 &&
            <div className="lvl-callout">
              {t('No usage or costs in this period')}
            </div>
          }
          {!loading && billingReports.length > 0 &&
            <Fragment>
              {!loading && timespan === 'current-month' && <KeyInformation billingReports={billingReports} />}

              {!loading &&
                <div>
                  <RenderGraph timespan={timespan} billingReports={billingReports} />
                </div>
              }

              {!loading &&
                <RenderList billingReports={billingReports} products={products} />
              }
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsBilling
