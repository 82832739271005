import React from 'react'
import _ from 'lodash'

import { prettyPrice } from '../../utils/pretty-prices'
import { useTranslation, Trans } from 'react-i18next'


const KeyInformation = ({billingReports}) => {
  const { t } = useTranslation()

  // this only gets called if showing "CURRENT MONTH"

  // the actual sub total
  const subTotal = billingReports.length > 0 && billingReports.map(g => g.subTotal).reduce((a, b) => a + b)
  const totalDiscounts = billingReports.length > 0 && billingReports.map(g => g.totalDiscounts).reduce((a, b) => a + b)

  const daysReported = Object.keys(_.groupBy(billingReports, br => br.date)).length
  // console.log('daysReported', daysReported)

  const date = new Date()
  const month = date.getMonth() + 1;
  const year = date.getFullYear()
  const daysInMonth = new Date(year, month, 0).getDate()
  // console.log('days in month: ', daysInMonth)

  const avgAmountPrDay = subTotal / daysReported
  const avDiscountsPrDay = totalDiscounts / daysReported

  const forecastedAmount = avgAmountPrDay * daysInMonth
  const forecastedDiscounts = avDiscountsPrDay * daysInMonth

  return (
    <div className="lvl-callout">
      <div style={{display: 'flex'}}>
        <div style={{flex: 1}}>
          <h3>{t('Current month')}</h3>
          <div className="h-text-emphasized">{prettyPrice(subTotal, 'NOK')}</div>
          <Trans i18nKey="usageDiscountStatement">
            Including {prettyPrice(totalDiscounts, 'NOK')} discount.
          </Trans>
        </div>
        <div style={{flex: 1}}>
          <h3>{t('Forecast for whole month')}</h3>
          <div className="h-text-emphasized">{prettyPrice(forecastedAmount, 'NOK')}</div>
          <Trans i18nKey="usageDiscountStatement">
            Including {prettyPrice(totalDiscounts, 'NOK')} discount.
          </Trans>
        </div>
      </div>
    </div>
  )
}

export default KeyInformation
