import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

const PageOptionsBar = ({backLink, header, options}) => {

  const { t } = useTranslation()

  return (
    <div className="lvl-options-bar">
      <div className="lvl-options-bar__header">
        <Link to={backLink} className="lvl-button lvl-button--hollow"><i className="fa fa-arrow-left"></i>{t('Back')}</Link>
        <b>{header}</b>
      </div>
      {options &&
      <div className="lvl-options-bar__body">
        {options.map((o, i) => (
          <Fragment>{o}</Fragment>
        ))}
      </div>
      }
    </div>
  )
}

PageOptionsBar.defaultProps = {
  header: '',
  backLink: '',
  options: '',
}

export default PageOptionsBar
