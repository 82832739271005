import React, { Fragment, useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'

const BillingAccountsWorkspacesEdit = () => {
  const [ workspace, setWorkspace] = useState(null)

  const { t } = useTranslation()
  const history = useHistory()

  const { billingAccountId, workspaceId } = useParams()

  const { setNotification } = useNotifications()
  const { getBillingAccountWorkspace, patchBillingAccountWorkspace, deleteBillingAccountWorkspace } =  useLevelupSDK()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return

    if (!workspaceId || !validateUUID(workspaceId)) return

    getBillingAccountWorkspace(billingAccountId, workspaceId)
      .then(workspace => {
        setWorkspace(workspace)
      })
      .catch(e => console.error(e))

  }, [billingAccountId, workspaceId])

  const deleteW = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm('Are you sure you wish to delete this item?')) {
      deleteBillingAccountWorkspace(billingAccountId, workspaceId)
        .then(workspace => history.push(`/billing-accounts/${billingAccountId}/workspaces`))
        .catch(error => console.error(error))
    }
  }

  // console.log('workspace', workspace)

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Workspace Settings')}</h1>
        </div>

        {!workspace && <ProgressIndicator />}

        <div className="lvl-page__body">
          {workspace &&
            <Fragment>
              <Formik
                initialValues={{
                  name: workspace.name,
                }}
                validationSchema={yup.object({
                  name: yup.string().min(2, t('Minimum 2 characters')).max(20, t('Maximum 20 characters')).required(t('Required')),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true)

                  patchBillingAccountWorkspace(billingAccountId, workspaceId, values)
                    .then(workspace => {
                      setSubmitting(false)
                      setWorkspace(workspace)
                      setNotification({text: t('Updated 🎉'), status: 'success'})
                    })
                    .catch(e => {
                      setNotification({text: t('Failed'), status: 'error'})
                      setSubmitting(false)
                    })
                }}
                >
                {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
                  <Form>

                    <div className="lvl-input">
                      <label htmlFor="name">{t('Name')}</label>
                      <Field type="text" id="name" name="name" data-hj-allow />
                      <ErrorMessage name="name" component="div" className="validation-message" />
                    </div>

                    <div className="lvl-button-group">
                      <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Save')}</button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="lvl-callout lvl-callout--danger -emphasized-header">
                <h2>Danger zone</h2>

                <div>
                  <h3>{t('Delete workspace')}</h3>
                  {workspace.submittedForDeletion &&
                    <p>Workspace is submitted for deletion.</p>
                  }

                  {!workspace.submittedForDeletion &&
                    <Fragment>
                      <p>{t('If you submit your workspace for deletion a request will be sent to Levelup, and they will manualy validate the deletion. Once your workspace is deleted, you can never get back your data!')}</p>
                      <button type="button" onClick={e => deleteW(e)} className="lvl-button--dangerous">{t('Delete workspace')}</button>
                    </Fragment>
                  }
                </div>
              </div>
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesEdit
