import React, { Fragment, useState, useEffect, useCallback } from "react"
import { Link, useHistory, useParams } from 'react-router-dom'
import RichTextEditor from 'react-rte';

import { useTranslation } from 'react-i18next'

import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import { ProgressIndicator } from '../../components/misc-ui-components/progress-indicators'
import { useNotifications } from '../../notifications'

const Ticket = ({ location }) => {
  const { setNotification } = useNotifications()
  const { postBillingAccountTicket } = useLevelupSDK()

  const { t } = useTranslation()

  const { billingAccountId, workspaceId } = useParams()
  const history = useHistory()
  const locationState = location.state || {}

  const [ rteContent, setRteContent ] = useState(RichTextEditor.createValueFromString(locationState.message, 'html') || RichTextEditor.createEmptyValue())
  const [ formData, setFormData ] = useState({
    subject: locationState.subject || '',
    message: locationState.message || '',
  })

  const [readyToSubmit, setReadyToSubmit] = useState(false)



  useEffect(() => {
    if (!formData.subject || !formData.message) {
      setReadyToSubmit(false)
    } else {
      setReadyToSubmit(true)
    }
  }, [formData])


  const submit = (e) => {
    e.preventDefault()

    postBillingAccountTicket(billingAccountId, {
      subject: formData.subject,
      message: formData.message
    }).then(res => {
      setNotification({text: t('Ticket submitted 🎉'), status: 'success'})
      history.goBack()
    }).catch(err => {
      setNotification({text: t('Failed to submit ticket, please try again.'), status: 'error'})
    })
  }

  return (
    <Fragment>
      <div className="lvl-page">

        <div className="lvl-page__header">
          <h1>{locationState.heading || t('New ticket')}</h1>
        </div>

        <div className="lvl-page__body">
          <form onSubmit={e => submit(e)}>

            <div className="lvl-input-inline">
              <label htmlFor="subject">{t('Subject')}</label>
              <input type="text" id="subject" value={formData.subject} disabled />
            </div>

            <div>
              <label htmlFor="subject">{t('Subject')}</label>
              <RichTextEditor
                value={rteContent}
                toolbarConfig={{
                  // Optionally specify the groups to display (displayed in the order listed).
                  display: [],
                  // INLINE_STYLE_BUTTONS: [
                  //   {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                  //   {label: 'Italic', style: 'ITALIC'},
                  //   {label: 'Underline', style: 'UNDERLINE'}
                  // ],
                  // BLOCK_TYPE_DROPDOWN: [
                  //   {label: 'Normal', style: 'unstyled'},
                  //   {label: 'Heading Large', style: 'header-one'},
                  //   {label: 'Heading Medium', style: 'header-two'},
                  //   {label: 'Heading Small', style: 'header-three'}
                  // ],
                  // BLOCK_TYPE_BUTTONS: [
                  //   {label: 'UL', style: 'unordered-list-item'},
                  //   {label: 'OL', style: 'ordered-list-item'}
                  // ]
                }}
                onChange={(value) => {
                  setRteContent(value) // set the component value (more advanced)
                  setFormData({...formData, ...{message: value.toString('html')}}) // set the string formated data to message
                }}
              />
            </div>

              <div className="lvl-button-group">
                <button type="submit" className="lvl-button lvl-button--proceed" disabled={!readyToSubmit}>{t('Submit order')}</button>
                <button type="button" onClick={e => history.goBack()}>{t('Cancel')}</button>
              </div>

          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Ticket
