import React, { Fragment, useEffect } from "react"
import { Route } from "react-router-dom"
import { useAuth0 } from "../react-auth0-spa"

// Sticky UI components
import TopBar from './top-bar'
import { BillingAccountSidebar, WorkspaceSidebar } from './sidebar'

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  const render = props => {

    let isBillingAccount = false
    let isWorkspace = false

    if (props.match && props.match.params) {
      if (props.match.params.workspaceId) {
        isWorkspace = true
      } else if (props.match.params.billingAccountId) {
        isBillingAccount = true
      }
    }

    if (isAuthenticated === true) {
      return (
        <Fragment>
          <header className={`app__header ${isBillingAccount ? 'lvl-section--ba' : ''} ${isWorkspace ? 'lvl-section--ws' : ''}`}>
            <TopBar />
          </header>
          <main className={`app__body ${isBillingAccount ? 'lvl-section--ba' : ''} ${isWorkspace ? 'lvl-section--ws' : ''}`}>
            {isBillingAccount && <BillingAccountSidebar />}
            {isWorkspace && <WorkspaceSidebar />}
            <Component {...props} />
          </main>
        </Fragment>
      )
    } else {
      return null
    }
  }

  return <Route path={path} render={render} {...rest} />
};

export default PrivateRoute
