import React, { useState } from 'react'
import {Link} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import Table from '../../components/table'

const ErroredServies = ({userStatus}) => {
  const [ showAll, setShowAll ] = useState(false)
  const { t } = useTranslation()

  let services = []
  if (showAll) {
    services = userStatus.erroredServices
  } else {
    services = userStatus.erroredServices.slice(0, 3)
  }

  return (
    <div style={{
      float: 'left',
      width: 300,
      padding: 20,
      marginRight: 20,
      marginBottom: 20,
      borderRadius: 5,
      background: '#f7eefc'
    }}>
      <h3>{t('Services reporting errors')}</h3>
      <Table
        keyPrefix="errored-services-"
        headArray={['']}
        rows={services.map(service => [
          <Link to={`/billing-accounts/${service.workspace.billingAccountId}/workspaces/${service.workspace.id}/monitoring/${service.id}`}><i className="fas fa-exclamation-triangle" style={{color: '#E25F4A'}}></i> {service.name}</Link>,
        ])}
        />
      {userStatus.erroredServices.length > 3 &&
        <button onClick={e => setShowAll(!showAll)}>
          {showAll ? t('Show less') : t('Show all')}
        </button>
      }
    </div>
  )
}

export default ErroredServies
