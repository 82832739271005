import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"

import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import Table from '../../components/table'

const Logs = () => {
  const [ systemEmailEvents, setSystemEmailEvents ] = useState([])
  const [ pages, setPages ] = useState()
  const [ loading, setLoading ] = useState(true)
  const [ page, setPage ] = useState(null)

  const {
    getBillingAccountWorkspaceSystemEmailEvents
  } =  useLevelupSDK()
  const { t } = useTranslation()

  const { billingAccountId, workspaceId, serviceId } = useParams()

  const getEvents = () => {
    setSystemEmailEvents([])

    if (!billingAccountId || !workspaceId || !serviceId) return

    let queryString = ''
    if (page) {
      queryString = `?page=${encodeURIComponent(page)}`
    }

    getBillingAccountWorkspaceSystemEmailEvents(billingAccountId, workspaceId, serviceId, queryString)
      .then(res => {
        setSystemEmailEvents(res.items)
        setPages(res.paging)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        console.error(e)
      })

  }

  useEffect(() => {
    getEvents()
  }, [page])

  useEffect(() => {
    getEvents()
  }, [])

  return (
    <div>
      {loading && <div>Loading...</div>}
      {!loading &&
        <Fragment>
          <h2>{t('Event logs')}</h2>
          <div style={{textAlign: 'right', marginBottom: 20}}>
            <button onClick={e => getEvents()}>{t('Refresh')}</button>
          </div>
          <Table
            headArray={[
              '',
              t('Timestamp'),
              t('Event'),
              t('Sender'),
              t('Recipient'),
              t('Delivery Status Message')
            ]}
            rows={systemEmailEvents.map(event => {

              let sender = ''
              let recipient = ''

              if (event && event.message && event.message.headers) {
                sender = event.message.headers.from
                recipient = event.message.headers.to
              } else {
                recipient = event.recipient
              }

              let statusColor = 'blue'

              if (event['log-level'] === 'info') {
                statusColor = 'green'
              } else if (event['log-level'] === 'error') {
                statusColor = 'red'
              }

              return [
                <span style={{width: 8, height: 8, borderRadius: 8, display: 'inline-block', background: statusColor}}></span>,
                moment.unix(event.timestamp).format('YYYY.MM.DD HH:mm'),
                event.event,
                sender,
                recipient,
                event['delivery-status'] ? event['delivery-status'].message : ''
              ]
            })} />

          {pages &&
            <div>
              {pages.previous && <button onClick={e => setPage(pages.previous)}>{t('Previous')}</button>}
              {pages.next && <button onClick={e => setPage(pages.next)}>{t('Next')}</button>}
            </div>
          }
        </Fragment>
      }
    </div>
  )
}

export default Logs
