import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import moment from 'moment'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import { StatusLabel } from '../../components/misc-ui-components/labels'
import { useNotifications } from '../../notifications'

import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'

const RenderSSLStatus = ({serviceLogs}) => {
  const { t } = useTranslation()

  const latestStatus = serviceLogs[serviceLogs.length - 1]
  const data = latestStatus.data
  const sslInfo = data.sslInfo
  const error = Object.assign({}, data.error)
  if (error && error.cert) {
    delete error.cert.raw
  }

  console.log(error)

  if (sslInfo) {
    return (
      <Fragment>
        <h3>SSL Status</h3>
        <table className="lvl-table-definition-list">
          <tbody>
            <tr>
              <th>{t('Valid')}</th>
              <td>{sslInfo.valid ? t('Yes') : t('No')}</td>
            </tr>
            <tr>
              <th>{t('Valid to')}</th>
              <td>{moment(sslInfo['valid_to']).format('DD.MM.YYYY')}</td>
            </tr>
            <tr>
              <th>{t('Valid from')}</th>
              <td>{moment(sslInfo['valid_from']).format('DD.MM.YYYY')}</td>
            </tr>
            <tr>
              <th>{t('Days remaining')}</th>
              <td>{sslInfo['days_remaining']}</td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    )
  } else if (error) {
    return (
      <Fragment>
        <h3>SSL error</h3>
        <table className="lvl-table-definition-list">
          <tbody>
            {error.code &&
              <tr>
                <th>{t('Error Code')}</th>
                <td>{error.code}</td>
              </tr>
            }
            {error.reason &&
              <tr>
                <th>{t('Reason')}</th>
                <td>{error.reason}</td>
              </tr>
            }
            {error.cert &&
              <tr>
                <th>{t('Raw cert data')}</th>
                <td><pre>{JSON.stringify(error.cert, null, 4)}</pre></td>
              </tr>
            }
          </tbody>
        </table>
      </Fragment>
    )
  } else {
    return <div />
  }
}

const BillingAccountsWorkspacesMonitoringDetail = () => {
  const [ products, setProducts ] = useState([])
  const [ service, setService ] = useState({})
  const [ serviceLogs, setServiceLogs ] = useState([])
  const { setNotification } = useNotifications()

  const { t } = useTranslation()
  const {
    deleteBillingAccountWorkspaceService,
    getProductsGroupsProducts,
    getBillingAccountWorkspaceService,
    getBillingAccountWorkspaceServiceMonitorLogs
  } =  useLevelupSDK()

  const history = useHistory()

  const { billingAccountId, workspaceId, serviceId } = useParams()

  // HARD CODED STRING!
  const SHARED_HOSTING_PRODUCTS_GROUPS_ID = 'bbc0e9a2-7338-470a-b368-36c4b71f1e38'

  const init = useCallback(
    ( notification = false ) => {
      const main = async () => {
        getProductsGroupsProducts(SHARED_HOSTING_PRODUCTS_GROUPS_ID)
          .then(products => setProducts(products))
          .catch(error => console.error(error.message))

        getBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
          .then(service => setService(service))
          .catch(e => console.error(e))
      }
      main()
      if ( notification )
      setNotification({text: t('Refreshed 🙌'), status: 'warning'})
    }, [SHARED_HOSTING_PRODUCTS_GROUPS_ID, billingAccountId, workspaceId, serviceId, getProductsGroupsProducts, getBillingAccountWorkspaceService]
  )

  const deleteService = async () => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      deleteBillingAccountWorkspaceService(billingAccountId, workspaceId, serviceId)
        .then(res => {
          history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring`)
          setNotification({text: t('Deleted ✌️'), status: 'success'})
        })
        .catch(error => console.error(error.message))
    }
  }

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    init()
  }, [billingAccountId, workspaceId, serviceId, init])

  useEffect(() => {
    const fetchServiceMonitorLogs = async () => {
      if (!service || !service.id) return

      // fetch monitor logs
      let queryString = '?'
      if (service.productId === 'e3b87f37-fa5a-4b06-b6c1-21c5eb9f9996') {
        const start = moment().subtract(24, 'h').toISOString()
        const end = moment().toISOString()
        queryString += `start=${start}&end=${end}`
      }

      if (service.productId === 'c2e1d3b7-86bc-4843-9f94-db93d9013785') {
        queryString += `limit=1`
      }

      if (service.productId === '4524dfa8-9340-49a8-84e7-6c230a8ea701') {
        queryString += `limit=20`
      }

      getBillingAccountWorkspaceServiceMonitorLogs(billingAccountId, workspaceId, serviceId, queryString)
        .then(serviceMonitorLogs => setServiceLogs(serviceMonitorLogs))
        .catch(e => console.error(e))
    }

    fetchServiceMonitorLogs()
  }, [service])

  // define strings
  let currentProductName = t('Could not fetch product information')
  if (products.length > 0 && service && service.productId) {
    const currentProduct = products.find(p => p.id === service.productId)
    currentProductName = currentProduct.name || currentProductName
  }

  // console.log(serviceLogs)

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
            header={t('Monitoring')}
            backLink={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring`}
            options={[
              <Link className="lvl-button" to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring/${serviceId}/edit`}><i className="fa fa-pencil"></i>{t('Edit')}</Link>,
              <button onClick={e => init(true)}><i className="fa fa-sync-alt"></i>{t('Refresh')}</button>,
              <button className="lvl-button lvl-button--dangerous" onClick={e => deleteService()}><i className="fa fa-trash-o"></i>{t('Delete')}</button>
              ]}
            />
        </div>

          <div className="lvl-page__header">
            <h1>{service.name}</h1>
          </div>

          <div className="lvl-page__body">
            <div>
              <h3>{t('Service information')}</h3>
              <table className="lvl-table-definition-list">
                <thead></thead>
                <tbody>
                  <tr>
                    <th>{t('Product')}</th>
                    <td>{currentProductName}</td>
                  </tr>
                  {service.details && Object.keys(service.details).map(key => {
                    if (key === 'reportedDaysRemaining') return
                    const value = service.details[key]
                    return (
                      <tr key={`info-${key}`}>
                        <th>{key}</th>
                        <td>{value && value == ("OK" || "ERROR") && <StatusLabel type="success" text={value} />}{value && value != ("OK" || "ERROR") && value}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            {service.productId === 'e3b87f37-fa5a-4b06-b6c1-21c5eb9f9996' &&
              // This is a HTTP monitoring product
              // Lets draw a timeline
              <Fragment>
                {serviceLogs.length && serviceLogs[0].data &&
                  <div>
                    <h3>{t('Latest log information')}</h3>
                    <table className="lvl-table-definition-list">
                      <thead></thead>
                      <tbody>
                        {serviceLogs[0].data.error &&
                          <tr>
                            <th>{t('Error')}</th>
                            <td>{JSON.stringify(serviceLogs[0].data.error) || serviceLogs[0].data.error}</td>
                          </tr>
                        }
                        {serviceLogs[0].data.statusCode &&
                          <tr>
                            <th>{t('Status Code')}</th>
                            <td>{serviceLogs[0].data.statusCode}</td>
                          </tr>
                        }
                        {serviceLogs[0].data.statusMessage &&
                          <tr>
                            <th>{t('Status message')}</th>
                            <td>{serviceLogs[0].data.statusMessage}</td>
                          </tr>
                        }
                        {serviceLogs[0].date &&
                          <tr>
                            <th>{t('Logged at')}</th>
                            <td>{moment(serviceLogs[0].date).format('DD.MM.YYYY HH:mm')}</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                }

                <div>
                  <h3>{t('Logs last 24 hours')}</h3>
                  <div className="lvl-block">
                    <StatusLabel type="success" text={t('Green')} /> = {t('OK')}
                    &nbsp;&nbsp;&nbsp;
                    <StatusLabel type="error" text={t('Red')} /> = {t('Error')}
                  </div>
                  <div className="lvl-visual-log__graph">
                    {serviceLogs.length > 0 && [...serviceLogs].reverse().map(sl => {
                      const status = sl.data.statusCode === 200 ? 'success' : 'error'
                      return <div key={sl.id} className={`lvl-graph__datapoint lvl-graph__datapoint--${status}`}></div>
                    })}
                  </div>
                  <div className="lvl-visual-log__labels">
                    <div>{t('24 hours ago')}</div>
                    <div>{t('12 hours ago')}</div>
                    <div>{t('Right now')}</div>
                  </div>
                </div>

              </Fragment>
            }

            {service.productId === 'c2e1d3b7-86bc-4843-9f94-db93d9013785' && serviceLogs.length > 0 &&
              <RenderSSLStatus serviceLogs={serviceLogs} />
            }

            {service.productId === '4524dfa8-9340-49a8-84e7-6c230a8ea701' &&
              <Fragment>
                <div>
                  <h3>{t('Getting started')}</h3>
                  <p>{t('Monitor your CRON by checking in to this address in the end of your cron job.')}</p>
                  <p><b><a href={`https://checkin.levelup.no/${service.id}`}>https://checkin.levelup.no/{service.id}</a></b></p>
                  <div style={{flex: 1, padding: 40, background: 'rgb(247, 238, 252)'}}>
                    <h3>{t('Example')}</h3>
                    <p><b>CURL</b></p>
                    <span><code>$ curl -L https://checkin.levelup.no/{service.id}</code></span>
                  </div>
                </div>
                <div>
                  <h3>{t('Last 20 Cron checkins')}</h3>
                  <ul>
                    {serviceLogs.map(sl => <li key={sl.id} style={{background: '#f2f4f5', marginBottom: 5, padding: 20}}>{moment(new Date(sl.date)).format('DD/MM/YYYY HH:mm')}</li>)}
                  </ul>
                </div>
              </Fragment>
            }
          </div>

      </div>
    </Fragment>
  )
}

export default BillingAccountsWorkspacesMonitoringDetail
