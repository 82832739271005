import React, { useState, useEffect } from 'react'
import {Bar} from 'react-chartjs-2'
import _ from 'lodash'
import moment from 'moment'

const generateCurrentMonthLabels = () => {
  var dateArray = []
  let start = moment().startOf('month').startOf('day').startOf('hour').startOf('minutes').format('YYYY-MM-DD')
  const end = moment().endOf('month').endOf('day').endOf('hour').endOf('minutes').format('YYYY-MM-DD')
  while (new Date(start) <= new Date(end)) {
    dateArray.push( moment(start).format('DD.MM.YYYY') )
    start = moment(start).add(1, 'days')
  }
  return dateArray
}

const generateLastMonthLabels = () => {
  var dateArray = []
  const lastMonth = moment().subtract(1, 'month')

  let start = lastMonth.startOf('month').startOf('day').startOf('hour').startOf('minutes').format('YYYY-MM-DD')
  const end = lastMonth.endOf('month').endOf('day').endOf('hour').endOf('minutes').format('YYYY-MM-DD')

  while (new Date(start) <= new Date(end)) {
    dateArray.push( moment(start).format('DD.MM.YYYY') )
    start = moment(start).add(1, 'days');
  }
  return dateArray
}

const generateLast30Days = () => {
  var dateArray = []
  let start = moment().subtract(31, 'days').format('YYYY-MM-DD')
  const end = moment().subtract(1, 'days').format('YYYY-MM-DD')

  while (new Date(start) <= new Date(end)) {
    dateArray.push( moment(start).format('DD.MM.YYYY') )
    start = moment(start).add(1, 'days');
  }

  return dateArray
}

const generateLast90Days = () => {
  var dateArray = []
  let start = moment().subtract(91, 'days').format('YYYY-MM-DD')
  const end = moment().subtract(1, 'days').format('YYYY-MM-DD')

  while (new Date(start) <= new Date(end)) {
    dateArray.push( moment(start).format('DD.MM.YYYY') )
    start = moment(start).add(1, 'days');
  }

  return dateArray
}

const RenderGraph = ({timespan, billingReports}) => {

  const [ labels, setLabels ] = useState([])
  // const [ costData, setCostData ] = useState([])
  const [ discountsData, setDiscountsData ] = useState([])
  const [ subTotalData, setSubTotalData ] = useState([])

  // this effect creates the labels
  useEffect(() => {
    // clear every time
    setLabels([])
    // create labels based on the timeSpan
    if (timespan === 'current-month') {
      const dateLabels = generateCurrentMonthLabels()
      setLabels(dateLabels)
    }
    else if (timespan === 'last-month') {
      const dateLabels = generateLastMonthLabels()
      setLabels(dateLabels)
    }
    else if (timespan === 'last-30-days') {
      const dateLabels = generateLast30Days()
      setLabels(dateLabels)
    }
    else if (timespan === 'last-90-days') {
      const dateLabels = generateLast90Days()
      setLabels(dateLabels)
    }
  }, [billingReports])

  // calculates the cost per label
  useEffect(() => {

    // clear every time
    // setCostData([])
    setDiscountsData([])
    setSubTotalData([])

    const costArray = []
    const discountsArray = []
    const subTotalArray = []
    for (let x = 0; labels.length > x; x++) {
      const label = labels[x]

      const targetBillingReports = billingReports.filter(br => moment(br.date).format('DD.MM.YYYY') === label)
      if (!targetBillingReports || targetBillingReports.length === 0) {
        // costArray.push(0)
        discountsArray.push(0)
        subTotalArray.push(0)
      } else {
        // cost
        // const costValue = targetBillingReports.map(br => br.cost).reduce((a, b) => a + b) || 0
        // costArray.push(costValue)

        // discounts
        const dicountValue = targetBillingReports.map(br => br.totalDiscounts).reduce((a, b) => a + b) || 0
        discountsArray.push(dicountValue)


        // cost
        const subTotalValue = targetBillingReports.map(br => br.subTotal).reduce((a, b) => a + b) || 0
        subTotalArray.push(subTotalValue)
      }
    }
    // Set cost
    // setCostData(costArray)
    setDiscountsData(discountsArray)
    setSubTotalData(subTotalArray)


  }, [labels])

  const graphOptions = {
    scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    }
  }

  const graphData = {
    labels: labels,
    datasets: [
      // {
      //   label: 'Cost',
      //   fill: false,
      //   lineTension: 0.1,
      //   backgroundColor: 'rgba(0,0,255,0.4)',
      //   borderColor: 'rgba(75,192,192,1)',
      //   borderCapStyle: 'butt',
      //   borderDash: [],
      //   borderDashOffset: 0.0,
      //   borderJoinStyle: 'miter',
      //   pointBorderColor: 'rgba(75,192,192,1)',
      //   pointBackgroundColor: '#fff',
      //   pointBorderWidth: 1,
      //   pointHoverRadius: 5,
      //   pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      //   pointHoverBorderColor: 'rgba(220,220,220,1)',
      //   pointHoverBorderWidth: 2,
      //   pointRadius: 1,
      //   pointHitRadius: 10,
      //   data: costData
      // },
      {
        label: 'Discounts',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(255,0,0,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: discountsData
      },
      {
        label: 'subTotal',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(0,255,0,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: subTotalData
      }
    ]
  }

  return (
    <Bar data={graphData} options={graphOptions} />
   )
}

export default RenderGraph
