import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'


const ChangeCredentialsPassword = ({billingAccountId, workspaceId, serviceId, login}) => {
  const { t } = useTranslation()
  const [ password, setPassword ] = useState('')
  const { putBillingAccountWorkspaceSystemEmailCredentialsLogin } =  useLevelupSDK()
  const { setNotification } = useNotifications()


  const updateCredentialPassword = (e) => {
    e.preventDefault()

    putBillingAccountWorkspaceSystemEmailCredentialsLogin(billingAccountId, workspaceId, serviceId, login, {password: password})
      .then(res => setNotification({text: t('Updated 🎉'), status: 'success'}))
      .catch(e => console.error(e))
  }

  return (
    <form onSubmit={e => updateCredentialPassword(e)}>
      <input type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} />
      <button type="submit">Update</button>
    </form>
  )
}

export default ChangeCredentialsPassword
