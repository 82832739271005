import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import validateUUID from 'uuid-validate'

import AccountPicker from './account-picker'
import WorkspacePicker from './workspace-picker'

// import { useAuth0 } from "../../react-auth0-spa"
import { useAuth0 } from "../../react-auth0-spa"
import { useLevelupSDK } from '../../levelup-sdk'

import ProfileDropdown from './profile-dropdown'
import { LevelupLogo } from '../../components/misc-ui-components/levelup-logo'


// const LevelupLogo = '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 656.6 82.1" style="enable-background:new 0 0 656.6 82.1;" xml:space="preserve"><style type="text/css">.st0{fill:none;}.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#333E43;}.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#E15E4A;}</style><g><rect class="st0" width="656.6" height="82.1"/><path class="st1" d="M630.8,40.1h-20.2V10.8h20.2c9.1,0,15.3,6,15.3,14.6S639.9,40.1,630.8,40.1z M610.6,79.2V48.7H632c15.2,0,24.1-10.6,24.1-23.2S647.4,2.3,632,2.3H601v76.9H610.6z M537.3,80.6c20.9,0,31.4-12.2,31.4-31.4V2.3h-9.6v46.8c0,14.1-7.6,23-21.8,23c-14.2,0-21.8-8.9-21.8-23V2.3h-9.6v47.1C505.9,68.3,516.4,80.6,537.3,80.6z M481.1,79.2v-8.5h-35.8V2.3h-9.6v76.9H481.1z M406.1,79.2v-8.5h-40.8V44.2h40v-8.5h-40V10.8h40.8V2.3h-50.4v76.9H406.1z M300.2,79.2l31-76.9h-11l-26,66.4l-26-66.4h-11l30.9,76.9H300.2z M235.7,79.2v-8.5h-40.8V44.2h40v-8.5h-40V10.8h40.8V2.3h-50.4v76.9H235.7z M157.4,79.2v-8.5h-35.8V2.3h-9.6v76.9H157.4z"/><rect class="st0" width="656.6" height="82.1"/><path class="st2" d="M41.9,81.6V0h-8.3v81.6H41.9z M8.3,81.6V0H0v81.6H8.3z"/><rect class="st0" width="656.6" height="82.1"/></g></svg>'

const TopBar = () => {
  const [ navOpen, setNavOpen ] = useState(false)
  const [ billingAccount, setBillingAccount ] = useState(null)
  const [ billingAccountWorkspaces, setBillingAccountWorkspaces ] = useState([])

  const [ workspace, setWorkspace ] = useState()

  const { user, loading} = useAuth0()
  const { t } = useTranslation()
  const { 
    getBillingAccounts,
    getBillingAccount, 
    getBillingAccountWorkspaces,
    getBillingAccountWorkspace 
  } =  useLevelupSDK()

  const { billingAccountId, workspaceId } = useParams()

  useEffect(() => {

    if (billingAccountId  && validateUUID(billingAccountId)) {
      getBillingAccount(billingAccountId).then(res => setBillingAccount(res))
      getBillingAccountWorkspaces(billingAccountId).then(res => setBillingAccountWorkspaces(res))

      if (workspaceId && validateUUID(workspaceId)) {
        getBillingAccountWorkspace(billingAccountId, workspaceId).then(res => setWorkspace(res))
      } else {
        setWorkspace(null)
      }
    } else {
      setBillingAccount(null)
      setBillingAccountWorkspaces([])
    }

  }, [billingAccountId, workspaceId])

  return (
    <div className="lvl-top-bar">

      <div className="lvl-top-bar__header">
        <Link className="lvl-top-bar__logo" to="/"><LevelupLogo /></Link>

        <AccountPicker billingAccount={billingAccount} />

        {billingAccountId && <WorkspacePicker workspace={workspace} billingAccountWorkspaces={billingAccountWorkspaces} />}

      </div>

      <div className="lvl-top-bar__body">

        {!loading &&
          <div className="user-nav">
            <div className="user-excerpt h-is-clickable" onClick={() => setNavOpen(!navOpen)}>
              <span className="user-excerpt__username" data-hj-allow>{user.name}</span>
              <div className="user-excerpt__image"><img src={user.picture} alt="Profile" /></div>
            </div>

            <ProfileDropdown navOpen={navOpen} setNavOpen={() => setNavOpen()} />
          </div>
        }
      </div>
    </div>
  )
}

export default TopBar
