import React, { Fragment } from "react"

const ProviderExcerpt = ({logoHtml}) => {

  return (
    <Fragment>
    {logoHtml &&
      <div className="lvl-provider-excerpt">
       <span>Powered by:&nbsp;</span>
       <span className="lvl-provider-excerpt__logo">{logoHtml}</span>
      </div>
    }
    </Fragment>
  )
}

ProviderExcerpt.defaultProps = {
  logoHtml: false
}

export default ProviderExcerpt
