import React from 'react'

const InfoCard = ({heading, body, ctaOnClick, ctaText, footer, addingCard}) => {
  /*
    body can be html elements like p/h3/h2 or just a string
    ctaOnClick: a function that does something on click
    ctaTaxt: a text inside the cta button
  */

  // console.log('heading', heading)

  return (
    <div className={`lvl-info-card ${addingCard && "lvl-info-card--adding-card"}`}>
      {heading && <h3>{heading}</h3>}
      {body && <div className="lvl-info-card__body">{body}</div>}
      {ctaOnClick && <button type="button" onClick={e => ctaOnClick()}>{ctaText}</button>}
      {footer && <div className="lvl-info-card__footer">{footer}</div>}
    </div>
  )
}

InfoCard.defaultProps = {
  heading: '',
  body: 'Missing custom body text',
  // ctaOnClick: e => console.error('missing custom CTA on click'),
  ctaOnClick: '',
  ctaText: 'Create new xxx',
  addingCard: '',
}

export default InfoCard



