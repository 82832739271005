const prettyPrice = (price, currency) => {
  if (currency === 'NOK') {
    let transformedPrice = Number(price).toLocaleString('nb', {minimumFractionDigits: 2, maximumFractionDigits: 2})

    // transformedPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    transformedPrice = `Kr ${transformedPrice}`

    return transformedPrice
  }

  return price
}

export { prettyPrice }
export default prettyPrice
