import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'
import { useNotifications } from '../../notifications'

import { prettyPrice } from '../../utils/pretty-prices'

import PageOptionsBar from '../../components/misc-ui-components/page-options-bar'

const RenderProductPrices = ({productId}) => {
  const [ productPrice, setProductPrice] = useState({})

  const { t } = useTranslation()
  const { getProductPrice } =  useLevelupSDK()

  useEffect(() => {
    if (!productId) return 

    getProductPrice(productId)
    .then(r => setProductPrice(r))
    .catch(e => console.error(e))
  }, [productId])

  return (
    <div className="lvl-callout">
      <h3>{t('Estimated price')}</h3>
      <p>{t('Price per hour')}: {prettyPrice(productPrice.pricePerHour, 'NOK')}<br />
        {t('Estimated monthly price')}: {prettyPrice(productPrice.pricePerHour * 730.484398, 'NOK')}<br /><br />
        <small><i>* {t('Does not include any discounts')}.</i></small>
      </p>
    </div>
  )
}

const MonitoringAdd = () => {
  const [ products, setProducts ] = useState([])

  const history = useHistory()
  const { t } = useTranslation()
  const { billingAccountId, workspaceId } = useParams()
  const { 
    getProductsGroupsProducts, 
    postBillingAccountWorkspaceServices
  } =  useLevelupSDK()
  const { setNotification } = useNotifications()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProductsGroupsProducts('bbc0e9a2-7338-470a-b368-36c4b71f1e38')
      .then(products => setProducts(products))
      .catch(e => console.error(e))

  }, [billingAccountId, workspaceId])


  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__preheader">
          <PageOptionsBar
            header={t('Add new monitor')}
            backLink={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring`}
          />
        </div>

        <div className="lvl-page__header">
          <h1>{t('Add new monitor')}</h1>
        </div>


        <div className="lvl-page__body">

          {products && products.length > 0 && 
            <Formik
              initialValues={{
                name: '',
                productId: 'velg',
                url: '',
                domain: '',
                interval: '15MIN'
              }}
              validationSchema={yup.object({
                name: yup.string().min(2, t('Minimum 2 characters')).max(20, t('Maximum 20 characters')).required(t('Required')),
                productId: yup.string().required(t('Required')),
                url: yup.string().url(),
                domain: yup.string().matches(/[a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/, t('Please provide a valid domain name')).min(3, t('Minimum 3 characters')),
                interval: yup.mixed().oneOf(['15MIN', '30MIN', '1H', '1D', '1W', '1M'])
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)

                // build details
                const data = {}
                data.productId = values.productId
                data.name = values.name

                data.details = {}
                if (values.productId === 'e3b87f37-fa5a-4b06-b6c1-21c5eb9f9996') {
                  data.details.url = values.url
                }
                if (values.productId === 'c2e1d3b7-86bc-4843-9f94-db93d9013785') {
                  data.details.domain = values.domain
                }
                if (values.productId === '4524dfa8-9340-49a8-84e7-6c230a8ea701') {
                  data.details.interval = values.interval
                }

                postBillingAccountWorkspaceServices(billingAccountId, workspaceId, data)
                  .then(data => {
                    if (data && data.id) {
                      history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring/${data.id}`)
                      setNotification({text: t('Updated 🎉'), status: 'success'})
                      setSubmitting(false)
                    } else {
                      setNotification({text: t('Failed to create'), status: 'error'})
                      setSubmitting(false)
                    }
                  }).catch(error => {
                    setNotification({text: t('Failed to create'), status: 'error'})
                    setSubmitting(false)
                  })
              }}
              >
              {({ isSubmitting, setFieldValue, handleChange, handleBlur, values }) => (
                <Form>

                  <div className="lvl-input">
                    <label htmlFor="productId">{t('Monitor type')}</label>
                    <div className="lvl-input--select">
                      <Field as="select" id="productId" name="productId">
                        <Fragment>
                          <option value="velg">Velg</option>
                          {products.map(p => <option key={p.id} value={p.id} disabled={p.id === '9d7cec2d-7da3-4b46-b71d-c264a29f6042' ? true : false}>{t(p.name)}</option>)}
                        </Fragment>
                      </Field>
                    </div>
                  </div>

                  {values.productId !== 'velg' && 
                    <div className="lvl-input">
                      <label htmlFor="name">{t('Name')}</label>
                      <Field type="text" id="name" name="name" />
                      <ErrorMessage name="name" component="div" className="validation-message" />
                    </div>
                  }

                  {values.productId === 'e3b87f37-fa5a-4b06-b6c1-21c5eb9f9996' &&
                    <div className="lvl-input">
                      <label htmlFor="url">{t('URL')}</label>
                      <Field type="text" id="url" name="url" />
                      <ErrorMessage name="url" component="div" className="validation-message" />
                    </div>
                  }

                  {values.productId === 'c2e1d3b7-86bc-4843-9f94-db93d9013785' &&
                    <div className="lvl-input">
                      <label htmlFor="domain">{t('Domain')}</label>
                      <Field type="text" id="domain" name="domain" />
                      <ErrorMessage name="domain" component="div" className="validation-message" />
                    </div>
                  }

                  {values.productId === '4524dfa8-9340-49a8-84e7-6c230a8ea701' &&
                    <div className="lvl-input">
                      <label htmlFor="interval">Interval</label>
                      <div className="lvl-input--select">
                        <Field as="select" name="interval" id="interval">
                          <option value="15MIN">{t('Once every 15 minutes')}</option>
                          <option value="30MIN">{t('Once every 30 minutes')}</option>
                          <option value="1H">{t('Once every hour')}</option>
                          <option value="1D">{t('One a day')}</option>
                          <option value="1W">{t('Once a week')}</option>
                          <option value="1M">{t('Once a month')}</option>
                        </Field>
                      </div>
                    </div>
                  }

                  {values.productId && values.productId !== 'velg' && 
                    <RenderProductPrices productId={values.productId} /> 
                  }

                  <div className="lvl-button-group">
                    <button type="button" onClick={e => history.push(`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/monitoring`)}>{t('Cancel')}</button>
                    <button type="submit" disabled={isSubmitting} className="lvl-button lvl-button--proceed">{t('Create')}</button>
                  </div>
                </Form>
              )}
            </Formik>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default MonitoringAdd
