import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from "react-router-dom"
import _ from 'lodash'
import validateUUID from 'uuid-validate'

import { useTranslation } from 'react-i18next'
import { useLevelupSDK } from '../../levelup-sdk'

import Table from '../../components/table'
import NoServices from '../../components/misc-ui-components/no-services'
const KubernetesContainers = () => {
  const [ product, setProduct ] = useState()
  const [ services, setServices ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const history = useHistory()
  const { t } = useTranslation()
  const { getProduct, getBillingAccountWorkspaceServices } =  useLevelupSDK()
  const { billingAccountId, workspaceId } = useParams()

  useEffect(() => {
    // only run if current workspace is defined
    if (!billingAccountId || !validateUUID(billingAccountId)) return
    if (!workspaceId || !validateUUID(workspaceId)) return

    getProduct('cb843fd0-6514-4b04-b2ce-06db3bc4bb56')
      .then(product => {
        setProduct(product)
        getBillingAccountWorkspaceServices(billingAccountId, workspaceId, '?productId=cb843fd0-6514-4b04-b2ce-06db3bc4bb56')
          .then(res => {
            setServices(res)
            setLoading(false)
          }).catch(e => console.error(e))
      })
      .catch(e => console.error(e))
  }, [billingAccountId, workspaceId, getProduct, getBillingAccountWorkspaceServices])

  return (
    <Fragment>
      <div className="lvl-page">
        <div className="lvl-page__header">
          <h1>{t('Kubernetes containers')}</h1>
        </div>

        <div className="lvl-page__body">
          {loading && t('Loading...')}

          {!loading && services.length > 0 &&
            <Table
              headArray={[
                t('Name'),
                t('Product'),
                t('Actions')
              ]}
              rows={_.orderBy(services, [service => service.name.toLowerCase()], ['asc']).map(service => {
                return [
                  <Link to={`/billing-accounts/${billingAccountId}/workspaces/${workspaceId}/vpsr-hosting/kubernetes-containers/${service.id}`}>{service.name}</Link>,
                  product.name || 'Laster...',
                  t('Edit')
                ]
              })} />
          }

          {!loading && services.length === 0 &&
            <NoServices
              productName={t('Kubernetes containers')}
              />
          }
        </div>
      </div>
    </Fragment>
  )
}

export default KubernetesContainers
