import React, { Fragment } from "react"

import { useModal } from '../../../modal'

import { useTranslation, Trans } from 'react-i18next'

const NoServices = ({productName, ctaOnClick, inBillingAccount}) => {

  const { modal, setModal } = useModal()
  const { t } = useTranslation()

  return (
    <div className="lvl-callout">
      {ctaOnClick ?
        <Fragment>
          <h2>
            <Trans i18nKey="noServiceRunning">
              No {productName} service running in this {inBillingAccount ? 'account' : 'workspace'}.
            </Trans>
          </h2>
          <button className="lvl-button lvl-button--large lvl-button--proceed" onClick={e => ctaOnClick(e)}>{t('Get started')}</button>
        </Fragment>
        :
        <Fragment>
          <p>
            <Trans i18nKey="noServiceRunning">
              No {productName} service running in this {inBillingAccount ? t('account') : t('workspace')}.
            </Trans>
          </p>
          <h2 style={{marginBottom: '0'}}>
            <Trans i18nKey="contactUsToCreateService">
              Please <a onClick={e => setModal({
                content: (
                  <h1 className="demo">{t('Order')} {productName}</h1>
                )
              }) }>contact us</a> to create your first 🥳
            </Trans>
          </h2>
        </Fragment>
      }
    </div>
  )
}

NoServices.defaultProps = {
  productName: null,
  ctaOnClick: false,
  inBillingAccount: false
}

export default NoServices



