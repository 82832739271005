import React from 'react'
import { useTranslation } from 'react-i18next'


const GettingStarted = () => {
  const { t } = useTranslation()

  return (
    <div>
      <h2>{t('How to send SMS')}</h2>

      {/*<h3>{t('Through the Levelup API')}</h3>*/}
      <p><a href="https://levelupapis.no">{t('Link to API documentation')}</a></p>

      {/*<h3>{t('With our WordPress plugin')}</h3>*/}
    </div>
  )
}

export default GettingStarted
